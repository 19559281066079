import React, { useEffect } from "react";
import Header from "../components/Header";
import checkwrapper from "../assets/images/check-wrapper.svg";
import mail from "../assets/images/mail-02.svg";
import Lywologo from "../assets/images/LYWOLogoSmall.svg";
import ThankHeader from "../components/ThankHeader";
import { useLocation, useNavigate } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();

  const pathName = useLocation();

  useEffect(() => {
    return () => {
      navigate("/Thankyou");

      localStorage.clear();
    };
  }, []);

  return (
    <>
      <ThankHeader />
      <div className="">
        <div className="container d-flex flex-column" id="thank-main">
          <div className="thank-main text-center my-3 mt-md-5 ">
            <a className="text-center">
              <img src={checkwrapper} className="check-wrapper" />
            </a>
            <div className="text-center">
              <h2>That concludes our personality assessment.</h2>
              <p>
                We greatly appreciate your patience and effort in completing
                this test. Your inputs will be processed and the final report
                will be shared with the individual or firm that provided you
                with the access code.
              </p>
              <p>Thank you once again for your participation.</p>
            </div>
          </div>
        </div>
        <div className="thank-main2">
          <div className="text-center">
            <img src={Lywologo} className="Lywologo" alt="Lywologo" />
          </div>
          <p className="text-center mb-0 ps-2 pe-2 interested">
            If you’re interested in reviewing a brief summary of your
            personality report, or would like to share any feedback or comments,
            feel free to write to us
          </p>
          <div className="d-flex justify-content-center">
            {/* <a className="comp-mailbtn" href="mailto:info@lywo.in"> */}
            <a className="comp-mailbtn" href="mailto:hello@lywo.in">
              <img src={mail} className="mail" alt="Email Icon" />
              Share feedback
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
