import React from "react";
import Illustration3 from "../assets/images/Illustrationn3.svg";
import Header from "../components/Header";
import { t } from "i18next";


const BreakDone = (props) => {

  const {contnueBreakDone} = props

  return (
    <>
      {/* <Header /> */}
      <div className="container" id="breakmain">
        <div className="breakmain">
          <div className="text-center">
            <img src={Illustration3} className="Illustration1-slide" />
          </div>
          <div className="text-center p-4">
            <h3>Almost done.</h3>
            <p>
              You are in the Home Stretch.
              <br />
              It's great you've got so far so quickly. Don't rush take your time !
            </p>
          </div>
          <div>
            <button className="btn btn-primary"
            onClick={()=>contnueBreakDone()}
            >Continue</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakDone;
