import React from "react";
import Illustration1 from "../assets/images/Illustrationn1.svg";
import Header from "../components/Header";

const Break = () => {
  return (
    <>
      <Header />
      <div className="container" id="breakmain">
        <div className="breakmain">
          <a className="text-center">
            <img src={Illustration1} className="Illustration1-slide" />
          </a>
          <div className="text-center p-4">
            <h3>Great Start !!</h3>
            <p>
              Remember, Relax & Respond !<br />
              There are no correct answers. Just be your natural self and make
              the selections.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Break;
