import { t } from "i18next";
import Illustration from "../assets/images/Illustrationn1.svg";

function GreatStart(props) {
  const { continueTest } = props;

  return (
    <>
      {/* <Header /> */}
      <div className="container" id="breakmain">
        <div className="breakmain">
          <a className="text-center">
            <img src={Illustration} className="Illustration1-slide" />
          </a>
          <div className="text-center p-4">
            <h3>Great Start.</h3>
            <p>
              Remember, Relax & Respond ! <br />
              There are no correct answers. Just be your natural self and make the selections.
            </p>
          </div>
          <div>
            <button className="btn btn-primary" onClick={() => continueTest()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default GreatStart;
