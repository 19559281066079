import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LYWO_SecondaryLogo from "../assets/images/LYWO_SecondaryLogo.svg";
import persTestImg from "../assets/images/persTestImg.svg";
import cardImg from "../assets/images/cardImg.svg";
import persTestImg1 from "../assets/images/persTestImg1.svg";
import Like from "../assets/images/Like.svg";
import disLike from "../assets/images/disLike.svg";
import smilieImg from "../assets/images/smilieImg.svg";
import languageImg from "../assets/images/languageImg.svg";
import scrollActiveIcon from "../assets/images/scrollActiveIcon.svg";
import scrollDefaultIcon from "../assets/images/scrolldefaultIcon.svg";
import arrowRight from "../assets/images/arrow-right.svg";
import persTestImgMob from "../assets/images/persTestImgMob.svg";
import persTestImg1Mob from "../assets/images/persTestImg1Mob.svg";
import SwipeRight from "../assets/images/SwipeRight.svg";
import SwipeLeft from "../assets/images/SwipeLeft.svg";
import smilieImgMob from "../assets/images/smilieImgMob.svg";
import languageImgMob from "../assets/images/languageImgMob.svg";
import skipimg from "../assets/images/Text.svg";
import { AppContext } from "../contaxt/AppContext";
import globe from "../assets/images/globe-01.svg";
import DropdownLanguage from "../pages/multiLang/DropDown";
import { toast } from "react-toastify";

const SplashScreen = () => {
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(1);
  const { settokendatacontaxt, PageMsg, codequerylgin, userlgin } =
    useContext(AppContext);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        nextScreen();
      }
    };
    window.addEventListener("keypress", handleKeyPress);
    return () => window.removeEventListener("keypress", handleKeyPress);
  }, [currentScreen]);

  const nextScreen = () => {
    let add = localStorage.getItem("addInfoOne");
    let two = localStorage.getItem("addInfoTwo");
    if (currentScreen === 4) {
      if (
        (add?.toLocaleLowerCase() == "no" &&
          two?.toLocaleLowerCase() == "no") ||
        PageMsg == "Login Successful"
      ) {
      } else if (codequerylgin) {
        navigate("/companyname");
      } else {
        navigate("/LoginWithCode");
      }
    } else {
      setCurrentScreen((prevScreen) => Math.min(prevScreen + 1, 4));
    }
  };

  const skipIntro = () => {
    let add = localStorage.getItem("addInfoOne");
    let two = localStorage.getItem("addInfoTwo");
    const languageSelected =
      currentLanguage === "en" ||
      currentLanguage === "hi" ||
      currentLanguage === "te";
    // if (codequerylgin) {
    //   navigate("/AdditionalInformation");
    // } else {
    //   navigate("/LoginWithCode");
    // }
    if (currentScreen === 4 && !languageSelected) {
      setunselectlang(true);
      toast.error(
        "Please choose language of Test before proceeding ahead. You can always change it later."
      );
      return;
    }
    if (
      (add?.toLocaleLowerCase() == "no" && two?.toLocaleLowerCase() == "no") ||
      PageMsg == "Login Successful"
    ) {
      if (PageMsg == "Login Successful") {
        navigate("/companyname");
      } else {
        navigate("/");
      }
      // navigate("/companyname");
    } else if (codequerylgin) {
      navigate("/companyname");
    } else {
      // navigate("/LoginWithCode");
      if (userlgin) {
        navigate("/companyname");
      } else {
        if (currentScreen === 4 && !languageSelected) {
          setunselectlang(true);
          toast.error(
            "Please choose language of Test before proceeding ahead. You can always change it later."
          );
        }

        navigate("/LoginWithCode");
        // if (languageSelected) {
        //   navigate("/LoginWithCode");
        // }
      }
    }
  };

  const prevScreen = () => {
    setCurrentScreen((prevScreen) => Math.max(prevScreen - 1, 1));
  };
  const [showMobBtn, setShowMobBtn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowMobBtn(window.innerWidth <= 576);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [unselectlang, setunselectlang] = useState(false);
  const handlenextscreen = () => {
    const langselected =
      currentLanguage === "en" ||
      currentLanguage === "hi" ||
      currentLanguage === "te";
    setCurrentScreen((prevScreen) => Math.min(prevScreen + 1, 4));
    if (currentScreen === 4) {
      if (!langselected) {
        setunselectlang(true);
        toast.error(
          "Please choose language of Test before proceeding ahead. You can always change it later."
        );
      } else if (langselected) {
        navigate("/companyname");
      }
    }
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const handleStyle = {
    bottom: currentScreen === 3 && isSmallScreen ? "85px" : "60px",
    width: "100%",
    padding: "10px",
    // backgroundColor: "#eef4ff",
  };
  const handleDropDown = () => {
    setShowDropDown(!showDropDown);
    setIsOpen(!isOpen);
  };
  const [showDropDown, setShowDropDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = localStorage.getItem("language");
  const [curr, setCurr] = useState({
    en: "English",
    hi: "Hindi",
    te: "Telugu",
  });

  return (
    <div className="mainWrap">
      <div className="container padIn mb-4">
        <div className="logoCntr d-flex justify-content-evenly">
          <div>
            <a
              href="/"
              className={`${
                currentScreen === 4
                  ? "d-flex justify-content-evenly text-dark "
                  : ""
              }`}
            >
              <img
                src={LYWO_SecondaryLogo}
                className="logoStyle title"
                alt="Logo"
              />
            </a>
          </div>
        </div>

        {/* splashScreen1 */}
        {currentScreen === 1 && (
          <div className="splashScreen" onClick={nextScreen}>
            <div className="text-center homePad">
              <p className="hdg">Welcome to LYWO</p>
              <p className="subTxt mb-0">
                We believe that Attitude, Interests and Personality are as
                important and if not more important than Skills, Experience and
                Training.
              </p>
              <p className="subTxt mb-0">
                LYWO, which stands for Love Your Work, was developed with the
                vision to make it easier for individuals to find the kind of
                jobs that they are most suited for and help the jobs be better
                modelled for individuals, so happiness and success are easier to
                achieve.
              </p>
              <p className="subTxt mb-0">
                We Humans love to excel at things when we enjoy them. We are
                super excited that you have shown interest in our project to
                make the world a slightly happier place.
              </p>
            </div>
          </div>
        )}
        {/* splashScreen2 */}

        {currentScreen === 2 && (
          <div className="splashScreen" onClick={nextScreen}>
            <div className="text-center webViewPT">
              <p className="hdg">LYWO’s Personality Test</p>
              <div>
                <img
                  src={persTestImg}
                  className="logoStyle mt-3 mb-4"
                  alt="Personality Test"
                />
              </div>
              <p className="subHdg">4 Visual Word Options</p>
              <p className="subTxt">
                In each question, you’ll be presented with 4 word options that
                are accompanied with images for easier interpretation.
              </p>
              <div className="container padTB">
                <div className="row">
                  <div className="col cntr">
                    <div id="" className="VWOcard rot1">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Independent</p>
                    </div>

                    <div className="VWOcard rot2">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Quiet</p>
                    </div>

                    <div className="VWOcard rot3">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Enthusiastic</p>
                    </div>

                    <div className="VWOcard rot4">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Disciplined</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobViewPT">
              <p className="hdg text-center">LYWO’s Personality Test</p>
              <div className="row">
                <div className="col-2 text-center mt-1">
                  <img
                    src={persTestImgMob}
                    className="logoStyleMobPT"
                    alt="Personality Test"
                  />
                </div>
                <div className="col-10">
                  <p className="subHdg mb-1">4 Visual Word Options</p>
                  <p className="subTxt">
                    In each question, you’ll be presented with 4 word options
                    that are accompanied with images for easier interpretation.
                  </p>
                </div>
              </div>
              <div className="container padTB">
                <div className="row">
                  <div className="col cntr mb-2">
                    <div id="" className="VWOcard rot1">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Independent</p>
                    </div>

                    <div className="VWOcard rot2">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Quiet</p>
                    </div>
                  </div>
                  <div className="col cntr mt-2">
                    <div className="VWOcard rot3">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Enthusiastic</p>
                    </div>

                    <div className="VWOcard rot4">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Disciplined</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* splashScreen3 */}

        {currentScreen === 3 && (
          <div className="splashScreen" onClick={nextScreen}>
            <div className="text-center webViewPT">
              <p className="hdg">LYWO’s Personality Test</p>
              <div>
                <img
                  src={persTestImg1}
                  className="logoStyle mt-3 mb-4"
                  alt="Personality Test"
                />
              </div>
              <p className="subHdg">Choose Your Match</p>
              <p className="subTxt">
                From these 4 options, we will need you to pick one that you most
                relate to and one that probably least represents you.
              </p>
              <div className="container padTB">
                <div className="row">
                  <div className="col cntr">
                    <div className="VWOcard rot1 orngBrdr">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Independent</p>
                      <div className="iconBtm">
                        <img src={disLike} className="logoStyle" />
                      </div>
                    </div>

                    <div className="VWOcard rot2 smHgt">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Quiet</p>
                    </div>

                    <div className="VWOcard rot3 smHgt">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Enthusiastic</p>
                    </div>

                    <div className="VWOcard rot4 bluBrdr">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Disciplined</p>
                      <div className="iconBtm">
                        <img src={Like} className="logoStyle" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobViewPT">
              <p className="hdg text-center">LYWO’s Personality Test</p>
              <div className="row">
                <div className="col-2 text-center mt-1">
                  <img
                    src={persTestImg1Mob}
                    className="logoStyleMobPT"
                    alt="Personality Test"
                  />
                </div>
                <div className="col-10">
                  <p className="subHdg mb-1">Choose Your Match</p>
                  <p className="subTxt">
                    From these 4 options, we will need you to pick one that you
                    most relate to and one that probably least represents you.
                  </p>
                </div>
              </div>
              <div className="container pt-1 pb-5">
                <div className="swpRgt">
                  <img
                    src={SwipeRight}
                    className="swipeIconStyleMobPT me-2"
                    alt="Swipe Right"
                  />
                  <p className="swpTxt m-0">Swipe Right for Unlike</p>
                </div>
                <div className="row">
                  <div className="col cntr mb-2">
                    <div className="VWOcard rot1 orngBrdr">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Independent</p>
                      <div className="iconBtm">
                        <img src={disLike} className="logoStyle" />
                      </div>
                    </div>

                    <div className="VWOcard rot2 ">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Quiet</p>
                    </div>
                  </div>
                  <div className="col cntr mt-2">
                    <div className="VWOcard rot3">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Enthusiastic</p>
                    </div>

                    <div className="VWOcard rot4 bluBrdr">
                      <div>
                        <img src={cardImg} className="logoStyle" />
                      </div>
                      <p>Disciplined</p>
                      <div className="iconBtm">
                        <img src={Like} className="logoStyle" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swpLft">
                  <img
                    src={SwipeLeft}
                    className="swipeIconStyleMobPT me-2"
                    alt="Swipe Left"
                  />
                  <p className="swpTxt m-0">Swipe left for Like</p>
                </div>
                {/* <div className="text-center">
                  <p className="swpTxtMob">
                    You can tap or swipe to select your choices
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {/* splashScreen4 */}

        {currentScreen === 4 && (
          <div className="splashScreen">
            <div className="text-center webViewPT">
              <p className="hdg">LYWO’s Personality Test</p>
              <div className="row">
                <div className="col-6">
                  <img
                    src={smilieImg}
                    className="logoStyle mt-3 mb-4"
                    alt="Smile"
                  />
                  <p className="subHdg">Relax and Respond</p>
                  <p className="subTxt">
                    Don’t try to overthink your responses as there are no right
                    answers.
                  </p>
                </div>
                <div className="col-6">
                  <img
                    src={languageImg}
                    className="logoStyle mt-3 mb-4"
                    alt="Language"
                  />
                  <p className="subHdg">We Support 3 Languages</p>
                  <p className="subTxt">
                    Pick a language of your choice to answer.
                  </p>
                  <div className="row justify-content-center position-relative">
                    <div className="col-4 d-flex align-items-center pe-4">
                      <div className="row align-items-center">
                        <div className="percentage-col col d-flex mt-2 align-items-center justify-content-center"></div>
                        <div className="col border lang-box border-0">
                          <div
                            className="d-flex flex-column"
                            style={{ width: "200px" }}
                          >
                            <div
                              className={`d-flex justify-content-between border p-2 rounded-3 align-items-center ${
                                unselectlang ? "border-danger" : ""
                              }`}
                              onClick={handleDropDown}
                              style={{ cursor: "pointer" }}
                            >
                              <h5 className="me-1 mb-0 text-nowrap">
                                {currentLanguage === "en" && curr.en
                                  ? curr.en
                                  : currentLanguage === "hi" && curr.hi
                                  ? curr.hi
                                  : currentLanguage === "te" && curr.te
                                  ? curr.te
                                  : "Select Language"}
                              </h5>
                              <img src={globe} alt="Globe Icon" />

                              <div
                                className="border bg-white rounded-3 dropdown-container"
                                style={{
                                  position: "absolute",
                                  top: "90%",
                                  left: "10",
                                  zIndex: 1000,
                                }}
                              >
                                <div style={{ width: "177px" }}>
                                  {showDropDown && (
                                    <DropdownLanguage
                                      showDropDown={showDropDown}
                                      setShowDropDown={setShowDropDown}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <p>{unselectlang && <span className="text-danger ">Please choose language of Test before proceeding ahead. You can always change it later.</span> }</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center padTB">
                <p className="subTxtRW">
                  And at the end, we promise to neither place you in a box or
                  give you a 15 page report, we will help you identify the
                  behaviours are characteristics that are most resonant with
                  your personality and hope that you can use that to impact the
                  world around you positively !
                </p>
              </div>
            </div>
            <div className="mobViewPT">
              <p className="hdg text-center">LYWO’s Personality Test</p>
              <div>
                <div className="row">
                  <div className="col-2 text-center mt-1">
                    <img
                      src={smilieImgMob}
                      className="logoStyleMobPT"
                      alt="Smile"
                    />
                  </div>
                  <div className="col-10">
                    <p className="subHdg mb-1">Relax and Respond</p>
                    <p className="subTxt">
                      Don’t try to overthink your responses as there are no
                      right answers.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 text-center mt-1">
                    <img
                      src={languageImgMob}
                      className="logoStyleMobPT"
                      alt="Language"
                    />
                  </div>
                  <div className="col-10 my-2">
                    <p className="subHdg mb-1">We Support 3 Languages</p>
                    <p className="subTxt">
                      Pick a language of your choice to answer.
                    </p>
                    <div className="row justify-content-center position-relative">
                      <div className="col-12 d-flex align-items-center mx-auto">
                        <div className="row align-items-center">
                          <div className="percentage-col col d-flex mt-2 align-items-center justify-content-center"></div>
                          <div className="col border lang-box border-0">
                            <div
                              className="d-flex flex-column"
                              style={{ width: "200px" }}
                            >
                              <div
                                className={`d-flex justify-content-between border p-2 rounded-3 align-items-center  ${
                                  unselectlang ? "border-danger" : ""
                                }`}
                                onClick={handleDropDown}
                                style={{ cursor: "pointer" }}
                              >
                                <h5 className="me-1 mb-0 text-nowrap">
                                  {currentLanguage === "en" && curr.en
                                    ? curr.en
                                    : currentLanguage === "hi" && curr.hi
                                    ? curr.hi
                                    : currentLanguage === "te" && curr.te
                                    ? curr.te
                                    : "Select Language"}
                                </h5>
                                <img src={globe} alt="Globe Icon" />
                                <div
                                  className="border bg-white rounded-3 dropdown-container "
                                  style={{
                                    position: "absolute",
                                    top: "80%",
                                    right: "10",
                                    zIndex: 1000,
                                  }}
                                >
                                  <div style={{ width: "177px" }}>
                                    {showDropDown && (
                                      <DropdownLanguage
                                        showDropDown={showDropDown}
                                        setShowDropDown={setShowDropDown}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <p>{unselectlang && <span className="text-danger ">Please choose language of Test before proceeding ahead. You can always change it later.</span> }</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center padTB">
                <p className="subTxtRW">
                  And at the end, we promise to neither place you in a box nor
                  give you a 15-page report. We will help you identify the
                  behaviors and characteristics that are most resonant with your
                  personality and hope that you can use that to positively
                  impact the world around you!
                </p>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="container indctor" style={handleStyle}>
            <div className="row justify-content-between align-items-center">
              <div className="col-2"></div>
              <div className="col-6  mx-auto cntr">
                {[1, 2, 3, 4].map((screen) => (
                  <img
                    key={screen}
                    src={
                      currentScreen === screen
                        ? scrollActiveIcon
                        : scrollDefaultIcon
                    }
                    className="logoStyle"
                    alt={`icon-${screen}`}
                    onClick={() => setCurrentScreen(screen)}
                  />
                ))}
              </div>
              {/* <div className="col-2">
              
              </div> */}
              <div className="col-2">
                {codequerylgin ? (
                  <button
                    className="btn btn-light me-3 nextbutton"
                    onClick={handlenextscreen}
                  >
                    Next
                  </button>
                ) : (
                  <img
                    className=""
                    onClick={skipIntro}
                    src={skipimg}
                    alt="SkipIntro"
                    height={15}
                  />
                )}
              </div>
              {currentScreen === 3 && (
                <div className="text-center d-lg-none">
                  <p className="mb-0">
                    You can tap or swipe to select your choices
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {showMobBtn && (
          <div className="mobBtn">
            <button className="btnVldtMob" onClick={skipIntro}>
              <p>Let’s Get Started</p>
              <img
                src={arrowRight}
                className="arrowIconStyl"
                alt="ArrowRight-Icon"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplashScreen;
