import React, { useContext, useEffect, useState } from "react";
import LYWOLogoSmall from "../assets/images/LYWOLogoSmall.svg";
import Illustration from "../assets/images/Illustration.svg";
import arrowRight from "../assets/images/arrow-right.svg";
import calendarIcon from "../assets/images/CalendarIcon.svg";
import uploadIcon from "../assets/images/UploadIcon.svg";
import { useNavigate } from "react-router-dom";
import { simpleGetCall, simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../contaxt/AppContext";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useRef } from "react";

const AdditionalInformation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
    }),
  };
  // const handleExperienceChange = (e) => {
  //   const { value } = e.target;
  //   if (Number(value) >= 0 && Number(value) <= 40) {
  //     setExperience(value);
  //   }
  // };

  const handleExperienceChange = (e) => {
    const { value } = e.target;
    const numericValue = Number(value);

    if (numericValue >= 0) {
      setExperience(value);
    }
  };

  const options = [
    {
      value: "High School",
      label: "High School",
    },
    {
      value: "Diploma",
      label: "Diploma",
    },
    {
      value: "PG Diploma",
      label: "PG Diploma",
    },
    {
      value: "Bachelor's",
      label: "Bachelor's",
    },
    { value: "Master's", label: "Master's" },
    { value: "PhD", label: "PhD" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const scrollHomeAdd = useRef(null);
  const scrollElementref = useRef(null);
  const scrollSalary = useRef(null);

  const handleScrollSalary = () => {
    setTimeout(() => {
      if (scrollSalary.current) {
        scrollSalary.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (scrollHomeAdd.current) {
      scrollHomeAdd.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      if (scrollElementref.current) {
        scrollElementref.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 2000);
  }, []);
  const { tokendatacontaxt, hide, sethide, userlgin } = useContext(AppContext);
  console.log(tokendatacontaxt);
  console.log(hide, "hide");
  const navigate = useNavigate();
  sethide(localStorage.getItem("Usertype") == "Employee");
  const [startDate, setStartDate] = useState(null);

  const [buyOutOption, setBuyOutOption] = useState(true); // State to manage selected option
  const [specializationdata, setSpecializationdata] = useState(""); // State to manage selected option
  const [spaciallistid, setSpaciallistID] = useState("");
  console.log(spaciallistid); // State to manage selected option
  const [spaciallist, setSpaciallist] = useState([]); // State to manage selected option
  const [getspacialdata, setGetSpacialData] = useState([]); // State to manage selected option
  const [addtionalInfoData, setAddtionalInforData] = useState([]); // State to manage selected option
  const [educationLevel, setEducationLevel] = useState("");
  const [fieldOfEducation, setFieldOfEducation] = useState("");
  const [experience, setExperience] = useState("");
  const [currentJobTitle, setCurrentJobTitle] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [currentIndustry, setCurrentIndustry] = useState("");
  const [availableFrom, setAvailableFrom] = useState("");
  console.log(availableFrom, "availableFrom");
  const [noticePeriodBuyout, setNoticePeriodBuyout] = useState("");
  console.log("noticePeriodBuyout", noticePeriodBuyout);

  const [expectedSalary, setExpectedSalary] = useState("");
  const [uploadResume, setUploadResume] = useState("");
  const [uploadResumeSelected, setUploadResumeSelected] = useState(false);
  const [uploadResumeName, setUploadResumeName] = useState("");
  const [showorhidden, setshoworhidden] = useState(false);
  console.log(showorhidden, "showOrhidden");
  const token = localStorage.getItem("authToken");
  console.log(token);
  const currentUserId = localStorage.getItem("customer_id");

  const salaryOptions = [
    { value: "Below 3 Lakhs per year", label: "Below 3 Lakhs per year" },
    { value: "3 to 5 Lakhs per year", label: "3 to 5 Lakhs per year" },
    { value: "5 to 7 Lakhs per year", label: "5 to 7 Lakhs per year" },
    { value: "7 to 9 Lakhs per year", label: "7 to 9 Lakhs per year" },
    { value: "9 to 12 Lakhs per year", label: "9 to 12 Lakhs per year" },
    { value: "12 to 15 Lakhs per year", label: "12 to 15 Lakhs per year" },
    { value: "15 to 18 Lakhs per year", label: "15 to 18 Lakhs per year" },
    { value: "18 to 22 Lakhs per year", label: "18 to 22 Lakhs per year" },
    { value: "22 to 26 Lakhs per year", label: "22 to 26 Lakhs per year" },
    { value: "26 to 30 Lakhs per year", label: "26 to 30 Lakhs per year" },
    { value: "30 to 35 Lakhs per year", label: "30 to 35 Lakhs per year" },
    { value: "35 to 40 Lakhs per year", label: "35 to 40 Lakhs per year" },
    { value: "40 to 45 Lakhs per year", label: "40 to 45 Lakhs per year" },
    { value: "45 to 50 Lakhs per year", label: "45 to 50 Lakhs per year" },
    { value: "50 to 60 Lakhs per year", label: "50 to 60 Lakhs per year" },
    { value: "60 to 70 Lakhs per year", label: "60 to 70 Lakhs per year" },
    { value: "70 to 80 Lakhs per year", label: "70 to 80 Lakhs per year" },
    { value: "80 Lakhs to 1 Cr per year", label: "80 Lakhs to 1 Cr per year" },
    { value: "Above 1 Cr per year", label: "Above 1 Cr per year" },
  ];
  // const salaryRanges = [
  //   "Below 3 Lakhs per year",
  //   "3 to 5 Lakhs per year",
  //   "5 to 7 Lakhs per year",
  //   "7 to 9 Lakhs per year",
  //   "9 to 12 Lakhs per year",
  //   "12 to 15 Lakhs per year",
  //   "15 to 18 Lakhs per year",
  //   "18 to 22 Lakhs per year",
  //   "22 to 26 Lakhs per year",
  //   "26 to 30 Lakhs per year",
  //   "30 to 35 Lakhs per year",
  //   "35 to 40 Lakhs per year",
  //   "40 to 45 Lakhs per year",
  //   "45 to 50 Lakhs per year",
  //   "50 to 60 Lakhs per year",
  //   "60 to 70 Lakhs per year",
  //   "70 to 80 Lakhs per year",
  //   "80 Lakhs to 1 Cr per year",
  //   "Above 1 Cr per year",
  // ];

  const initialValidataion = {
    educationValidation: "",
    fieldValidation: "",
    experienceValidation: "",
    jobTitleValidation: "",
    currentCompanyValidation: "",
    currentIndustryValidation: "",
    dateValidation: "",
    expectedSalValidation: "",
    uploadResumeErr: "",
  };

  const [validation, setValidation] = useState(initialValidataion);

  const handleFileChanges = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      // reader.onloadend = () => {
      //   const arrayBuffer = reader.result;
      //   // Convert ArrayBuffer to binary string if needed
      //   // You can send the arrayBuffer directly to the server as binary data
      //   console.log(arrayBuffer);

      //   // Example: sending the arrayBuffer with fetch
      //   // fetch('your-server-endpoint', {
      //   //   method: 'POST',
      //   //   body: arrayBuffer,
      //   //   headers: {
      //   //     'Content-Type': 'application/pdf', // Change according to the file type
      //   //   },
      //   // })
      //     .then(response => response.json())
      //     .then(data => console.log(data))
      //     .catch(error => console.error('Error:', error));
      // };
      reader.readAsArrayBuffer(file);

      console.log(file.name, "file.name");
      setUploadResume(file.name);
    }
  };
  const handleOptionChange = (isYes) => {
    setBuyOutOption(isYes);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const fileSizeMB = file.size / (1024 * 1024); // size in MB
  //     if (fileSizeMB > 25) {
  //       alert("File size exceeds 25MB. Please upload a smaller file.");
  //       e.target.value = ""; // reset the file input
  //     } else {
  //       document.getElementById("UploadResumeInput").value = file.name;
  //     }
  //   }
  // };

  const handleFileChange = (e) => {
    // alert("File changed");
    const file = e.target.files[0];

    if (file) {
      const fileSizeMB = file.size / (1024 * 1024); // Calculate file size in MB
      if (fileSizeMB > 25) {
        alert("File size exceeds 25MB. Please upload a smaller file.");
        e.target.value = ""; // Reset the file input by clearing its value
      } else {
        // Update the visible input field with the file name
        const uploadResumeInput = document.getElementById("UploadResumeInput");
        // alert("UploadResumeInput",uploadResumeInput);
        console.log(uploadResumeInput, "uploadResumeInput");
        if (uploadResumeInput) {
          // uploadResumeInput.value = file.name;
          // const reader = new FileReader();
          // reader.readAsArrayBuffer(file);
          // const arrayBuffer = reader.result;
          // console.log(arrayBuffer,'arrayBuffer');
          console.log(file, "file");
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            // var base64String = reader.result.split(',')[1];
            var base64String = reader.result;
            console.log(base64String, "base64String");
            setUploadResume(base64String);
            setUploadResumeSelected(true);
            setUploadResumeName(file.name);
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        }
      }
    }
  };

  useEffect(() => {
    let addinfoone = localStorage.getItem("addInfoOne");
    let addinfotwo = localStorage.getItem("addInfoTwo");
    if (addinfoone?.toLowerCase() == "yes") {
      setshoworhidden(true);
    } else {
      setshoworhidden(false);
    }
  });

  useEffect(() => {
    GetSpecializations();
    GetSpecializationData();

    if (tokendatacontaxt === "" || tokendatacontaxt === null) {
    } else {
      GetSpecializations();
      GetSpecializationData();
    }
  }, [tokendatacontaxt]);

  // const edulevel = [
  //   "Diploma",
  //   "Bachelor's Degree Or Graduation",
  //   "Post Graduation",
  //   "Master Or  Post Graduation",
  // ];

  // const industryOptions = [
  //   {
  //     value: "	Independent Power and Renewable Electricity Producers",
  //     label: "	Independent Power and Renewable Electricity Producers",
  //   },
  //   {
  //     value: "Semiconductors & Semiconductor Equipment",
  //     label: "Semiconductors & Semiconductor Equipment",
  //   },
  //   { value: "Capital Markets", label: "Capital Markets" },
  //   { value: "Specialty Retail", label: "Specialty Retail" },
  //   {
  //     value: "Hotels, Restaurants & Leisure",
  //     label: "Hotels, Restaurants & Leisure",
  //   },
  //   { value: "Household Products", label: "Household Products" },
  //   {
  //     value: "Transportation Infrastructure",
  //     label: "Transportation Infrastructure",
  //   },
  //   { value: "Marine Transportation", label: "Marine Transportation" },
  //   { value: "Air Freight & Logistics", label: "Air Freight & Logistics" },
  //   { value: "Professional Services", label: "Professional Services" },
  //   {
  //     value: "Commercial Services & Supplies",
  //     label: "Commercial Services & Supplies",
  //   },
  //   {
  //     value: "Trading Companies & Distributors",
  //     label: "Trading Companies & Distributors",
  //   },
  //   { value: "Machinery", label: "Machinery" },
  //   { value: "Electrical Equipment", label: "Electrical Equipment" },
  //   { value: "Building Products", label: "Building Products" },
  //   { value: "Paper & Forest Products", label: "Paper & Forest Products" },
  //   {
  //     value: "Construction & Engineering",
  //     label: "Construction & Engineering",
  //   },
  //   {
  //     value: "Energy Equipment & Services",
  //     label: "Energy Equipment & Services",
  //   },
  //   { value: "Aerospace & Defense", label: "Aerospace & Defense" },
  //   { value: "Advertising & Marketing", label: "Advertising & Marketing" },
  //   {
  //     value: "Agricultural Industry",
  //     label: "Agricultural Industry",
  //   },
  //   { value: "Automobile Components", label: "Automobile Components" },
  //   { value: "Automobile", label: "Automobile" },
  //   { value: "Aviation", label: "Aviation" },
  //   { value: "Banks", label: "Banks" },
  //   { value: "Biotechnology", label: "Biotechnology" },
  //   { value: "BPO / Call Centre", label: "BPO / Call Centre" },
  //   { value: "Cement", label: "Cement" },
  //   { value: "Chemicals", label: "Chemicals" },
  //   {
  //     value: "Diversified Consumer Services",
  //     label: "Diversified Consumer Services",
  //   },
  //   { value: "Education / Training", label: "Education / Training" },
  //   { value: "Electric Vehicle", label: "Electric Vehicle" },
  //   {
  //     value: "Electronic Equipment, Instruments & Components",
  //     label: "Electronic Equipment, Instruments & Components",
  //   },
  //   {
  //     value: "Engineering and Capital Goods",
  //     label: "Engineering and Capital Goods",
  //   },
  //   { value: "Financial Services", label: "Financial Services" },
  //   { value: "FMCG", label: "FMCG" },
  //   { value: "Food Products", label: "Food Products" },
  //   {
  //     value: "Health Care Equipment & Supplies",
  //     label: "Health Care Equipment & Supplies",
  //   },
  //   { value: "Industrial Automation", label: "Industrial Automation" },
  //   {
  //     value: "Industrial Conglomerates",
  //     label: "Industrial Conglomerates",
  //   },
  //   { value: "Infrastructure", label: "Infrastructure" },
  //   { value: "Insurance", label: "Insurance" },
  //   { value: "Internet", label: "Internet" },
  //   { value: "IT & BPM", label: "IT & BPM" },
  //   { value: "IT Services & Consulting", label: "IT Services & Consulting" },
  //   { value: "Manufacturing", label: "Manufacturing" },
  //   { value: "Media and Entertainment", label: "Media and Entertainment" },
  //   { value: "Metals and Mining", label: "Metals and Mining" },
  //   { value: "MSME", label: "MSME" },
  //   {
  //     value: "Oil, Gas & Consumable Fuels",
  //     label: "Oil, Gas & Consumable Fuels",
  //   },
  //   { value: "Containers & Packaging", label: "Containers & Packaging" },
  //   { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  //   { value: "Ports", label: "Ports" },
  //   { value: "Power", label: "Power" },
  //   { value: "Medical Devices", label: "Medical Devices" },
  //   { value: "Railways", label: "Railways" },
  //   { value: "Real Estate", label: "Real Estate" },
  //   { value: "Recruitment / Staffing", label: "Recruitment / Staffing" },
  //   { value: "Retail", label: "Retail" },
  //   { value: "Roads", label: "Roads" },
  //   { value: "Science and Technology", label: "Science and Technology" },
  //   { value: "Services", label: "Services" },
  //   { value: "Software", label: "Software" },
  //   { value: "Steel", label: "Steel" },
  //   { value: "Telecommunications", label: "Telecommunications" },
  //   {
  //     value: "Textiles, Apparel & Luxury Goods",
  //     label: "Textiles, Apparel & Luxury Goods",
  //   },
  //   { value: "Tourism and Hospitality", label: "Tourism and Hospitality" },
  // ];
  const industryOptions = [
    { value: "abrasive_sandpaper_mfg", label: "Abrasive & Sandpaper Mfg." },
    { value: "accessories", label: "Accessories" },
    { value: "accommodation_food_services", label: "Accommodation And Food Services" },
    { value: "accounting", label: "Accounting" },
    { value: "adhesive_mfg", label: "Adhesive Mfg." },
    { value: "administrative_support_services", label: "Administrative And Support, Waste Management, And Remediation Services" },
    { value: "adult_education", label: "Adult Education" },
    { value: "aerospace_defense", label: "Aerospace And Defense" },
    { value: "aerospace_industry", label: "Aerospace Industry" },
    { value: "agriculture", label: "Agriculture" },
    { value: "agriculture_forestry_fishing_hunting", label: "Agriculture, Forestry, Fishing & Hunting" },
    { value: "plantations_rural_sectors", label: "Agriculture; Plantations; Other Rural Sectors" },
    { value: "air_transport", label: "Air Transport" },
    { value: "aircraft_manufacturing", label: "Aircraft Manufacturing" },
    { value: "aircraft_engine_parts_mfg", label: "Aircraft, Engine & Parts Mfg." },
    { value: "aircraft_marine_railroad_wholesaling", label: "Aircraft, Marine & Railroad Transportation Equipment Wholesaling" },
    { value: "alarm_horn_traffic_control_mfg", label: "Alarm, Horn & Traffic Control Equipment Mfg." },
    { value: "aluminum_mfg", label: "Aluminum Mfg." },
    { value: "animal_food_production", label: "Animal Food Production" },
    { value: "apartment_condominium_construction", label: "Apartment & Condominium Construction" },
    { value: "apparel_knitting_mills", label: "Apparel Knitting Mills" },
    { value: "art_office_supply_mfg", label: "Art & Office Supply Mfg." },
    { value: "art_and_craft", label: "Art And Craft" },
    { value: "artificial_intelligence", label: "Artificial Intelligence" },
    { value: "arts_and_culture", label: "Arts And Culture" },
    { value: "arts_entertainment_recreation", label: "Arts, Entertainment, And Recreation" },
    { value: "asphalt_mfg", label: "Asphalt Mfg." },
    { value: "athletic_sporting_goods_mfg", label: "Athletic & Sporting Goods Mfg." },
    { value: "atv_golf_cart_snowmobile_mfg", label: "Atv, Golf Cart & Snowmobile Mfg." },
    { value: "audio_video_equipment_mfg", label: "Audio & Video Equipment Mfg." },
    { value: "auto_parts_mfg", label: "Auto Parts Mfg." },
    { value: "auto_parts_wholesaling", label: "Auto Parts Wholesaling" },
    { value: "automobile_light_duty_vehicle_mfg", label: "Automobile & Light Duty Motor Vehicle Mfg." },
    { value: "automobile_brakes_mfg", label: "Automobile Brakes Mfg." },
    { value: "automobile_electronics_mfg", label: "Automobile Electronics Mfg." },
    { value: "automobile_engine_parts_mfg", label: "Automobile Engine & Parts Mfg." },
    { value: "automobile_interior_mfg", label: "Automobile Interior Mfg." },
    { value: "automobile_metal_stamping", label: "Automobile Metal Stamping" },
    { value: "automobile_steering_suspension_mfg", label: "Automobile Steering & Suspension Mfg." },
    { value: "automobile_transmission_mfg", label: "Automobile Transmission Mfg." },
    { value: "automobile_wholesaling", label: "Automobile Wholesaling" },
    { value: "automobiles", label: "Automobiles" },
    { value: "automotive_industry", label: "Automotive Industry" },
    { value: "automotive_services", label: "Automotive Services" },
    { value: "baking_mix_prepared_food_production", label: "Baking Mix & Prepared Food Production" },
    { value: "ball_bearing_mfg", label: "Ball Bearing Mfg." },
    { value: "banking", label: "Banking" },
    { value: "basic_metal", label: "Basic Metal" },
    { value: "basic_metal_industry", label: "Basic Metal Industry" },
    { value: "basic_metal_production", label: "Basic Metal Production" },
    { value: "battery_mfg", label: "Battery Mfg." },
    { value: "beef_pork_wholesaling", label: "Beef & Pork Wholesaling" },
    { value: "beef_cattle_production", label: "Beef Cattle Production" },
    { value: "beekeeping", label: "Beekeeping" },
    { value: "beer_wholesaling", label: "Beer Wholesaling" },
    { value: "billboard_sign_mfg", label: "Billboard & Sign Mfg." },
    { value: "biotechnology", label: "Biotechnology" },
    { value: "blind_shade_mfg", label: "Blind & Shade Mfg." },
    { value: "boat_building", label: "Boat Building" },
    { value: "boiler_heat_exchanger_mfg", label: "Boiler & Heat Exchanger Mfg." },
    { value: "book_magazine_newspaper_wholesaling", label: "Book, Magazine & Newspaper Wholesaling" },
    { value: "bottled_water_production", label: "Bottled Water Production" },
    { value: "brand_name_pharmaceutical_mfg", label: "Brand Name Pharmaceutical Mfg." },
    { value: "bread_production", label: "Bread Production" },
    { value: "breweries", label: "Breweries" },
    { value: "bridge_highway_construction", label: "Bridge & Elevated Highway Construction" },
    { value: "broadcasting", label: "Broadcasting" },
    { value: "building_finishing_contractors", label: "Building Finishing Contractors" },
    { value: "business_support_services", label: "Business Support Services" },
    { value: "cabinet_vanity_mfg", label: "Cabinet & Vanity Mfg." },
    { value: "camera_film_wholesaling", label: "Camera & Film Wholesaling" },
    { value: "candy_production", label: "Candy Production" },
    { value: "canned_fruit_vegetable_processing", label: "Canned Fruit & Vegetable Processing" },
    { value: "cardboard_box_container_mfg", label: "Cardboard Box & Container Mfg." },
    { value: "carpenters", label: "Carpenters" },
    { value: "carpet_mills", label: "Carpet Mills" },
    { value: "cattle_hog_wholesaling", label: "Cattle & Hog Wholesaling" },
    { value: "cement_mfg", label: "Cement Mfg." },
    { value: "ceramics_mfg", label: "Ceramics Mfg." },
    { value: "cereal_production", label: "Cereal Production" },
    { value: "charitable_organizations", label: "Charitable Organizations" },
    { value: "chemical_industries", label: "Chemical Industries" },
    { value: "chemical_product_mfg", label: "Chemical Product Mfg." },
    { value: "chemical_wholesaling", label: "Chemical Wholesaling" },
    { value: "chicken_turkey_meat_production", label: "Chicken & Turkey Meat Production" },
    { value: "chicken_egg_production", label: "Chicken Egg Production" },
    { value: "childcare_services", label: "Childcare Services" },
    { value: "chocolate_production", label: "Chocolate Production" },
    { value: "cigarette_tobacco_mfg", label: "Cigarette & Tobacco Mfg." },
    { value: "cigarette_tobacco_wholesaling", label: "Cigarette & Tobacco Products Wholesaling" },
    { value: "circuit_board_electronic_component_mfg", label: "Circuit Board & Electronic Component Mfg." },
    { value: "clay_brick_product_mfg", label: "Clay Brick & Product Mfg." },
    { value: "cleaning_services", label: "Cleaning Services" },
    { value: "clothing_accessories_wholesaling", label: "Clothing & Clothing Accessories Wholesaling" },
    { value: "cloud_computing", label: "Cloud Computing" },
    { value: "coal_natural_gas_power", label: "Coal & Natural Gas Power" },
    { value: "coal_ore_wholesaling", label: "Coal & Ore Wholesaling" },
    { value: "coal_mining", label: "Coal Mining" },
    { value: "coated_laminated_paper_mfg", label: "Coated & Laminated Paper Mfg." },
    { value: "coffee_production", label: "Coffee Production" },
    { value: "commerce", label: "Commerce" },
    { value: "commercial_building_construction", label: "Commercial Building Construction" },
    { value: "commercial_construction", label: "Commercial Construction" },
    { value: "commercial_real_estate", label: "Commercial Real Estate" },
    { value: "communication_equipment_mfg", label: "Communication Equipment Mfg." },
    { value: "community_services", label: "Community Services" },
    { value: "computer", label: "Computer" },
    { value: "computer_packaged_software_wholesaling", label: "Computer & Packaged Software Wholesaling" },
    { value: "computer_industry", label: "Computer Industry" },
    { value: "computer_mfg", label: "Computer Mfg." },
    { value: "computer_peripheral_mfg", label: "Computer Peripheral Mfg." },
    { value: "concrete_contractors", label: "Concrete Contractors" },
    { value: "concrete_pipe_block_mfg", label: "Concrete Pipe & Block Mfg." },
    { value: "confectionery_wholesaling", label: "Confectionery Wholesaling" },
    { value: "construction", label: "Construction" },
    { value: "construction_mining_equipment_wholesaling", label: "Construction & Mining Equipment Wholesaling" },
    { value: "construction_industry", label: "Construction Industry" },
    { value: "construction_machinery_mfg", label: "Construction Machinery Mfg." },
    { value: "consultancy", label: "Consultancy" },
    { value: "consumer_services", label: "Consumer Services" },
    { value: "cookie_cracker_pasta_production", label: "Cookie, Cracker & Pasta Production" },
    { value: "copier_office_equipment_wholesaling", label: "Copier & Office Equipment Wholesaling" },
    { value: "copier_optical_machinery_mfg", label: "Copier & Optical Machinery Mfg." },
    { value: "copper_rolling_drawing_extruding", label: "Copper Rolling, Drawing & Extruding" },
    { value: "copper_nickel_lead_zinc_mining", label: "Copper, Nickel, Lead & Zinc Mining" },
    { value: "corn_farming", label: "Corn Farming" },
    { value: "cosmetic_beauty_products_mfg", label: "Cosmetic & Beauty Products Mfg." },
    { value: "costume_team_uniform_mfg", label: "Costume & Team Uniform Mfg." },
    { value: "cotton_farming", label: "Cotton Farming" },
    { value: "creative_industry", label: "Creative Industry" },
    { value: "crop_production", label: "Crop Production" },
    { value: "cultural_industry", label: "Cultural Industry" },
    { value: "cultural_institutions", label: "Cultural Institutions" },
    { value: "cut_sew_apparel_contractors", label: "Cut & Sew Apparel Contractors" },
    { value: "cybersecurity", label: "Cybersecurity" },
    { value: "dairy_product_production", label: "Dairy Product Production" },
    { value: "dairy_wholesaling", label: "Dairy Wholesaling" },
    { value: "data_analytics", label: "Data Analytics" },
    { value: "defense_industry", label: "Defense Industry" },
    { value: "demolition_wrecking", label: "Demolition & Wrecking" },
    { value: "design_manufacturing", label: "Design And Manufacturing" },
    { value: "digital_media", label: "Digital Media" },
    { value: "distilleries", label: "Distilleries" },
    { value: "drone_technology", label: "Drone Technology" },
    { value: "drug_cosmetic_toiletry_wholesaling", label: "Drug, Cosmetic & Toiletry Wholesaling" },
    { value: "drywall_insulation_installers", label: "Drywall & Insulation Installers" },
    { value: "dye_pigment_mfg", label: "Dye & Pigment Mfg." },
    { value: "education_industry", label: "Education Industry" },
    { value: "educational_services", label: "Educational Services" },
    { value: "egg_poultry_wholesaling", label: "Egg & Poultry Wholesaling" },
    { value: "electric_power_industry", label: "Electric Power Industry" },
    { value: "electric_vehicles", label: "Electric Vehicles" },
    { value: "electrical_equipment_mfg", label: "Electrical Equipment Mfg." },
    { value: "electrical_equipment_wholesaling", label: "Electrical Equipment Wholesaling" },
    { value: "electricians", label: "Electricians" },
    { value: "electricity_generation", label: "Electricity Generation" },
    { value: "electronic_part_equipment_wholesaling", label: "Electronic Part & Equipment Wholesaling" },
    { value: "electronics_industry", label: "Electronics Industry" },
    { value: "elevator_installation_service", label: "Elevator Installation & Service" },
    { value: "employment_services", label: "Employment Services" },
    { value: "energy_industry", label: "Energy Industry" },
    { value: "engine_turbine_mfg", label: "Engine & Turbine Mfg." },
    { value: "engineering_industry", label: "Engineering Industry" },
    { value: "entertainment_media", label: "Entertainment And Media" },
    { value: "entertainment_industry", label: "Entertainment Industry" },
    { value: "entertainment_venues", label: "Entertainment Venues" },
    { value: "environmental_consulting", label: "Environmental Consulting" },
    { value: "environmental_services", label: "Environmental Services" },
    { value: "event_planning", label: "Event Planning" },
    { value: "excavation_contractors", label: "Excavation Contractors" },
    { value: "explosives_mfg", label: "Explosives Mfg." },
    { value: "facilities_support_services", label: "Facilities Support Services" },
    { value: "farm_supplies_wholesaling", label: "Farm Supplies Wholesaling" },
    { value: "farm_lawn_garden_equipment_wholesaling", label: "Farm, Lawn & Garden Equipment Wholesaling" },
    { value: "farming", label: "Farming" },
    { value: "farming_industry", label: "Farming Industry" },
    { value: "fashion", label: "Fashion" },
    { value: "fashion_apparel", label: "Fashion And Apparel" },
    { value: "fashion_industry", label: "Fashion Industry" },
    { value: "fence_construction", label: "Fence Construction" },
    { value: "ferrous_metal_foundry_products", label: "Ferrous Metal Foundry Products" },
    { value: "fertilizer_mfg", label: "Fertilizer Mfg." },
    { value: "film", label: "Film" },
    { value: "film_television", label: "Film And Television" },
    { value: "film_industry", label: "Film Industry" },
    { value: "finance_insurance", label: "Finance And Insurance" },
    { value: "financial_consulting", label: "Financial Consulting" },
    { value: "financial_services", label: "Financial Services" },
    { value: "financial_services_industry", label: "Financial Services Industry" },
    { value: "fish_seafood_aquaculture", label: "Fish & Seafood Aquaculture" },
    { value: "fish_seafood_wholesaling", label: "Fish & Seafood Wholesaling" },
    { value: "fishing", label: "Fishing" },
    { value: "fishing_aquaculture", label: "Fishing And Aquaculture" },
    { value: "fishing_industry", label: "Fishing Industry" },
    { value: "fitness_wellness_services", label: "Fitness And Wellness Services" },
    { value: "flooring_installers", label: "Flooring Installers" },
    { value: "flour_milling", label: "Flour Milling" },
    { value: "flower_nursery_stock_wholesaling", label: "Flower & Nursery Stock Wholesaling" },
    { value: "food", label: "Food" },
    { value: "Food And Beverages", label: "Food And Beverages" },
    { value: "Food Industry", label: "Food Industry" },
    { value: "Food; Drink; Tobacco", label: "Food; Drink; Tobacco" },
    { value: "Footwear", label: "Footwear" },
    { value: "Footwear Wholesaling", label: "Footwear Wholesaling" },
    { value: "Forest Support Services", label: "Forest Support Services" },
    { value: "Forestry", label: "Forestry" },
    { value: "Forestry Industry", label: "Forestry Industry" },
    { value: "Forestry; Wood; Pulp And Paper", label: "Forestry; Wood; Pulp And Paper" },
    { value: "Forklift & Conveyor Mfg.", label: "Forklift & Conveyor Mfg." },
    { value: "Freight Forwarding", label: "Freight Forwarding" },
    { value: "Frozen Food Production", label: "Frozen Food Production" },
    { value: "Frozen Food Wholesaling", label: "Frozen Food Wholesaling" },
    { value: "Fruit & Nut Farming", label: "Fruit & Nut Farming" },
    { value: "Fruit & Vegetable Wholesaling", label: "Fruit & Vegetable Wholesaling" },
    { value: "Furniture", label: "Furniture" },
    { value: "Furniture Wholesaling", label: "Furniture Wholesaling" },
    { value: "Gambling", label: "Gambling" },
    { value: "Gambling Industry", label: "Gambling Industry" },
    { value: "Gaming And Esports", label: "Gaming And Esports" },
    { value: "Gas", label: "Gas" },
    { value: "Gas Industry", label: "Gas Industry" },
    { value: "Gasoline & Petroleum Bulk Stations", label: "Gasoline & Petroleum Bulk Stations" },
    { value: "Gasoline & Petroleum Wholesaling", label: "Gasoline & Petroleum Wholesaling" },
    { value: "Generic Pharmaceutical Mfg.", label: "Generic Pharmaceutical Mfg." },
    { value: "Glass & Glazing Contractors", label: "Glass & Glazing Contractors" },
    { value: "Glass Product Mfg.", label: "Glass Product Mfg." },
    { value: "Glasses & Contact Lens Mfg.", label: "Glasses & Contact Lens Mfg." },
    { value: "Glasses & Contacts Wholesaling", label: "Glasses & Contacts Wholesaling" },
    { value: "Gold & Silver Ore Mining", label: "Gold & Silver Ore Mining" },
    { value: "Green", label: "Green" },
    { value: "Green Industry", label: "Green Industry" },
    { value: "Grocery Wholesaling", label: "Grocery Wholesaling" },
    { value: "Guns & Ammunition Mfg.", label: "Guns & Ammunition Mfg." },
    { value: "Gym & Exercise Equipment Mfg.", label: "Gym & Exercise Equipment Mfg." },
    { value: "Gypsum Product Mfg.", label: "Gypsum Product Mfg." },
    { value: "Hand Tool & Cutlery Mfg.", label: "Hand Tool & Cutlery Mfg." },
    { value: "Hardware Mfg.", label: "Hardware Mfg." },
    { value: "Hay & Crop Farming", label: "Hay & Crop Farming" },
    { value: "Health Care And Social Assistance", label: "Health Care And Social Assistance" },
    { value: "Health Insurance", label: "Health Insurance" },
    { value: "Health Services", label: "Health Services" },
    { value: "Health Services Industry", label: "Health Services Industry" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Heating & Air Conditioning Equipment Mfg.", label: "Heating & Air Conditioning Equipment Mfg." },
    { value: "Heating & Air Conditioning Wholesaling", label: "Heating & Air Conditioning Wholesaling" },
    { value: "Heating & Air-Conditioning Contractors", label: "Heating & Air-Conditioning Contractors" },
    { value: "Heavy Civil Construction", label: "Heavy Civil Construction" },
    { value: "Heavy Engineering Construction", label: "Heavy Engineering Construction" },
    { value: "Higher Education", label: "Higher Education" },
    { value: "Hog & Pig Farming", label: "Hog & Pig Farming" },
    { value: "Home Builders", label: "Home Builders" },
    { value: "Home Furnishing Wholesaling", label: "Home Furnishing Wholesaling" },
    { value: "Horse & Other Equine Production", label: "Horse & Other Equine Production" },
    { value: "Horticulture", label: "Horticulture" },
    { value: "Hose & Belt Mfg.", label: "Hose & Belt Mfg." },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Hospitality And Tourism", label: "Hospitality And Tourism" },
    { value: "Hospitality Industry", label: "Hospitality Industry" },
    { value: "Hospitals And Clinics", label: "Hospitals And Clinics" },
    { value: "Hotels", label: "Hotels" },
    { value: "Hotels And Accommodations", label: "Hotels And Accommodations" },
    { value: "Hotels Industry", label: "Hotels Industry" },
    { value: "Hotels; Tourism; Catering", label: "Hotels; Tourism; Catering" },
    { value: "Household Furniture Mfg.", label: "Household Furniture Mfg." },
    { value: "Housing Developers", label: "Housing Developers" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Hunting & Trapping", label: "Hunting & Trapping" },
    { value: "Hydroelectric Power", label: "Hydroelectric Power" },
    { value: "Ice Cream Production", label: "Ice Cream Production" },
    { value: "Industrial Building Construction", label: "Industrial Building Construction" },
    { value: "Industrial Machinery & Equipment Wholesaling", label: "Industrial Machinery & Equipment Wholesaling" },
    { value: "Industrial Robot Industry", label: "Industrial Robot Industry" },
    { value: "Industrial Supplies Wholesaling", label: "Industrial Supplies Wholesaling" },
    { value: "Information", label: "Information" },
    { value: "Information And Cultural Industries", label: "Information And Cultural Industries" },
    { value: "Information Industry", label: "Information Industry" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Information Technology Industry", label: "Information Technology Industry" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Infrastructure (Roads, Bridges, Etc.)", label: "Infrastructure (Roads, Bridges, Etc.)" },
    { value: "Infrastructure Industry", label: "Infrastructure Industry" },
    { value: "Ink Mfg.", label: "Ink Mfg." },
    { value: "Inorganic Chemical Mfg.", label: "Inorganic Chemical Mfg." },
    { value: "Insurance", label: "Insurance" },
    { value: "Insurance Industry", label: "Insurance Industry" },
    { value: "Internet Service Providers", label: "Internet Service Providers" },
    { value: "Investigation And Security Services", label: "Investigation And Security Services" },
    { value: "Investment Services", label: "Investment Services" },
    { value: "Iron & Steel Mfg.", label: "Iron & Steel Mfg." },
    { value: "Iron Ore Mining", label: "Iron Ore Mining" },
    { value: "It Consulting", label: "It Consulting" },
    { value: "It Services", label: "It Services" },
    { value: "Janitorial Equipment Supply Wholesaling", label: "Janitorial Equipment Supply Wholesaling" },
    { value: "Jewelry", label: "Jewelry" },
    { value: "Jewelry & Watch Wholesaling", label: "Jewelry & Watch Wholesaling" },
    { value: "Jewelry Mfg.", label: "Jewelry Mfg." },
    { value: "Juice Production", label: "Juice Production" },
    { value: "Laboratory Supply Wholesaling", label: "Laboratory Supply Wholesaling" },
    { value: "Laminated Plastics Mfg.", label: "Laminated Plastics Mfg." },
    { value: "Land Development", label: "Land Development" },
    { value: "Land Transport (Trucking, Rail)", label: "Land Transport (Trucking, Rail)" },
    { value: "Leather Good & Luggage Mfg.", label: "Leather Good & Luggage Mfg." },
    { value: "Leather Tanning & Finishing", label: "Leather Tanning & Finishing" },
    { value: "Legal Services", label: "Legal Services" },
    { value: "Leisure", label: "Leisure" },
    { value: "Leisure Industry", label: "Leisure Industry" },
    { value: "Life Insurance", label: "Life Insurance" },
    { value: "Lighting & Bulb Mfg.", label: "Lighting & Bulb Mfg." },
    { value: "Lighting Fixtures Mfg.", label: "Lighting Fixtures Mfg." },
    { value: "Lime Mfg.", label: "Lime Mfg." },
    { value: "Livestock Farming", label: "Livestock Farming" },
    { value: "Livestock Production Support Services", label: "Livestock Production Support Services" },
    { value: "Logging", label: "Logging" },
    { value: "Low Technology Industry", label: "Low Technology Industry" },
    { value: "Lubricant Oil Mfg.", label: "Lubricant Oil Mfg." },
    { value: "Lumber Wholesaling", label: "Lumber Wholesaling" },
    { value: "Machine Shop Services", label: "Machine Shop Services" },
    { value: "Machinery", label: "Machinery" },
    { value: "Maintenance And Repair", label: "Maintenance And Repair" },
    { value: "Major Household Appliance Mfg.", label: "Major Household Appliance Mfg." },
    { value: "Management Of Companies And Enterprises", label: "Management Of Companies And Enterprises" },
    { value: "Manufactured Home Wholesaling", label: "Manufactured Home Wholesaling" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Manufacturing Industry", label: "Manufacturing Industry" },
    { value: "Margarine & Cooking Oil Processing", label: "Margarine & Cooking Oil Processing" },
    { value: "Maritime Transport", label: "Maritime Transport" },
    { value: "Marketing And Advertising", label: "Marketing And Advertising" },
    { value: "Marketing Consulting", label: "Marketing Consulting" },
    { value: "Masonry", label: "Masonry" },
    { value: "Mattress Mfg.", label: "Mattress Mfg." },
    { value: "Meat Industry", label: "Meat Industry" },
    { value: "Meat, Beef & Poultry Processing", label: "Meat, Beef & Poultry Processing" },
    { value: "Mechanical And Electrical Engineering", label: "Mechanical And Electrical Engineering" },
    { value: "Media", label: "Media" },
    { value: "Media Industry", label: "Media Industry" },
    { value: "Medical Device Mfg.", label: "Medical Device Mfg." },
    { value: "Medical Devices", label: "Medical Devices" },
    { value: "Medical Instrument & Supply Mfg.", label: "Medical Instrument & Supply Mfg." },
    { value: "Medical Supplies Wholesaling", label: "Medical Supplies Wholesaling" },
    { value: "Merchandising Industry", label: "Merchandising Industry" },
    { value: "Metal Can & Container Mfg.", label: "Metal Can & Container Mfg." },
    { value: "Metal Fabrication", label: "Metal Fabrication" },
    { value: "Metal Mining", label: "Metal Mining" },
    { value: "Metal Pipe & Tube Mfg.", label: "Metal Pipe & Tube Mfg." },
    { value: "Metal Plating & Treating", label: "Metal Plating & Treating" },
    { value: "Metal Stamping & Forging", label: "Metal Stamping & Forging" },
    { value: "Metal Tank Mfg.", label: "Metal Tank Mfg." },
    { value: "Metal Wholesaling", label: "Metal Wholesaling" },
    { value: "Metalworking Machinery Mfg.", label: "Metalworking Machinery Mfg." },
    { value: "Millwork", label: "Millwork" },
    { value: "Mineral & Phosphate Mining", label: "Mineral & Phosphate Mining" },
    { value: "Mineral Processing", label: "Mineral Processing" },
    { value: "Mineral Product Mfg.", label: "Mineral Product Mfg." },
    { value: "Mining", label: "Mining" },
    { value: "Mining (Coal; Other Mining)", label: "Mining (Coal; Other Mining)" },
    { value: "Mining And Quarrying", label: "Mining And Quarrying" },
    { value: "Mining Industry", label: "Mining Industry" },
    { value: "Mining, Oil & Gas Machinery Mfg.", label: "Mining, Oil & Gas Machinery Mfg." },
    { value: "Mining, Quarrying, And Oil And Gas Extraction", label: "Mining, Quarrying, And Oil And Gas Extraction" },
    { value: "Mobile Services", label: "Mobile Services" },
    { value: "Molybdenum & Metal Ore Mining", label: "Molybdenum & Metal Ore Mining" },
    { value: "Motorcycle, Bike & Parts Mfg.", label: "Motorcycle, Bike & Parts Mfg." },
    { value: "Municipal Building Construction", label: "Municipal Building Construction" },
    { value: "Museums And Galleries", label: "Museums And Galleries" },
    { value: "Music Industry", label: "Music Industry" },
    { value: "Natural Gas Distribution", label: "Natural Gas Distribution" },
    { value: "Navigational Instrument Mfg.", label: "Navigational Instrument Mfg." },
    { value: "News Media Industry", label: "News Media Industry" },
    { value: "Ngos", label: "Ngos" },
    { value: "Non-Metallic Mining", label: "Non-Metallic Mining" },
    { value: "Nonferrous Metal Foundry Products Mfg.", label: "Nonferrous Metal Foundry Products Mfg." },
    { value: "Nonferrous Metal Refining", label: "Nonferrous Metal Refining" },
    { value: "Nonferrous Metal Rolling & Alloying", label: "Nonferrous Metal Rolling & Alloying" },
    { value: "Nonprofit And Social Services", label: "Nonprofit And Social Services" },
    { value: "Nuclear Power", label: "Nuclear Power" },
    { value: "Office Administrative Services", label: "Office Administrative Services" },
    { value: "Office Furniture Mfg.", label: "Office Furniture Mfg." },
    { value: "Office Stationery Mfg.", label: "Office Stationery Mfg." },
    { value: "Office Stationery Wholesaling", label: "Office Stationery Wholesaling" },
    { value: "Oil & Gas Field Services", label: "Oil & Gas Field Services" },
    { value: "Oil & Gas Pipeline Construction", label: "Oil & Gas Pipeline Construction" },
    { value: "Oil And Gas Extraction", label: "Oil And Gas Extraction" },
    { value: "Oil And Gas Industry", label: "Oil And Gas Industry" },
    { value: "Oil And Gas Production; Oil Refining", label: "Oil And Gas Production; Oil Refining" },
    { value: "Oil Drilling & Gas Extraction", label: "Oil Drilling & Gas Extraction" },
    { value: "Oilseed Farming", label: "Oilseed Farming" },
    { value: "Online Education", label: "Online Education" },
    { value: "Online Retail", label: "Online Retail" },
    { value: "Orange & Citrus Groves", label: "Orange & Citrus Groves" },
    { value: "Organic Chemical Mfg.", label: "Organic Chemical Mfg." },
    { value: "Organic Farming", label: "Organic Farming" },
    { value: "Other Services (Except Public Administration)", label: "Other Services (Except Public Administration)" },
    { value: "Other Support Services", label: "Other Support Services" },
    { value: "Oxygen & Hydrogen Gas Mfg.", label: "Oxygen & Hydrogen Gas Mfg." },
    { value: "Paint Wholesaling", label: "Paint Wholesaling" },
    { value: "Painters", label: "Painters" },
    { value: "Paper And Pulp", label: "Paper And Pulp" },
    { value: "Paper Bag & Disposable Plastic Product Wholesaling", label: "Paper Bag & Disposable Plastic Product Wholesaling" },
    { value: "Paper Mills", label: "Paper Mills" },
    { value: "Paper Product Mfg.", label: "Paper Product Mfg." },
    { value: "Paper Wholesaling", label: "Paper Wholesaling" },
    { value: "Paperboard Mills", label: "Paperboard Mills" },
    { value: "Parts And Accessories", label: "Parts And Accessories" },
    { value: "Performing Arts (Theater, Dance)", label: "Performing Arts (Theater, Dance)" },
    { value: "Personal Care Services (Salons, Spas)", label: "Personal Care Services (Salons, Spas)" },
    { value: "Pesticide Mfg.", label: "Pesticide Mfg." },
    { value: "Petrochemical Mfg.", label: "Petrochemical Mfg." },
    { value: "Petroleum Refining", label: "Petroleum Refining" },
    { value: "Pharmaceutical Industry", label: "Pharmaceutical Industry" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals" },
    { value: "Philanthropy", label: "Philanthropy" },
    { value: "Piece Goods, Notions & Other Apparel Wholesaling", label: "Piece Goods, Notions & Other Apparel Wholesaling" },
    { value: "Plant & Flower Growing", label: "Plant & Flower Growing" },
    { value: "Plastic & Resin Mfg.", label: "Plastic & Resin Mfg." },
    { value: "Plastic Bottle Mfg.", label: "Plastic Bottle Mfg." },
    { value: "Plastic Film, Sheet & Bag Mfg.", label: "Plastic Film, Sheet & Bag Mfg." },
    { value: "Plastic Pipe & Parts Mfg.", label: "Plastic Pipe & Parts Mfg." },
    { value: "Plastic Products Miscellaneous Mfg.", label: "Plastic Products Miscellaneous Mfg." },
    { value: "Plastics", label: "Plastics" },
    { value: "Plastics & Rubber Machinery Mfg.", label: "Plastics & Rubber Machinery Mfg." },
    { value: "Plastics Wholesaling", label: "Plastics Wholesaling" },
    { value: "Plumbers", label: "Plumbers" },
    { value: "Plumbing & Heating Supplies Wholesaling", label: "Plumbing & Heating Supplies Wholesaling" },
    { value: "Polystyrene Foam Mfg.", label: "Polystyrene Foam Mfg." },
    { value: "Postal And Telecommunications Services", label: "Postal And Telecommunications Services" },
    { value: "Power Conversion Equipment Mfg.", label: "Power Conversion Equipment Mfg." },
    { value: "Power Tools & Other General Purpose Machinery Mfg.", label: "Power Tools & Other General Purpose Machinery Mfg." },
    { value: "Precast Concrete Mfg.", label: "Precast Concrete Mfg." },
    { value: "Prefabricated Home Mfg.", label: "Prefabricated Home Mfg." },
    { value: "Primary And Secondary Education", label: "Primary And Secondary Education" },
    { value: "Printing", label: "Printing" },
    { value: "Printing Services", label: "Printing Services" },
    { value: "Printing, Paper, Food, Textile & Other Machinery Mfg.", label: "Printing, Paper, Food, Textile & Other Machinery Mfg." },
    { value: "Professional And Business Services", label: "Professional And Business Services" },
    { value: "Professional Industry", label: "Professional Industry" },
    { value: "Professional, Scientific And Technical Services", label: "Professional, Scientific And Technical Services" },
    { value: "Property And Casualty Insurance", label: "Property And Casualty Insurance" },
    { value: "Property Management", label: "Property Management" },
    { value: "Public Administration", label: "Public Administration" },
    { value: "Public Relations", label: "Public Relations" },
    { value: "Public Service", label: "Public Service" },
    { value: "Publishing", label: "Publishing" },
    { value: "Publishing Industry", label: "Publishing Industry" },
    { value: "Pulp And Paper Industry", label: "Pulp And Paper Industry" },
    { value: "Pump & Compressor Mfg.", label: "Pump & Compressor Mfg." },
    { value: "Quarrying", label: "Quarrying" },
    { value: "Quick Printing", label: "Quick Printing" },
    { value: "Railway Industry", label: "Railway Industry" },
    { value: "Ready-Mix Concrete Mfg.", label: "Ready-Mix Concrete Mfg." },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Real Estate And Rental And Leasing", label: "Real Estate And Rental And Leasing" },
    { value: "Real Estate Development", label: "Real Estate Development" },
    { value: "Real Estate Industry", label: "Real Estate Industry" },
    { value: "Real Estate Investment Trusts (Reits)", label: "Real Estate Investment Trusts (Reits)" },
    { value: "Recordable Media Mfg.", label: "Recordable Media Mfg." },
    { value: "Recyclable Material Wholesaling", label: "Recyclable Material Wholesaling" },
    { value: "Refrigeration Equipment Wholesaling", label: "Refrigeration Equipment Wholesaling" },
    { value: "Remodeling", label: "Remodeling" },
    { value: "Renewable Energy", label: "Renewable Energy" },
    { value: "Renewable Energy (Solar, Wind, Hydro)", label: "Renewable Energy (Solar, Wind, Hydro)" },
    { value: "Renovation And Remodeling", label: "Renovation And Remodeling" },
    { value: "Repair And Maintenance Services", label: "Repair And Maintenance Services" },
    { value: "Research And Development", label: "Research And Development" },
    { value: "Residential Construction", label: "Residential Construction" },
    { value: "Residential Real Estate", label: "Residential Real Estate" },
    { value: "Restaurant & Hotel Equipment Wholesaling", label: "Restaurant & Hotel Equipment Wholesaling" },
    { value: "Restaurants And Food Services", label: "Restaurants And Food Services" },
    { value: "Retail", label: "Retail" },
    { value: "Retail Industry", label: "Retail Industry" },
    { value: "Retail Trade", label: "Retail Trade" },
    { value: "Retail Trade (Brick-And-Mortar And E-Commerce)", label: "Retail Trade (Brick-And-Mortar And E-Commerce)" },
    { value: "Road & Highway Construction", label: "Road & Highway Construction" },
    { value: "Roofing Contractors", label: "Roofing Contractors" },
    { value: "Roofing, Siding & Insulation Wholesaling", label: "Roofing, Siding & Insulation Wholesaling" },
    { value: "Rubber Product Mfg.", label: "Rubber Product Mfg." },
    { value: "Sand & Gravel Mining", label: "Sand & Gravel Mining" },
    { value: "Sanitary Paper Product Mfg.", label: "Sanitary Paper Product Mfg." },
    { value: "Satellite Communications", label: "Satellite Communications" },
    { value: "Satellite Services", label: "Satellite Services" },
    { value: "Sawmills & Wood Production", label: "Sawmills & Wood Production" },
    { value: "Scientific", label: "Scientific" },
    { value: "Scientific Industry", label: "Scientific Industry" },
    { value: "Screw, Nut & Bolt Mfg.", label: "Screw, Nut & Bolt Mfg." },
    { value: "Seafood Preparation", label: "Seafood Preparation" },
    { value: "Seasoning, Sauce And Condiment Production", label: "Seasoning, Sauce And Condiment Production" },
    { value: "Semiconductor & Circuit Mfg.", label: "Semiconductor & Circuit Mfg." },
    { value: "Semiconductor Machinery Mfg.", label: "Semiconductor Machinery Mfg." },
    { value: "Services Industry", label: "Services Industry" },
    { value: "Services To Buildings And Dwellings", label: "Services To Buildings And Dwellings" },
    { value: "Sewage Treatment Facilities", label: "Sewage Treatment Facilities" },
    { value: "Sheep Farming", label: "Sheep Farming" },
    { value: "Sheet Metal, Window & Door Mfg.", label: "Sheet Metal, Window & Door Mfg." },
    { value: "Ship Building", label: "Ship Building" },
    { value: "Shipping; Ports; Fisheries; Inland Waterways", label: "Shipping; Ports; Fisheries; Inland Waterways" },
    { value: "Shoe & Footwear Mfg.", label: "Shoe & Footwear Mfg." },
    { value: "Snack Food Production", label: "Snack Food Production" },
    { value: "Soap & Cleaning Compound Mfg.", label: "Soap & Cleaning Compound Mfg." },
    { value: "Soda Production", label: "Soda Production" },
    { value: "Soft Drink, Baked Goods & Other Grocery Wholesaling", label: "Soft Drink, Baked Goods & Other Grocery Wholesaling" },
    { value: "Software Development", label: "Software Development" },
    { value: "Software Industry", label: "Software Industry" },
    { value: "Solar Panel Mfg.", label: "Solar Panel Mfg." },
    { value: "Soybean Farming", label: "Soybean Farming" },
    { value: "Space Exploration", label: "Space Exploration" },
    { value: "Space Industry", label: "Space Industry" },
    { value: "Space Vehicle & Missile Mfg.", label: "Space Vehicle & Missile Mfg." },
    { value: "Specialized Construction (Energy, Utilities)", label: "Specialized Construction (Energy, Utilities)" },
    { value: "Sport Industry", label: "Sport Industry" },
    { value: "Sporting Goods Wholesaling", label: "Sporting Goods Wholesaling" },
    { value: "Steam & Air-Conditioning Supply", label: "Steam & Air-Conditioning Supply" },
    { value: "Steel Industry", label: "Steel Industry" },
    { value: "Stone Mining", label: "Stone Mining" },
    { value: "Stone, Concrete & Clay Wholesaling", label: "Stone, Concrete & Clay Wholesaling" },
    { value: "Structural Metal Product Mfg.", label: "Structural Metal Product Mfg." },
    { value: "Sugar Processing", label: "Sugar Processing" },
    { value: "Sugarcane Harvesting", label: "Sugarcane Harvesting" },
    { value: "Supply Chain Management", label: "Supply Chain Management" },
    { value: "Sustainability Services", label: "Sustainability Services" },
    { value: "Swimming Pool Construction", label: "Swimming Pool Construction" },
    { value: "Synthetic Fiber Mfg.", label: "Synthetic Fiber Mfg." },
    { value: "Syrup & Flavoring Production", label: "Syrup & Flavoring Production" },
    { value: "Tank & Armored Vehicle Manufacturing", label: "Tank & Armored Vehicle Manufacturing" },
    { value: "Tea Production", label: "Tea Production" },
    { value: "Technology Industry", label: "Technology Industry" },
    { value: "Telecommunication Networking Equipment Manufacturing", label: "Telecommunication Networking Equipment Manufacturing" },
    { value: "Telecommunications Industry", label: "Telecommunications Industry" },
    { value: "Telemedicine", label: "Telemedicine" },
    { value: "Textile Industry", label: "Textile Industry" },
    { value: "Textile Mills", label: "Textile Mills" },
    { value: "Textiles And Apparel", label: "Textiles And Apparel" },
    { value: "Tile Installers", label: "Tile Installers" },
    { value: "Timber Services", label: "Timber Services" },
    { value: "Tire Manufacturing", label: "Tire Manufacturing" },
    { value: "Tire Wholesaling", label: "Tire Wholesaling" },
    { value: "Tobacco Industry", label: "Tobacco Industry" },
    { value: "Tool & Hardware Wholesaling", label: "Tool & Hardware Wholesaling" },
    { value: "Tortilla Production", label: "Tortilla Production" },
    { value: "Tour Operators", label: "Tour Operators" },
    { value: "Toy & Craft Supplies Wholesaling", label: "Toy & Craft Supplies Wholesaling" },
    { value: "Toy, Doll & Game Manufacturing", label: "Toy, Doll & Game Manufacturing" },
    { value: "Tractors & Agricultural Machinery Manufacturing", label: "Tractors & Agricultural Machinery Manufacturing" },
    { value: "Train, Subway & Transit Car Manufacturing", label: "Train, Subway & Transit Car Manufacturing" },
    { value: "Transmission Line Construction", label: "Transmission Line Construction" },
    { value: "Transport Industry", label: "Transport Industry" },
    { value: "Transportation And Logistics", label: "Transportation And Logistics" },
    { value: "Transportation And Warehousing", label: "Transportation And Warehousing" },
    { value: "Travel Agencies", label: "Travel Agencies" },
    { value: "Travel Arrangement And Reservation Services", label: "Travel Arrangement And Reservation Services" },
    { value: "Truck & Bus Manufacturing", label: "Truck & Bus Manufacturing" },
    { value: "Truck, Trailer & Motor Home Manufacturing", label: "Truck, Trailer & Motor Home Manufacturing" },
    { value: "TV & Appliance Wholesaling", label: "TV & Appliance Wholesaling" },
    { value: "Urethane Foam Manufacturing", label: "Urethane Foam Manufacturing" },
    { value: "Used Car Parts Wholesaling", label: "Used Car Parts Wholesaling" },
    { value: "Utilities", label: "Utilities" },
    { value: "Utility Industry", label: "Utility Industry" },
    { value: "Vacuum, Fan & Small Household Appliance Manufacturing", label: "Vacuum, Fan & Small Household Appliance Manufacturing" },
    { value: "Valve Manufacturing", label: "Valve Manufacturing" },
    { value: "Vegetable Farming", label: "Vegetable Farming" },
    { value: "Vehicle Manufacturing", label: "Vehicle Manufacturing" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Video Game Industry", label: "Video Game Industry" },
    { value: "Vitamin & Supplement Manufacturing", label: "Vitamin & Supplement Manufacturing" },
    { value: "Vocational Training", label: "Vocational Training" },
    { value: "VoIP Services", label: "VoIP Services" },
    { value: "Warehousing And Storage", label: "Warehousing And Storage" },
    { value: "Waste Management", label: "Waste Management" },
    { value: "Water & Sewer Line Construction", label: "Water & Sewer Line Construction" },
    { value: "Water Industry", label: "Water Industry" },
    { value: "Water Supply", label: "Water Supply" },
    { value: "Water Supply & Irrigation Systems", label: "Water Supply & Irrigation Systems" },
    { value: "Water Treatment", label: "Water Treatment" },
    { value: "Wheat, Barley & Sorghum Farming", label: "Wheat, Barley & Sorghum Farming" },
    { value: "Wholesale And Retail Trade", label: "Wholesale And Retail Trade" },
    { value: "Wholesale Distribution", label: "Wholesale Distribution" },
    { value: "Wholesale Trade", label: "Wholesale Trade" },
    { value: "Wholesale Trade Agents And Brokers", label: "Wholesale Trade Agents And Brokers" },
    { value: "Wind Power", label: "Wind Power" },
    { value: "Wind Turbine Manufacturing", label: "Wind Turbine Manufacturing" },
    { value: "Wine & Spirits Wholesaling", label: "Wine & Spirits Wholesaling" },
    { value: "Wineries", label: "Wineries" },
    { value: "Wire & Cable Manufacturing", label: "Wire & Cable Manufacturing" },
    { value: "Wire & Spring Manufacturing", label: "Wire & Spring Manufacturing" },
    { value: "Wiring Device Manufacturing", label: "Wiring Device Manufacturing" },
    { value: "Women’s, Girls’ And Infants’ Apparel Manufacturing", label: "Women’s, Girls’ And Infants’ Apparel Manufacturing" },
    { value: "Wood Framing", label: "Wood Framing" },
    { value: "Wood Pallets & Skids Production", label: "Wood Pallets & Skids Production" },
    { value: "Wood Paneling Manufacturing", label: "Wood Paneling Manufacturing" },
    { value: "Wood Product Manufacturing", label: "Wood Product Manufacturing" },
    { value: "Wood Pulp Mills", label: "Wood Pulp Mills" },
    { value: "Woodworking Machinery Manufacturing", label: "Woodworking Machinery Manufacturing" },
    { value: "Assessment Consulting", label: "Assessment Consulting" },
    { value: "Brand Consulting", label: "Brand Consulting" },
    { value: "Change Management Consulting", label: "Change Management Consulting" },
    { value: "Civil Engineering Consulting", label: "Civil Engineering Consulting" },
    { value: "Clinical Operations Consulting", label: "Clinical Operations Consulting" },
    { value: "Cloud Computing Consulting", label: "Cloud Computing Consulting" },
    { value: "Community Engagement Consulting", label: "Community Engagement Consulting" },
    { value: "Compensation Consulting", label: "Compensation Consulting" },
    { value: "Compliance And Regulatory Consulting", label: "Compliance And Regulatory Consulting" },
    { value: "Compliance Consulting", label: "Compliance Consulting" },
    { value: "Contract Management Consulting", label: "Contract Management Consulting" },
    { value: "Corporate Finance Consulting", label: "Corporate Finance Consulting" },
    { value: "Crisis Management Consulting", label: "Crisis Management Consulting" },
    { value: "Curriculum Development Consulting", label: "Curriculum Development Consulting" },
    { value: "Customer Relationship Management (CRM) Consulting", label: "Customer Relationship Management (CRM) Consulting" },
    { value: "Cybersecurity Consulting", label: "Cybersecurity Consulting" },
    { value: "Data Analytics Consulting", label: "Data Analytics Consulting" },
    { value: "Digital Marketing Consulting", label: "Digital Marketing Consulting" },
    { value: "Digital Transformation Consulting", label: "Digital Transformation Consulting" },
    { value: "Education Consulting", label: "Education Consulting" },
    { value: "Educational Technology Consulting", label: "Educational Technology Consulting" },
    { value: "Employee Benefits Consulting", label: "Employee Benefits Consulting" },
    { value: "Energy Efficiency Consulting", label: "Energy Efficiency Consulting" },
    { value: "Engineering Consulting", label: "Engineering Consulting" },
    { value: "Enterprise Risk Management", label: "Enterprise Risk Management" },
    { value: "Environmental Consulting", label: "Environmental Consulting" },
    { value: "Environmental Engineering Consulting", label: "Environmental Engineering Consulting" },
    { value: "Environmental Impact Assessment", label: "Environmental Impact Assessment" },
    { value: "Financial Consulting", label: "Financial Consulting" },
    { value: "Fraud Prevention Consulting", label: "Fraud Prevention Consulting" },
    { value: "Fundraising Consulting", label: "Fundraising Consulting" },
    { value: "Health IT Consulting", label: "Health IT Consulting" },
    { value: "Healthcare Consulting", label: "Healthcare Consulting" },
    { value: "Healthcare Management Consulting", label: "Healthcare Management Consulting" },
    { value: "Human Resources Consulting", label: "Human Resources Consulting" },
    { value: "Intellectual Property Consulting", label: "Intellectual Property Consulting" },
    { value: "Inventory Management Consulting", label: "Inventory Management Consulting" },
    { value: "Investment Consulting", label: "Investment Consulting" },
    { value: "IT Consulting", label: "IT Consulting" },
    { value: "IT Strategy Consulting", label: "IT Strategy Consulting" },
    { value: "Legal Compliance Consulting", label: "Legal Compliance Consulting" },
    { value: "Legal Consulting", label: "Legal Consulting" },
    { value: "Litigation Support Consulting", label: "Litigation Support Consulting" },
    { value: "Logistics Consulting", label: "Logistics Consulting" },
    { value: "Management Consulting", label: "Management Consulting" },
    { value: "Market Analysis Consulting", label: "Market Analysis Consulting" },
    { value: "Market Research Consulting", label: "Market Research Consulting" },
    { value: "Marketing Consulting", label: "Marketing Consulting" },
    { value: "Nonprofit Consulting", label: "Nonprofit Consulting" },
    { value: "Operations Consulting", label: "Operations Consulting" },
    { value: "Organizational Consulting", label: "Organizational Consulting" },
    { value: "Organizational Development Consulting", label: "Organizational Development Consulting" },
    { value: "Procurement Consulting", label: "Procurement Consulting" },
    { value: "Program Evaluation Consulting", label: "Program Evaluation Consulting" },
    { value: "Property Valuation Consulting", label: "Property Valuation Consulting" },
    { value: "Public Relations Consulting", label: "Public Relations Consulting" },
    { value: "Real Estate Consulting", label: "Real Estate Consulting" },
    { value: "Real Estate Investment Consulting", label: "Real Estate Investment Consulting" },
    { value: "Recruitment And Staffing Consulting", label: "Recruitment And Staffing Consulting" },
    { value: "Regulatory Compliance Consulting", label: "Regulatory Compliance Consulting" },
    { value: "Risk Consulting", label: "Risk Consulting" },
    { value: "Risk Management Consulting", label: "Risk Management Consulting" },
    { value: "Sales Consulting", label: "Sales Consulting" },
    { value: "Sales Process Optimization", label: "Sales Process Optimization" },
    { value: "Sales Strategy Consulting", label: "Sales Strategy Consulting" },
    { value: "Sales Training Consulting", label: "Sales Training Consulting" },
    { value: "School Improvement Consulting", label: "School Improvement Consulting" },
    { value: "Software Implementation Consulting", label: "Software Implementation Consulting" },
    { value: "Strategy Consulting", label: "Strategy Consulting" },
    { value: "Structural Engineering Consulting", label: "Structural Engineering Consulting" },
    { value: "Supplier Relationship Management Consulting", label: "Supplier Relationship Management Consulting" },
    { value: "Supply Chain Consulting", label: "Supply Chain Consulting" },
    { value: "Sustainability Consulting", label: "Sustainability Consulting" },
    { value: "System Integration Consulting", label: "System Integration Consulting" },
    { value: "Tax Consulting", label: "Tax Consulting" },
    { value: "Technology Consulting", label: "Technology Consulting" },
    { value: "Technology Implementation Consulting", label: "Technology Implementation Consulting" },
    { value: "Training And Development Consulting", label: "Training And Development Consulting" },
    { value: "Transportation Engineering Consulting", label: "Transportation Engineering Consulting" },
    { value: "Urban Planning Consulting", label: "Urban Planning Consulting" }
  ];
  const GetSpecializations = () => {
    let token = localStorage.getItem("authToken");
    let currentUserId = localStorage.getItem("customer_id");
    simpleGetCall(
      `${ApiConfig.GET_SPECIALIZATIONS}?currentUserId=${currentUserId}&token=${token}`
    )
      // simpleGetCall(ApiConfig.GET_SPECIALIZATIONS)
      .then((res) => {
        if (res.response) {
          const options = [];

          res?.response?.forEach((item) => {
            options.push({
              value: item?.specializationId,
              label: item?.specializationName,
            });
          });

          console.log(options, "2222222222222222222222222222222222222");

          setSpaciallist(options);

          // setLoading(false);
          // Extract the array of items from the response
          // Set the array to the state
        } else {
          // notifyError(res.message)
          // Handle the case when the result is false
          // notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const submitAddtionalInfor = () => {
    let isValidated = true;

    let fieldValidation = "";
    let educationLevelValidation = "";
    let exprienceValidation = "";
    let jobTitleValidation = "";
    let currentCompanyValidation = "";
    let currentIndustryValidation = "";
    let dateValidation = "";
    let expectedSal = "";
    let uploadResumeErr = "";

    if (educationLevel?.length === 0) {
      educationLevelValidation = "Please Select Education";

      isValidated = false;
    } else {
      educationLevelValidation = "";
    }

    if (spaciallist?.length === 0) {
      fieldValidation = "Please Select Field";

      isValidated = false;
    } else {
      fieldValidation = "";
    }

    if (experience.length === 0) {
      exprienceValidation = "Please Enter Experience";

      isValidated = false;
    } else {
      exprienceValidation = "";
    }

    if (currentJobTitle.length === 0) {
      jobTitleValidation = "Please Enter Job Title";

      isValidated = false;
    } else {
      jobTitleValidation = "";
    }

    if (showorhidden) {
      if (uploadResumeName.length === 0) {
        uploadResumeErr = "please Select Resume";
        isValidated = false;
      }

      if (currentCompany.length === 0) {
        currentCompanyValidation = "Please Enter Company Name";

        isValidated = false;
      } else {
        currentCompanyValidation = "";
      }

      if (currentIndustry.length === 0) {
        currentIndustryValidation = "Please Select Current Industry";

        isValidated = false;
      } else {
        currentIndustryValidation = "";
      }
      if (!availableFrom) {
        dateValidation = "Please select today or a future date";
        isValidated = false;
      } else {
        dateValidation = "";
        isValidated = true;
      }

      // const today = new Date();

      // if (!availableFrom) {
      //   dateValidation = "Please Select Date";
      //   isValidated = false;
      // } else {
      //   const selectedDate = new Date(availableFrom);

      //   if (selectedDate < today) {
      //     dateValidation = "Please select today or a future date";
      //     isValidated = false;
      //   } else {
      //     dateValidation = "";
      //   }
      // }

      if (expectedSalary?.length === 0) {
        isValidated = false;

        expectedSal = "Please Add Expected Salary";
      } else {
        expectedSal = "";
      }
      if (uploadResume.length === 0) {
        isValidated = false;
      }
    }

    setValidation((prev) => ({
      ...prev,
      educationValidation: educationLevelValidation,
      fieldValidation: fieldValidation,
      experienceValidation: exprienceValidation,
      jobTitleValidation: jobTitleValidation,
      currentCompanyValidation: currentCompanyValidation,
      currentIndustryValidation: currentIndustryValidation,
      dateValidation: dateValidation,
      expectedSalValidation: expectedSal,
      uploadResumeErr: uploadResumeErr,
    }));

    const currentUserId = parseInt(localStorage.getItem("customer_id"));

    if (isValidated) {
      console.log(currentUserId, "upload");
      let requestData;

      requestData = {
        userId: currentUserId, // Assuming this is a constant value
        educationlevel: educationLevel,
        fieldOfEducation: spaciallistid,
        experinces: experience,
        currentJobTittle: currentJobTitle,
        currentCompany: currentCompany,
        currentIndustry: currentIndustry,
        availableFrom: availableFrom,
        noticePeriodbuyoptions: buyOutOption, // Convert to boolean
        expectedSalary: expectedSalary,
        uploadResume: uploadResumeSelected? uploadResume : null,
        uploadResumeName: uploadResumeName,
      };
      //  alert(requestData)
      //  console.log("request",requestData);
      //  return ;
      if (requestData) {
        simplePostCall(
          ApiConfig.SUBMIT_ADDITIONINFO,
          JSON.stringify(requestData)
        )
          .then((res) => {
            if (res.succeded) {
              if (userlgin) {
                navigate("/");
              } else {
                navigate("/CompanyName");
              }
            } else {
              // Handle the case when the response is false or invalid
              console.error("Error: No response or response is false");
            }
          })
          .catch((err) => {
            console.log("Error:", err);
          })
          .finally(() => {
            // setLoading(false);
          });
      }
    }
  };

  const GetSpecializationData = () => {
    let token = localStorage.getItem("authToken");
    const currentUserId = localStorage.getItem("customer_id");
    console.log(currentUserId);

    simpleGetCall(
      `${ApiConfig.GET_ADDITIONINFO}?UserId=${currentUserId}&token=${token}`
    )
      .then((res) => {
        console.log(res, "spacialdata");
        if (res.response) {
          if (res.response) {
            setGetSpacialData(res.response);
            populateFormFields(res.response);
          }
          console.log(res.response);
          setGetSpacialData(res.response);

          // setLoading(false);
          // Extract the array of items from the response
          // Set the array to the state
        } else {
          // notifyError(res.message)
          // Handle the case when the result is false
          // notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const populateFormFields = (data) => {
    console.log(data.educationlevel, "data populated");
    // Populate form fields with the fetched data if available

    setEducationLevel(data.educationlevel || "");
    setSpaciallistID(data.specializationId || "");
    setExperience(data.experinces || "");
    setCurrentJobTitle(data.currentJobTittle || "");
    setCurrentCompany(data.currentCompany || "");
    setCurrentIndustry(data.currentIndustry || "");
    setUploadResume(data);
    if (data.availableFrom) {
      const date = new Date(data.availableFrom).toISOString();
      setAvailableFrom(date);
    } else {
      setAvailableFrom("");
    }
    // setAvailableFrom(data.availableFrom || '');
    setBuyOutOption(data.noticePeriodbuyoptions);
    setExpectedSalary(data.expectedSalary || "");
    // setUploadResume(data.uploadResume || '');
    setUploadResumeName(data.uploadResume || '');
  };
  return (
    <div className="aiWrap">
      <div className="clLft-side">
        <div className="clLogoStyleMob" ref={scrollHomeAdd}>
          <a href="/">
            <img src={LYWOLogoSmall} className="clLogoStyle" alt="Logo" />
          </a>
        </div>
        <div className="illustrationContainer">
          <img src={Illustration} className="illustImg" alt="Logo" />
        </div>
      </div>
      {showorhidden ? (
        <div className="row d-flex formSpc">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 loginCode">
            <div className="text-center">
              <p>Additional information</p>
              <div className="addInfsubTxt" ref={scrollElementref}>
                <p>
                  Almost there! Please share some more information before we
                  take you to the test.
                </p>
              </div>
            </div>
            <div className="addInfrmtnArea">
              <div className="mb-3">
                <label htmlFor="EducationLevelInput">Education Level</label>
                <div
                  className={`input-group ${
                    validation.educationValidation && educationLevel == ""
                      ? // ? "make-red-border"
                        ""
                      : ""
                  }`}
                >
                  <Select
                    styles={customStyles}
                    id="EducationLevelInput"
                    name="EducationLevelInput"
                    className={`form-selection scrollable-select`}
                    // value={educationLevel}
                    value={options.find(
                      (option) => option.value === educationLevel
                    )}
                    onChange={(selectedOption) =>
                      setEducationLevel(selectedOption.value)
                    }
                    options={options}
                  ></Select>
                </div>
                {/* <p
                  className={`${
                    validation.educationValidation && educationLevel == ""
                      ? "instructionTxtError"
                      : "d-none instructionTxt"
                  }`}
                >
                  {validation?.educationValidation}
                </p> */}
              </div>
              {/* <div className="mb-3">
                <label htmlFor="EducationLevelInput">Education Level</label>
                <div className="input-group">
                  <select
                    id="EducationLevelInput"
                    name="EducationLevelInput"
                    className={`form-select scrollable-select ${
                      validation.educationValidation && !educationLevel
                        ? "border-danger"
                        : ""
                    }`}
                    value={educationLevel}
                    onChange={(e) => setEducationLevel(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Education Level
                    </option>
                    <option value="Bachelor of Engineering (B.E.)">
                      Bachelor of Engineering (B.E.)
                    </option>
                    <option value="Bachelor of Technology(B.Tech.)">
                      Bachelor of Technology(B.Tech.)
                    </option>
                    <option value="Master of Engineering (M.E)">
                      Master of Engineering (M.E)
                    </option>
                    <option value="Master of Technology(M.Tech)">
                      Master of Technology(M.Tech)
                    </option>
                    <option value="Diploma">Diploma</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Post Graduate Diploma in Management (PGDM)">
                      Post Graduate Diploma in Management (PGDM)
                    </option>
                    <option value="⁠Chartered Accountancy (CA)">
                      ⁠Chartered Accountancy (CA)
                    </option>
                    <option value="⁠Company Secretary (CS)">
                      ⁠Company Secretary (CS)
                    </option>
                    <option value="⁠Integrated Bachelor of Laws (B.A. LLB, B.B.A. LLB, etc.)">
                      ⁠Integrated Bachelor of Laws (B.A. LLB, B.B.A. LLB, etc.)
                    </option>

                  </select>
                </div>
                <p
                  className={`${
                    validation.educationValidation && !educationLevel
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.educationValidation}
                </p>
              </div> */}

              <div className="mb-3">
                <label htmlFor="FieldOfEducationInput">
                  Field of Education
                </label>
                <div className="input-group">
                  <Select
                    // components={{
                    //   Input: (props) => (
                    //     <input {...props} className="customInput" />
                    //   ),
                    // }}
                    placeholder="Select an option"
                    value={spaciallist?.filter((i) => i.value == spaciallistid)}
                    id="FieldOfEducationInput"
                    name="FieldOfEducationInput"
                    className="form-selection basic-single "
                    options={spaciallist}
                    styles={customStyles}
                    classNamePrefix="select"
                    onChange={(e) => setSpaciallistID(e.value)}
                    isSearchable={true}
                  />
                </div>
                <p
                  className={`${
                    validation.fieldValidation && !spaciallistid
                      ? "instructionTxtError"
                      : "d-none"
                  }`}
                >
                  Please Select Field Of Education
                </p>
              </div>
              {/* <div className="mb-3">
              <label htmlFor="FieldOfEducationInput">Field of Education</label>
              <div className="input-group">
                <select
                  id="FieldOfEducationInput"
                  name="FieldOfEducationInput"
                  className="form-select"
                  value={spaciallistid}
                  onChange={(e) => setSpaciallistID(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Field of Education
                  </option>
                  {spaciallist.map((item) => (
                  <option  value={item.specializationId } selected>
                     {item.specializationName}
                  </option>
                   
                ))}
                </select>
              </div>
              <p className="instructionTxt">{validation?.fieldValidation}</p>
            </div> */}
              {/* <div className="mb-3">
              <label htmlFor="ExperienceInput">Experience (in years)</label>
              <div className="input-group">
                <select
                  id="ExperienceInput"
                  name="ExperienceInput"
                  className="form-select"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Experience
                  </option>
                  <option value="1">Experience @1</option>
                  <option value="2">Experience @2</option>
                  <option value="3">Experience @3</option>
                </select>
              </div>
            </div> */}
              <div className="mb-3">
                <label htmlFor="ExperienceInput">Experience (in years)</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="ExperienceInput"
                    name="ExperienceInput"
                    className={`form-control ${
                      validation.experienceValidation && !experience.length > 0
                        ? "border-danger"
                        : ""
                    }`}
                    value={experience > 40 ? "40+" : experience}
                    // onChange={(e) => setExperience(e.target.value)}
                    onChange={handleExperienceChange}
                    placeholder="Enter your experience"
                    min="0"
                  />
                </div>
                <p
                  className={`${
                    validation.experienceValidation && !experience.length > 0
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.experienceValidation}
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="CurrentJobTitleInput">Current Job Title</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="CurrentJobTitleInput"
                    name="CurrentJobTitleInput"
                    placeholder="Current Job Title"
                    value={currentJobTitle}
                    className={`${
                      validation.jobTitleValidation &&
                      !currentJobTitle.length > 0
                        ? "border-danger"
                        : ""
                    }`}
                    onChange={(e) => setCurrentJobTitle(e.target.value)}
                  />
                </div>
                <p
                  className={`${
                    validation.jobTitleValidation && !currentJobTitle.length > 0
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.jobTitleValidation}
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="CurrentCompanyInput">Current Company</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="CurrentCompanyInput"
                    name="CurrentCompanyInput"
                    className={`${
                      validation.currentCompanyValidation &&
                      !currentCompany.length > 0
                        ? "border-danger"
                        : ""
                    }`}
                    placeholder="Current Company"
                    value={currentCompany}
                    onChange={(e) => setCurrentCompany(e.target.value)}
                  />
                </div>
                <p
                  className={`${
                    validation.currentCompanyValidation &&
                    !currentCompany.length > 0
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.currentCompanyValidation}
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="CurrentIndustryInput">Current Industry</label>
                <div
                  className={`input-group ${
                    validation.currentIndustryValidation &&
                    currentIndustry == ""
                      ? "make-red-border"
                      : ""
                  }`}
                >
                  <Select
                    id="CurrentIndustryInput"
                    styles={customStyles}
                    name="CurrentIndustryInput"
                    className={` form-selection `}
                    value={industryOptions.filter(
                      (i) => i.value === currentIndustry
                    )}
                    options={industryOptions}
                    onChange={(e) => setCurrentIndustry(e.value)}
                  ></Select>{" "}
                </div>
                <p
                  className={`${
                    validation.currentIndustryValidation &&
                    currentIndustry === ""
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.currentIndustryValidation}
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="AvailableFromInput">Available From</label>
                <div className="date-picker-container">
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    selected={availableFrom}
                    onChange={(date) => setAvailableFrom(date)}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    open={false}
                    className={`${
                      validation.dateValidation ? "border-danger" : ""
                    }`}
                  />
                </div>
                <p
                  className={`${
                    validation.dateValidation
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation.dateValidation}
                </p>
              </div>
              <div className="mb-3 yesno">
                <label htmlFor="YesOrNoInput">
                  Notice Period Buy Out Option Available?
                </label>
                <div className="input-groupp mt-2">
                  <div className="d-flex">
                    <input
                      type="radio"
                      id="buyOutOptionYes"
                      name="buyOutOption"
                      className="yesnoinput"
                      value={buyOutOption}
                      checked={buyOutOption}
                      onClick={() => handleOptionChange(true)}
                    />
                    <label
                      htmlFor="buyOutOptionYes"
                      className="ms-2 yesnolabel"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="d-flex ms-5">
                    <input
                      type="radio"
                      id="buyOutOptionNo"
                      name="buyOutOption"
                      className="yesnoinput"
                      value={!buyOutOption}
                      checked={!buyOutOption}
                      onClick={() => handleOptionChange(false)}
                    />
                    <label htmlFor="buyOutOptionNo" className="ms-2 yesnolabel">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="ExpectedSalaryInput">Expected Salary</label>
                <div
                  onClick={handleScrollSalary}
                  ref={scrollSalary}
                  className={`input-group ${
                    validation.expectedSalValidation && expectedSalary === ""
                      ? "make-red-border"
                      : ""
                  }`}
                >
                  <Select
                    styles={customStyles}
                    id="ExpectedSalaryInput"
                    name="ExpectedSalaryInput"
                    className="form-selection"
                    isSearchable={false}
                    value={salaryOptions?.filter(
                      (i) => i.value == expectedSalary
                    )}
                    options={salaryOptions}
                    onChange={(e) => setExpectedSalary(e.value)}
                  ></Select>
                </div>
                <p
                  className={`${
                    validation.expectedSalValidation && expectedSalary === ""
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.expectedSalValidation}
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="UploadResumeInput">Upload Resume</label>
                <div className="input-group">
                  <input                        // 
                    readOnly
                    type="text"
                    id="UploadResumeInput"
                    className={`${
                      validation.uploadResumeErr && uploadResumeName === ""
                        ? "border-danger"
                        : ""
                    }`}
                    name="UploadResumeInput"
                    placeholder="Upload Resume"
                    style={{ cursor: "pointer" }}
                    value={uploadResumeName}
                    onChange={(e) => {
                      setUploadResume(e.target.value);
                      setUploadResumeSelected(true);
                    }}
                    onClick={() =>
                      document.getElementById("hiddenFileInput").click()
                    }
                  />
                  <img
                    src={uploadIcon}
                    className="uploadIconStyl"
                    alt="Upload Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      document.getElementById("hiddenFileInput").click()
                    }
                  />
                  <input
                    type="file"
                    id="hiddenFileInput"
                    style={{ display: "none" }} 
                    accept=".pdf, .doc, .docx"
                    onChange={handleFileChange}
                  />
                </div>
                <p
                  className={`instructionTxt ${
                    validation.uploadResumeErr && uploadResumeName === ""
                      ? "text-danger"
                      : ""
                  } `}
                >
                  Upload your resume as a PDF or Word file, no larger than
                  25MBs.
                </p>
              </div>
            </div>
            <div className="btnArea">
              <button
                className="btnVldt"
                // onClick={() => navigate("/CompanyName")}
                onClick={() => submitAddtionalInfor()}
              >
                <p>Proceed to Test</p>
                <img
                  src={arrowRight}
                  className="arrowIconStyl"
                  alt="ArrowRight-Icon"
                />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex formSpc">
          <div className="col-auto loginCode">
            <div className="text-center">
              <p>Additional information</p>
              <div className="addInfsubTxt">
                <p>
                  Almost there! Please share some more information before we
                  take you to the test.
                </p>
              </div>
            </div>
            <div className="addInfrmtnArea" ref={scrollElementref}>
              <div className="mb-3">
                <label htmlFor="EducationLevelInput">Education Level</label>
                <div
                  className={`input-group ${
                    validation.educationValidation && educationLevel == ""
                      ? // ? "make-red-border"
                        ""
                      : ""
                  }`}
                >
                  <Select
                    styles={customStyles}
                    id="EducationLevelInput"
                    name="EducationLevelInput"
                    className={`form-selection scrollable-select`}
                    // value={educationLevel}
                    value={options.find(
                      (option) => option.value === educationLevel
                    )}
                    onChange={(selectedOption) =>
                      setEducationLevel(selectedOption.value)
                    }
                    options={options}
                  ></Select>
                </div>
                {/* <p
                  className={`${
                    validation.educationValidation && educationLevel == ""
                      ? "instructionTxtError"
                      : "d-none instructionTxt"
                  }`}
                >
                  {validation?.educationValidation}
                </p> */}
              </div>
              {/* <div className="mb-3">
                <label htmlFor="EducationLevelInput">Education Level</label>
                <div
                  className={`input-group ${
                    validation.educationValidation && !educationLevel
                      ? "make-red-border"
                      : "border"
                  }`}
                >
                  <select
                    id="EducationLevelInput"
                    name="EducationLevelInput"
                    className="form-select scrollable-select"
                    value={educationLevel}
                    onChange={(e) => setEducationLevel(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Education Level
                    </option>
                    <option value="Bachelor of Engineering (B.E.)">
                      Bachelor of Engineering (B.E.)
                    </option>
                    <option value="Bachelor of Technology(B.Tech.)">
                      Bachelor of Technology(B.Tech.)
                    </option>
                    <option value="Master of Engineering (M.E)">
                      Master of Engineering (M.E)
                    </option>
                    <option value="Master of Technology(M.Tech)">
                      Master of Technology(M.Tech)
                    </option>
                    <option value="Diploma">Diploma</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Post Graduate Diploma in Management (PGDM)">
                      Post Graduate Diploma in Management (PGDM)
                    </option>
                    <option value="⁠Chartered Accountancy (CA)">
                      ⁠Chartered Accountancy (CA)
                    </option>
                    <option value="⁠Company Secretary (CS)">
                      ⁠Company Secretary (CS)
                    </option>
                    <option value="⁠Integrated Bachelor of Laws (B.A. LLB, B.B.A. LLB, etc.)">
                      ⁠Integrated Bachelor of Laws (B.A. LLB, B.B.A. LLB, etc.)
                    </option>
                   
                  </select>
                </div>
                <p
                  className={`${
                    validation.educationValidation && !educationLevel
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.educationValidation}
                </p>
              </div> */}
              {/* <div className="mb-3">
              <label htmlFor="FieldOfEducationInput">Field of Education</label>
              <div className="input-group">
                <select
                  id="FieldOfEducationInput"
                  name="FieldOfEducationInput"
                  className="form-select"
                  value={spaciallistid}
                  onChange={(e) => setSpaciallistID(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Field of Education
                  </option>
                  {spaciallist.map((item) => (
                  <option  value={item.specializationId } selected>
                     {item.specializationName}
                  </option>
                   
                ))}
                </select>
              </div>
              <p className="instructionTxt">{validation?.fieldValidation}</p>
            </div> */}
              <div className="mb-3 extrafield">
                <label htmlFor="FieldOfEducationInput">
                  Field of Education
                </label>
                <div
                  className={`input-group ${
                    validation.fieldValidation && !spaciallistid
                      ? "make-red-border"
                      : ""
                  }`}
                >
                  <Select
                    // components={{
                    //   Input: (props) => (
                    //     <input {...props} className="customInput" />
                    //   ),
                    // }}
                    placeholder="Select and option"
                    value={spaciallist.filter((i) => i.value == spaciallistid)}
                    className={`form-selection basic-single `}
                    options={spaciallist}
                    styles={customStyles}
                    classNamePrefix="select"
                    onChange={(e) => setSpaciallistID(e.value)}
                    isSearchable={true}
                  />
                </div>
                <p
                  className={`${
                    validation.fieldValidation && !spaciallistid
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  Please Select Field Of Education
                </p>
              </div>
              {/* <div className="mb-3">
              <label htmlFor="ExperienceInput">Experience (in years)</label>
              <div className="input-group">
                <select
                  id="ExperienceInput"
                  name="ExperienceInput"
                  className="form-select"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Experience
                  </option>
                  <option value="1">Experience @1</option>
                  <option value="2">Experience @2</option>
                  <option value="3">Experience @3</option>
                </select>
              </div>
            </div> */}
              <div className="mb-3">
                <label htmlFor="ExperienceInput">Experience (in years)</label>
                <div
                  className={`input-group ${
                    validation.experienceValidation && !experience.length > 0
                      ? "make-red-border"
                      : ""
                  } `}
                >
                  <input
                    type="text"
                    id="ExperienceInput"
                    name="ExperienceInput"
                    className="form-control"
                    value={experience > 40 ? "40+" : experience}
                    // onChange={(e) => setExperience(e.target.value)}
                    onChange={handleExperienceChange}
                    placeholder="Enter your experience"
                    min="0"
                  />
                </div>
                <p
                  className={`${
                    validation.experienceValidation && !experience.length > 0
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.experienceValidation}
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="CurrentJobTitleInput">Current Job Title</label>
                <div
                  className={`input-group ${
                    validation.jobTitleValidation && !currentJobTitle.length > 0
                      ? "make-red-border"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    id="CurrentJobTitleInput"
                    name="CurrentJobTitleInput"
                    placeholder="Current Job Title"
                    value={currentJobTitle}
                    onChange={(e) => setCurrentJobTitle(e.target.value)}
                  />
                </div>
                <p
                  className={`${
                    validation.jobTitleValidation && !currentJobTitle.length > 0
                      ? "instructionTxtError"
                      : "instructionTxt"
                  }`}
                >
                  {validation?.jobTitleValidation}
                </p>
              </div>
              {/* <div className="mb-3">
              <label htmlFor="CurrentCompanyInput">Current Company</label>
              <div className="input-group">
                <input
                  type="text"
                  id="CurrentCompanyInput"
                  name="CurrentCompanyInput"
                  placeholder="Current Company"
                  value={currentCompany}
                  onChange={(e)=>setCurrentCompany(e.target.value)}
                />
              </div>
            </div> */}
              {/* <div className="mb-3">
              <label htmlFor="CurrentIndustryInput">Current Industry</label>
              <div className="input-group">
                <select
                  id="CurrentIndustryInput"
                  name="CurrentIndustryInput"
                  className="form-select"
                  value={currentIndustry}
                  onChange={(e)=>setCurrentIndustry(e.target.value)}
                >
                  <option value="" disabled selected>
                    Current Industry
                  </option>
                  <option value="industry @1">Industry @1</option>
                  <option value="industry @2">Industry @2</option>
                  <option value="industry @3">Industry @3</option>
                </select>
              </div>
            </div> */}
              {/* <div className="mb-3">
              <label htmlFor="AvailableFromInput">Available From</label>
              <div className="input-group">
                <input
                  type="date"
                  id="AvailableFromInput"
                  name="AvailableFromInput"
                  placeholder="Available From"
                  value={availableFrom}
                  onChange={(e)=>setAvailableFrom(e.target.value)}
                />
               
              </div>
            </div> */}
              {/* <div className="mb-3">
              <label htmlFor="YesOrNoInput">
                Notice Period Buy Out Option Available?
              </label>
              <div className="input-group mt-2">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="buyOutOptionYes"
                    name="buyOutOption"
                    className="form-check-input"
                    value={true}
                    checked={buyOutOption === true}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="buyOutOptionYes" className="form-check-label">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="buyOutOptionNo"
                    name="buyOutOption"
                    className="form-check-input"
                    value={buyOutOption}
                    checked={buyOutOption === "no"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="buyOutOptionNo" className="form-check-label">
                    No
                  </label>
                </div>
              </div>
            </div> */}
              {/* <div className="mb-3">
              <label htmlFor="ExpectedSalaryInput">Expected Salary</label>
              <div className="input-group">
                <select
                  id="ExpectedSalaryInput"
                  name="ExpectedSalaryInput"
                  className="form-select"
                  value={expectedSalary}
                  onChange={(e)=>setExpectedSalary(e.target.value)}
                >
                  <option value="" disabled selected>
                    Expected Salary
                  </option>
                  <option value="₹100,000 - ₹200,000" selected>
                    ₹100,000 - ₹200,000
                  </option>
                  <option value="₹200,000 - ₹300,000">
                    ₹200,000 - ₹300,000
                  </option>
                  <option value="₹300,000 - ₹400,000">
                    ₹300,000 - ₹400,000
                  </option>
                </select>
              </div>
            </div> */}
              {/* <div className="mb-3">
              <label htmlFor="UploadResumeInput">Upload Resume</label>
              <div className="input-group">
                <input
                  readOnly
                  type="text"
                  id="UploadResumeInput"
                  name="UploadResumeInput"
                  placeholder="Upload Resume"
                  style={{ cursor: "pointer" }}
                  value={uploadResume}
                  onChange={(e)=>setUploadResume(e.target.value)}
                  onClick={() =>
                    document.getElementById("hiddenFileInput").click()
                  }
                />
                <img
                  src={uploadIcon}
                  className="uploadIconStyl"
                  alt="Upload Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    document.getElementById("hiddenFileInput").click()
                  }
                />
                <input
                  type="file"
                  id="hiddenFileInput"
                  style={{ display: "none" }}
                  accept=".pdf, .doc, .docx"
                  onChange={handleFileChange}
                />
              </div>
              <p className="instructionTxt">
                Upload your resume as a PDF or Word file, no larger than 25MBs.
              </p>
            </div> */}
            </div>
            <div className="btnArea">
              <button
                className="btnVldt"
                // onClick={() => navigate("/CompanyName")}
                onClick={() => submitAddtionalInfor()}
              >
                <p>Proceed to Test</p>
                <img
                  src={arrowRight}
                  className="arrowIconStyl"
                  alt="ArrowRight-Icon"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalInformation;
