import React, { useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./assets/style/main.css";
import "./assets/style/header.css";
import "./assets/style/company.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import SplashScreen from "./pages/SplashScreen";
import CodeLogin from "./pages/CodeLogin";
import Header from "./components/Header";
import CompanyName from "./pages/CompanyName";
import Thankyou from "./pages/Thank-you";
import Break from "./pages/Break";
import BreakGoing from "./pages/BreakGoing";
import BreakDone from "./pages/BreakDone";
import ThankHeader from "./components/ThankHeader";
import AdditionalInformation from "./pages/AdditionalInformation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { AppContext } from "./context/AppContext";
import { useEffect } from "react";
import AppState from "./contaxt/AppState";
import { AppContext } from "./contaxt/AppContext";
import {
  simpleGetCall,
  simplePostCall,
  simplePostTokenCall,
} from "./api/ApiServices";
import ApiConfig from "./api/ApiConfig";

function App() {
  // const {

  // } = useContext(AppContext);

  const { settokendatacontaxt, codequerylgin, setcodequerylgin } =
    useContext(AppContext);

  const { customerData } = useContext(AppContext);

  const navigate = useNavigate();

  const pathName = useLocation();

  useEffect(() => {
    continueTestWithQuery();
    codequeryLogin();
  }, []);
  const [code, setCode] = useState("");
  // const [codequerylgin, setcodequerylgin] = useState(false);
  useEffect(() => {
    if (codequerylgin) navigate("/LoginWithCode", { state: { code } }); 
    localStorage.clear();
  }, [codequerylgin]);

  const codequeryLogin = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code) {
      setCode(code);
      setcodequerylgin(true);
    }
  };

  const [queryLogin, setQuueryLogin] = useState(false);

  useEffect(() => {
    if (queryLogin) navigate("/CompanyName");
  }, [queryLogin]);

  const continueTestWithQuery = async () => {
    const searchParams = new URLSearchParams(pathName.search);

    const code = searchParams.get("code");
    const email = searchParams.get("email");

    if (code && email) {
      let validCode = await simplePostCall(
        ApiConfig.LOGIN_WITH_UNIQUE_CODE,
        JSON.stringify({ jobUniqueModel: code })
      );

      console.log(validCode);

      if (validCode?.succeded) {
        localStorage.setItem("addInfoOne", validCode.response[0].addInfoOne);
        localStorage.setItem("addInfoTwo", validCode.response[0].addInfoTwo);
        localStorage.setItem("Uniquecode", code);

        let emailValid = await simplePostCall(
          ApiConfig.REGISTRATION_FORM,
          JSON.stringify({
            email: email,
            name: "Query Login",
            mobileNumber: "Query Login",
            UniqueCode: code,
          })
        );

        if (emailValid?.succeded) {
          localStorage.setItem("customer_id", emailValid?.response[0]?.userId);

          let userData = {
            userId: emailValid?.response[0]?.userId,
            companyId: 0,
            roleId: 3,
            otp: emailValid?.response[0]?.otp,
          };

          const urlEncodedData = new URLSearchParams(userData).toString();

          simplePostTokenCall(
            ApiConfig.TOKEN_ACCESS,
            urlEncodedData,
            JSON.stringify(userData)
          ).then((res) => {
            console.log(res?.token);

            if (res?.token) {
              settokendatacontaxt(res?.token);

              localStorage.setItem("authToken", res?.token);

              setQuueryLogin(true);
            }
          });
        }
      }
    }
  };

  return (
    <div>
      <ToastContainer autoClose={2000} />
      <Routes>
        <Route exact path="/" element={<SplashScreen />} />
        <Route exact path="/LoginWithCode" element={<CodeLogin />} /> 
        
        <Route
          exact
          path="/AdditionalInformation"
          element={<AdditionalInformation />}
        />
        <Route exact path="/CompanyName" element={<CompanyName />} />
        <Route exact path="/Thankyou" element={<Thankyou />} />
        <Route exact path="/Break" element={<Break />} />
        <Route exact path="/BreakGoing" element={<BreakGoing />} />
        <Route exact path="/BreakDone" element={<BreakDone />} />
        <Route exact path="/ThankHeader" element={<ThankHeader />} />
        <Route exact path="/acc" element={<div>Hello</div>} />
      </Routes>

      {/* <ToastContainer/> */}
    </div>
  );
}

export default App;
