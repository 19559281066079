import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import vectorcircle from "../assets/images/vector-circle.svg";
import Enthusiastic from "../assets/images/Enthusiastic.svg";
import satisfied from "../assets/images/satisfied.svg";
import diplomatic from "../assets/images/diplomatic.svg";
import Cautious from "../assets/images/Cautious.svg";
import thumbsdown from "../assets/images/thumbs-down.svg";
import thumpsdownwhite from "../assets/images/thumbsdownWhitediff.svg";
import thumbsup from "../assets/images/thumbs-up.svg";
import activeThumsUp from "../assets/images/activeThumsUp.svg";
import arrowleft from "../assets/images/arrow-left.svg";
import arrowright from "../assets/images/arrow-right.svg";
import Tasty_Cookies from "../assets/images/Tasty_Cookies.svg";
import drag from "../assets/images/drag.svg";
import { json, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { simpleGetCall, simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../contaxt/AppContext";
import BreakGoing from "./BreakGoing";
import BreakDone from "./BreakDone";
import Spinner from "react-bootstrap/Spinner";
import GreatStart from "./greatStart";
import { useStopwatch } from "react-timer-hook";
import { useTranslation, withTranslation } from "react-i18next";
import { t } from "i18next";
import down from "../assets/images/down-svgrepo-com.svg";
import up from "../assets/images/up-svgrepo-com.svg";
import nextbtn from "../assets/images/next (1).svg";
import prevbtn from "../assets/images/previous.svg";
import editprof from "../assets/images/user.svg";
import saveprog from "../assets/images/archive.svg";
import dragup from "../assets/images/Group 7.svg";
import submitbtn from "../assets/images/submit.svg";

const SwipeableOptionsCard = ({
  question,
  option,
  handleLike,
  handleUnlike,
}) => {
  const [displayunlike, setDisplayunlike] = useState(null);
  const [displaylike, setDisplaylike] = useState(null);
  const [animationlike, setAnimationlike] = useState(false);
  const [animationunlike, setAnimationunlike] = useState(false);

  const [isHoveredlike, setIsHoveredlike] = useState(false);
  const [isHoveredunlike, setIsHoveredunlike] = useState(false);

  const handleButtonUnLike = () => {
    handleUnlike(question.questionId, option);
    setDisplayunlike(true);
    setTimeout(() => {
      setDisplayunlike(false);
    }, 600);
    setDisplaylike(false);

    setAnimationunlike(true);
    setTimeout(() => {
      setAnimationunlike(false);
    }, 600);

    setIsHoveredunlike(true);
    setTimeout(() => {
      setIsHoveredunlike(false);
    }, 600);
  };
  const handleButtonLike = () => {
    handleLike(question.questionId, option);
    setDisplaylike(true);
    setTimeout(() => {
      setDisplaylike(false);
    }, 600);
    setDisplayunlike(false);
    setAnimationlike(true);

    setAnimationlike(true);
    setTimeout(() => {
      setAnimationlike(false);
    }, 600);

    setIsHoveredlike(true);
    setTimeout(() => {
      setIsHoveredlike(false);
    }, 600);
  };

  const handlers = useSwipeable({
    // blue like
    onSwipedLeft: () => {
      handleLike(question.questionId, option);
      setDisplaylike(true);
      setTimeout(() => {
        setDisplaylike(false);
      }, 600);
      setDisplayunlike(false);
      setAnimationlike(true);

      setAnimationlike(true);
      setTimeout(() => {
        setAnimationlike(false);
      }, 600);
      setIsHoveredlike(true);
      setTimeout(() => {
        setIsHoveredlike(false);
      }, 600);
    },
    // orange unlike
    onSwipedRight: () => {
      handleUnlike(question.questionId, option);
      setDisplayunlike(true);
      setTimeout(() => {
        setDisplayunlike(false);
      }, 600);
      setDisplaylike(false);

      setAnimationunlike(true);
      setTimeout(() => {
        setAnimationunlike(false);
      }, 600);
      setIsHoveredunlike(true);
      setTimeout(() => {
        setIsHoveredunlike(false);
      }, 600);

      // setImgani(true);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <div
      {...handlers}
      className={`options-card row d-flex justify-content-around company-box my-2 position-relative ${
        question.selectedMostId === option.optionId ? "border-blue" : ""
      } ${question.selectedLeastId === option.optionId ? "border-red" : ""}`}
    >
      {displayunlike && (
        <div
          className={`leftunlike hide position-absolute top-0 start-0 ${
            animationunlike ? "unlike-animation" : "d-none"
          }
          }`}
        >
          {question?.selectedMostId == option.optionId ? null : (
            <img src={thumpsdownwhite} className="img-color" />
          )}
        </div>
      )}

      <div className="col-5">
        <img
          src={`https://api-beta.lywo.in/api/Assessment/FindSvgFile/find?fileName=${encodeURIComponent(
            option.optionValue.trim()
          )}.svg`}
          alt={option.optionValue}
          className={`company-img ${animationunlike ? "move-right" : ""}`}
        />
      </div>
      <div
        className={`col-6 d-flex flex-column justify-content-between align-items-end padding-small-screen ${
          animationlike ? "move-left" : ""
        }`}
      >
        <h2 className="option-name text-nowrap">{t(option.optionValue)}</h2>
        <div className="pb-md-4 d-flex gapp">
          <div
            className={`thumbsdown-comp-link ${
              question.selectedLeastId == option.optionId
                ? "DislikeSelected"
                : ""
            } ${isHoveredunlike ? "hover-unlike" : ""}`}
            // onClick={() => handleUnlike(question.questionId, option)}
            onClick={handleButtonUnLike}
          >
            <span
              className={`hover-text ${
                question.selectedLeastId == option.optionId
                  ? "selectedColor"
                  : ""
              }`}
            >
              Unlike Me
            </span>
            <img
              src={
                question.selectedLeastId == option.optionId
                  ? thumpsdownwhite
                  : thumbsdown
              }
              className="thumbsdown-comp"
              alt="Thumbs Down"
            />
          </div>
          <div
            className={`thumbsup-comp-link ${
              question.selectedMostId == option.optionId ? "likeSelected" : ""
            }  ${isHoveredlike ? "hover-effect" : ""}`}
            // onClick={() => handleLike(question.questionId, option)}
            onClick={handleButtonLike}
          >
            {/* {console.log(question.selectedMostId + " " + option.optionId)} */}
            <span
              className={`hover-text ${
                question.selectedMostId == option.optionId
                  ? "selectedColor"
                  : ""
              }`}
            >
              Like Me
            </span>
            <img
              src={
                question.selectedMostId == option.optionId
                  ? activeThumsUp
                  : thumbsup
              }
              className="thumbsup-comp"
              alt="Thumbs Up"
            />
          </div>
        </div>
      </div>
      {displaylike && (
        <div
          className={`rightlike hide position-absolute top-0 end-0 ${
            animationlike ? "like-animation" : "d-none"
          }
          }`}
        >
          {question?.selectedMostId == option.optionId ? (
            <img src={activeThumsUp} className="img-color" />
          ) : (
            <img src={activeThumsUp} className="img-color" />
          )}
        </div>
      )}
    </div>
  );
};

const CompanyName = () => {
  const [showToast, setShowToast] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataAssessment, setdataAssessment] = useState([]);
  const [picData, setpicData] = useState([]);
  const [data, setdata] = useState({});
  console.log(dataAssessment, "");
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [totalAttempted, setTotalAttempted] = useState(0);
  const [selectedQestionID, setselectedQestionID] = useState(1);
  const [answersGiven, setAnswersGiven] = useState(false);
  const [assessmentMin, setAssessmentMin] = useState(10); // Example value
  const [assessmentSec, setAssessmentSec] = useState(10); // Example value
  const [getLocalStorageData, setLocalStorageData] = useState([] || {});
  const [flag, setFlag] = useState("");
  const [mostLike, setMostLike] = useState({});
  const [leastLike, setLeastLike] = useState({});
  const [showlike, setshowlike] = useState("");
  const [showdislike, setshowdislike] = useState("");
  const navigate = useNavigate();
  const [attemptedQuestions, setAttemptedQuestions] = useState([]);
  const [likedOptions, setLikedOptions] = useState([]);
  const { dynamicdata, setdynamicdata } = useState({});
  const [repeatToAnAnswer, setRepeateUnAnswer] = useState(false);
  const [toggle, settoggle] = useState(false);
  const { percentage, setPercentage, tokendatacontaxt } =
    useContext(AppContext);
  //const [percentage, setPercentage] = useState(0);
  const [seletcetedAnser, setSelectedAnser] = useState({
    mostLike: "",
    leastLike: "",
  });

  const [firstRepeatQues, setFirstRepeatQues] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [showGreatGoing, setShowGreatGoing] = useState(false);

  const [showBreakDone, setShowBreakdone] = useState(false);

  const [showGreatStart, setShowGreatStart] = useState(false);

  const { seconds, minutes, hours, pause, start, resume } = useStopwatch();
  const newquestionscreen = useRef(null);

  const [unAnswerList, setUnAnswerList] = useState([]);

  // const { id } = useParams();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleShowToast = () => {
    saveProgressData();
  };
  const handleShowToastf = () => {
    setShowToast(false);
  };
  const [isActive, setIsActive] = useState(false);

  const toggleBottomSheet = () => {
    // let ele = document.querySelector(".bottomSheet");
    // if (isActive) {
    //   ele.classList.remove("active");
    //   setIsActive(false);
    // } else {
    //   ele.classList.remove("bottomSheet");
    //   setIsActive(true);
    // }
    setIsActive(!isActive);
  };

  const [companies, setCompanies] = useState([
    {
      id: 1,
      name: "Enthusiastic",
      thumbsdown: thumbsdown,
      thumbsup: thumbsup,
      compImg: Enthusiastic,
      expanded: false,
    },
    {
      id: 2,
      name: "Satisfied",
      thumbsdown: thumbsdown,
      thumbsup: thumbsup,
      compImg: satisfied,
      expanded: false,
    },
    {
      id: 3,
      name: "Diplomatic",
      thumbsdown: thumbsdown,
      thumbsup: thumbsup,
      compImg: diplomatic,
      expanded: false,
    },
    {
      id: 4,
      name: "Cautious",
      thumbsdown: thumbsdown,
      thumbsup: thumbsup,
      compImg: Cautious,
      expanded: false,
    },
  ]);

  const [selectedQues, setSelectedQues] = useState({});

  const calculatePercentage = (attempted, total) => {
    let attemptd = 0;

    dataAssessment?.forEach((i) => {
      if (i?.selectedMostId && i?.selectedLeastId) {
        attemptd++;
      }
    });

    setTotalAttempted(attemptd);

    return total === 0 ? 0 : Math.round((attemptd / total) * 100);
  };

  // const [hidebtn,sethidebtn]= useState(true);

  // const addinfor=localStorage.getItem('addInfoOne')
  // const addinfotwo=localStorage.getItem('addInfoTwo');

  // if(addinfor=='yes' || addinfotwo=='yes') {
  // sethidebtn(false);
  // console.log('haa')
  // }
  // else
  // {
  //    sethidebtn(true);
  // }

  const [hidebtn, setHideBtn] = useState(true);
  useEffect(() => {
    const addinfor = localStorage.getItem("addInfoOne");
    const addinfotwo = localStorage.getItem("addInfoTwo");

    if (addinfor === "yes" || addinfotwo === "yes") {
      setHideBtn(false);
      console.log("haa");
    } else {
      setHideBtn(true);
    }

    if (window.innerWidth < 768) {
      window.scrollTo(0, 50);
    } else return;
  }, []);

  // let globalpercentage=""
  useEffect(() => {
    if (dataAssessment.length === 0) return;

    setPercentage(
      calculatePercentage(attemptedQuestions.length, dataAssessment.length)
    );
  }, [selectedQuestions, attemptedQuestions, dataAssessment]);
  const scrll = document.querySelector(".comp-mainHead-txt");
  useEffect(() => {
    if (window.innerWidth < 768) {
      window.scrollTo(0, 50);
    } else {
      window.scrollTo(0, 0);
    }
  }, [selectedNumber]);
  const handleClick = (index, direction) => {
    setCompanies((prevCompanies) => {
      return prevCompanies.map((company, i) => {
        if (i === index) {
          return {
            ...company,
            expanded: !company.expanded, // Toggle expanded state
            direction: company.expanded ? "" : direction, // Set direction only if expanding
          };
        } else {
          return { ...company, expanded: false };
        }
      });
    });

    setTimeout(() => {
      setCompanies((prevCompanies) => {
        return prevCompanies.map((company, i) => {
          if (i === index) {
            return { ...company, expanded: false };
          }
          return company;
        });
      });
    }, 3000); // Close after 3 seconds
  };

  useEffect(() => {
    const progressBar = document.getElementById("progressBar");
    if (progressBar) {
      progressBar.style.width = `${percentage}%`;
    }
  }, []);

  const handleNumberClick = (questionId) => {
    console.log("under if");
    setSelectedNumber(questionId);

    localStorage.setItem("questionId", questionId);

    handleLinkDislike(questionId);

    console.log("under fuction");

    dataAssessment.map((item) => {
      if (item.questionId == questionId) {
        item.options.map((option) => {
          getAssessmentListPic(option.optionValue);
        });
      }
    });

    if (repeatToAnAnswer) {
      let unAnswers = [];

      dataAssessment.forEach((i) => {
        if (i.questionId > questionId) {
          if (!i?.selectedLeastId || !i?.selectedMostId) {
            unAnswers.push(i?.questionId);
          }
        }
      });

      setUnAnswerList(unAnswers);
    }

    handleSelectedAnswer(questionId);
  };

  const handleLinkDislike = (questionId = 1) => {
    console.log(questionId);
    // console.log(data);
    const previewsData =
      JSON.parse(localStorage.getItem("questionsData")) || [];
    console.log(previewsData);
    // Filter entries by the given questionId
    console.log(questionId);
    const filteredData = previewsData?.filter(
      (item) => item?.questionId === questionId
    );
    console.log(filteredData);
    if (filteredData.length > 0) {
      const sortedData = filteredData.sort((a, b) => a.optionId - b.optionId);

      const latestTwoEntries = sortedData.slice(-20);

      let reverData = [...latestTwoEntries].reverse();

      let leastLike = reverData.find((item) => item.data?.type === "least");
      console.log(leastLike);
      let mostLike = reverData?.find((item) => item?.data.type === "most");
      console.log(mostLike);
      console.log(
        mostLike?.data?.optionId == leastLike?.data?.optionId &&
          mostLike?.data?.optionValue == leastLike?.data?.optionValue
      );
      if (
        mostLike?.data?.optionId == leastLike?.data?.optionId &&
        mostLike?.data?.optionValue == leastLike?.data?.optionValue &&
        mostLike?.data?.type == "most"
      ) {
        setLeastLike(leastLike);
        setMostLike({});
      } else if (
        mostLike?.data?.optionId == leastLike?.data?.optionId &&
        mostLike?.data?.optionValue == leastLike?.data?.optionValue &&
        leastLike?.data?.type == "least"
      ) {
        console.log("kkkkk");
        setMostLike(mostLike);
        setLeastLike({});
      } else {
        setLeastLike(leastLike);
        setMostLike(mostLike);
      }
      console.log(mostLike);
      console.log(leastLike);
    } else {
      setMostLike(null);
      setLeastLike(null);
    }
  };
  useEffect(() => {
    console.log("log");
    handleLinkDislike();
  }, []);

  useEffect(() => {
    handleSelectedAnswer(selectedNumber);
  }, [dataAssessment]);

  const contentRef = useRef(null);
  // const handlers = useSwipeable({
  //   onSwiped: (eventData) => handleNext(),
  // });

  const editAddInfo = () => {
    saveProgressOnAddtional();
    navigate("/AdditionalInformation");
  };

  useEffect(() => {
    getAssessmentList();
  }, [tokendatacontaxt]);
  useEffect(() => {
    getAssessmentListPic();
  }, []);

  // const [applied, setApplied] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setApplied(true);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, []);
  // const [applied, setApplied] = useState(false);
  // const handletimer=()=>{
  //   setTimeout(()=>{
  //     setApplied(true)
  //   },3000)
  // }

  const getAssessmentList = () => {
    const currentUserId = localStorage.getItem("customer_id");
    const uniquecode = localStorage.getItem("Uniquecode");
    if (currentUserId) {
      simpleGetCall(
        `${ApiConfig.ASSESMENT_TEST}?currentUserId=${currentUserId}&code=${uniquecode}`
      )
        .then((res) => {
          if (res.response) {
            console.log(res.response, "Data Assess");
            setdataAssessment(() => res.response);
            handleSelectedAnswer(
              res.response[0]?.currentQuestionId
                ? res.response[0]?.currentQuestionId
                : 1
            );
            setSelectedNumber(
              res.response[0]?.currentQuestionId
                ? res.response[0]?.currentQuestionId
                : 1
            );
            setIsLoading(false);
            start();
          } else {
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        })
        .finally(() => {});
    } else {
      console.error("Error: No currentUserId found in localStorage");
    }
  };

  const handleSelect = (questionId, optionId, type, optionValue) => {
    setdataAssessment(
      dataAssessment.map((question) => {
        if (question.questionId === questionId) {
          if (type === "most") {
            question.selectedMostId = optionId;
          } else {
            question.selectedLeastId = optionId;
          }
        }

        return question;
      })
    );

    saveDataToLocalStorage("questionsData", {
      questionId: questionId,
      data: { questionId, type, optionValue, optionId },
    });
  };

  const handlePrevious = () => {
    if (selectedNumber > 1) {
      handleSelectedAnswer(selectedNumber - 1);
      setSelectedNumber((prevNumber) => prevNumber - 1);
    }
  };

  useEffect(() => {
    if (percentage <= 11 && percentage != 0 && percentage >= 10) {
      setShowGreatStart(true);
      pause();
    } else if (percentage <= 36 && percentage != 0 && percentage >= 35) {
      setShowGreatGoing(true);
      pause();
    } else if (percentage <= 71 && percentage != 0 && percentage >= 70) {
      pause();
      setShowBreakdone(true);
    }
  }, [percentage]);

  const repeatQues = () => {};

  const handleNext = () => {
    const maxQuestionNumber = dataAssessment.length;
    console.log(selectedNumber, "percentagesssss");

    // if (
    //   selectedNumber + 1 === 4 &&
    //   percentage <= 11 &&
    //   percentage != 0 &&
    //   percentage >= 10
    // ) {
    //   setShowGreatStart(true);
    //   pause();
    // }

    // if (
    //   selectedNumber + 1 == 11 &&
    //   percentage <= 36 &&
    //   percentage != 0 &&
    //   percentage >= 35
    // ) {
    //   setShowGreatGoing(true);
    //   pause();
    // }

    // if (
    //   selectedNumber + 1 == 21 &&
    //   percentage <= 71 &&
    //   percentage != 0 &&
    //   percentage >= 70
    // ) {
    //   pause();
    //   setShowBreakdone(true);
    // }

    if (selectedNumber === 28 || repeatToAnAnswer) {
      if (selectedNumber == 28 || unAnswerList.length == 0) {
        console.log(dataAssessment);

        let unAnswers = [];

        let updateUnAns = false;

        dataAssessment.forEach((i) => {
          if (!i?.selectedLeastId || !i?.selectedMostId) {
            unAnswers.push(i?.questionId);
          }
        });

        if (unAnswers.length > 0) {
          setSelectedNumber(unAnswers[0]);

          setFirstRepeatQues(unAnswers[0]);

          unAnswers.shift();

          setUnAnswerList(unAnswers);

          setRepeateUnAnswer(true);
        }
      } else {
        if (unAnswerList.length > 0) {
          setSelectedNumber(unAnswerList[0]);

          unAnswerList.shift();

          setUnAnswerList(unAnswerList);
        }
      }
    } else {
      if (selectedNumber < maxQuestionNumber) {
        handleSelectedAnswer(selectedNumber + 1);
        setSelectedNumber((prevNumber) => prevNumber + 1);
      }
    }
  };

  const handleNavigate = () => {
    // Check if dataAssessment is not empty and if answers are given for all questions
    const allQuestionsAnswered = dataAssessment.every(
      (question) =>
        question.selectedMostId !== null && question.selectedLeastId !== null
    );
    if (dataAssessment.length > 0 && allQuestionsAnswered) {
      navigate("/Thankyou");
    } else {
      // Handle condition where data is empty or some questions are unanswered
      toast.error("Please answer all questions before proceeding.");
    }
    saveSubmitData();
  };

  const preValues = [];
  const getAssessmentListPic = (optionvalue) => {
    const png = `${optionvalue}.png`; // Replace with your actual PNG image name or path

    const url = `${ApiConfig.ASSESSMENT_PICTURE}${png}`;

    simpleGetCall(url)
      .then((res) => {
        console.log(res, " ccccc");
        preValues.push(res);

        console.log(res.response, "```````");
        setpicData((pre) => [...pre, res]);

        // setLoading(false);
        // Extract the array of items from the response
        // Set the array to the state

        // notifyError(res.message)
        // Handle the case when the result is false
        // notifyError(res.message);
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  console.log("dataassessment", dataAssessment);
  // const selectedItem = picData.find(item => item === option.optionValue + ".png");
  const saveProgressData = () => {
    const currentUserId = localStorage.getItem("customer_id");
    let requestData;
    const uniqucode = localStorage.getItem("Uniquecode");
    let CurrentQuestion = localStorage.getItem("questionId");

    requestData = {
      currentUserId: currentUserId,
      questionSelected: dataAssessment
        .filter(
          (question) =>
            question.selectedMostId != null && question.selectedLeastId != null
        )
        .map((question) => ({
          questionId: question.questionId,
          answerForMost: question.selectedMostId,
          answerForLeast: question.selectedLeastId,
        })),
      assessmentmin: assessmentMin,
      assessmentsec: assessmentSec,
      isProgress: percentage == "100" ? false : true,
      uniqueCode: uniqucode,
      currentQuestion: CurrentQuestion ? CurrentQuestion : selectedNumber,
      timeOfCompletion: minutes,
      percentage: percentage,
    };
    console.log(percentage);
    simplePostCall(ApiConfig.ADD_ASSESSMENT_RESULT, JSON.stringify(requestData))
      .then((res) => {
        if (res.response) {
          if (percentage == "100") {
            navigate("/ThankYou");
          } else {
            toast.success(
              "Please use Same Code and Email to Retrieve your Form."
            );
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const saveProgressOnAddtional = () => {
    const currentUserId = localStorage.getItem("customer_id");
    let requestData;
    const uniqucode = localStorage.getItem("Uniquecode");
    let CurrentQuestion = localStorage.getItem("questionId");

    requestData = {
      currentUserId: currentUserId,
      questionSelected: dataAssessment
        .filter(
          (question) =>
            question.selectedMostId != null && question.selectedLeastId != null
        )
        .map((question) => ({
          questionId: question.questionId,
          answerForMost: question.selectedMostId,
          answerForLeast: question.selectedLeastId,
        })),
      assessmentmin: assessmentMin,
      assessmentsec: assessmentSec,
      isProgress: percentage == "100" ? false : true,
      uniqueCode: uniqucode,
      currentQuestion: CurrentQuestion,
      timeOfCompletion: minutes,
      percentage: percentage,
    };
    console.log(percentage);
    simplePostCall(ApiConfig.ADD_ASSESSMENT_RESULT, JSON.stringify(requestData))
      .then((res) => {
        if (res.response) {
          if (percentage == "100") {
            navigate("/ThankYou");
          } else {
            // toast.success("Please use the same Code & Email when you Return");
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const saveSubmitData = () => {
    const currentUserId = localStorage.getItem("customer_id");
    const uniqucode = localStorage.getItem("Uniquecode");
    let requestData;

    requestData = {
      currentUserId: currentUserId,
      questionSelected: dataAssessment
        .filter(
          (question) =>
            question.selectedMostId != null && question.selectedLeastId != null
        )
        .map((question) => ({
          questionId: question.questionId,
          answerForMost: question.selectedMostId,
          answerForLeast: question.selectedLeastId,
        })),
      assessmentmin: assessmentMin,
      assessmentsec: assessmentSec,
      isProgress: false,
      uniqueCode: uniqucode,
      timeOfCompletion: minutes,
    };

    simplePostCall(ApiConfig.ADD_ASSESSMENT_RESULT, JSON.stringify(requestData))
      .then((res) => {
        console.log(res, "ggggggggggggggggg");
        if (res.response) {
          console.log(res.response);
        } else {
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  // Function to save data to localStorage
  function saveDataToLocalStorage(key, data) {
    const previewsData = JSON.parse(localStorage.getItem(key)) || [];

    // If previewsData exists and is an array, merge new data into it
    if (previewsData) {
      const previewsDataWithCurrent = [...previewsData, data];
      localStorage.setItem(key, JSON.stringify(previewsDataWithCurrent));
    } else {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  const [likeAmmt, setLikeAnimate] = useState(null);

  const handleLikeClick = (questionId, option) => {
    setSelectedAnser((prev) => ({
      ...prev,
      mostLike: option?.optionValue,
    }));

    setLikeAnimate(true);

    setdataAssessment((prevData) =>
      prevData.map((question) =>
        question.questionId === questionId
          ? {
              ...question,
              selectedMostId:
                question.selectedMostId != option.optionId
                  ? option.optionId
                  : null,
              selectedLeastId:
                question.selectedLeastId == option.optionId
                  ? null
                  : question.selectedLeastId,
            }
          : question
      )
    );

    console.log(dataAssessment, "DataAssessments");

    // setSelectedLikes(prevLikes => {
    //   const updatedLikes = {
    //     ...prevLikes,
    //     [questionId]: prevLikes[questionId] === option.optionId ? null : option.optionId
    //   };

    //   // If the option was already selected as dislike, deselect it
    //   if (selectedDislikes[questionId] === option.optionId) {
    //     setSelectedDislikes(prevDislikes => ({
    //       ...prevDislikes,
    //       [questionId]: null
    //     }));
    //   }
    //   // If the option was already selected as dislike, deselect it
    //   if (selectedDislikes[questionId] === option.optionId) {
    //     setSelectedDislikes(prevDislikes => ({
    //       ...prevDislikes,
    //       [questionId]: null
    //     }));
    //   }

    //   // Update the attempted count based on selections
    //   updateAttemptedQuestions(questionId, updatedLikes[questionId], selectedDislikes[questionId]);

    //   return updatedLikes;
    // });
  };

  const handleDislikeClick = (questionId, option) => {
    setSelectedAnser((prev) => ({
      ...prev,
      leastLike: option?.optionValue,
    }));

    setLikeAnimate(false);

    setdataAssessment((prevData) =>
      prevData.map((question) =>
        question.questionId === questionId
          ? {
              ...question,
              selectedLeastId:
                question.selectedLeastId != option.optionId
                  ? option.optionId
                  : null,
              selectedMostId:
                question.selectedMostId == option.optionId
                  ? null
                  : question.selectedMostId,
            }
          : question
      )
    );

    // setSelectedDislikes(prevDislikes => {
    //   const updatedDislikes = {
    //     ...prevDislikes,
    //     [questionId]: prevDislikes[questionId] === option.optionId ? null : option.optionId
    //   };

    //   // If the option was already selected as like, deselect it
    //   if (selectedLikes[questionId] === option.optionId) {
    //     setSelectedLikes(prevLikes => ({
    //       ...prevLikes,
    //       [questionId]: null
    //     }));
    //   }
    //   // If the option was already selected as like, deselect it
    //   if (selectedLikes[questionId] === option.optionId) {
    //     setSelectedLikes(prevLikes => ({
    //       ...prevLikes,
    //       [questionId]: null
    //     }));
    //   }

    //   // Update the attempted count based on selections
    //   updateAttemptedQuestions(questionId, selectedLikes[questionId], updatedDislikes[questionId]);
    //   // Update the attempted count based on selections
    //   updateAttemptedQuestions(questionId, selectedLikes[questionId], updatedDislikes[questionId]);

    //   return updatedDislikes;
    // });
  };

  const attemptedQuestionss = dataAssessment.filter(
    (question) => question.selectedMostId || question.selectedLeastId
  );

  const percentagee =
    (attemptedQuestionss.length / dataAssessment.length) * 100;

  const updateAttemptedQuestions = (questionId, likeOption, dislikeOption) => {
    if (likeOption && dislikeOption && likeOption !== dislikeOption) {
      // Add the question to attemptedQuestions if both like and dislike are selected
      setAttemptedQuestions((prevAttempted) =>
        prevAttempted.some((question) => question.questionId === questionId)
          ? prevAttempted
          : [...prevAttempted, { questionId }]
      );
    } else if (!likeOption || !dislikeOption || likeOption === dislikeOption) {
      // Remove the question from attemptedQuestions if only one option or both options are deselected
      setAttemptedQuestions((prevAttempted) =>
        prevAttempted.filter((question) => question.questionId !== questionId)
      );
    }

    // Update the percentage based on the attemptedQuestions count
    setPercentage(
      calculatePercentage(attemptedQuestions.length, dataAssessment.length)
    );
  };

  const handleSelectedAnswer = (questionNo) => {
    let index = dataAssessment.findIndex((item) => {
      return item?.questionId === questionNo;
    });

    let mostSelectedAnswerIndex = dataAssessment[index]?.options?.findIndex(
      (item) => {
        return dataAssessment[index]?.selectedMostId == item?.optionId;
      }
    );

    let leastSelectedAnswerIndex = dataAssessment[index]?.options?.findIndex(
      (item) => {
        return dataAssessment[index]?.selectedLeastId == item?.optionId;
      }
    );

    let most = "";

    let least = "";

    if (mostSelectedAnswerIndex != -1) {
      most =
        dataAssessment[index]?.options[mostSelectedAnswerIndex]?.optionValue;
    }

    if (leastSelectedAnswerIndex != -1) {
      least =
        dataAssessment[index]?.options[leastSelectedAnswerIndex]?.optionValue;
    }

    setSelectedAnser({
      mostLike: most,
      leastLike: least,
    });
  };
  // const handleAnimation = (animationClass, option) => {
  //   const element = document.getElementById(`card-${option.optionId} `);
  //   if (element) {
  //     element.classList.add(animationClass);
  //     setTimeout(() => {
  //       element.classList.remove(animationClass);
  //     }, 2000);
  //   }
  // };
  const [ani, setAni] = useState(null);
  const [displayorange, setDisplayorange] = useState(false);
  const [displayBlue, setDisplayblue] = useState(false);

  const handleAnimationleft = (option, animationClass, buttonType) => {
    setDisplayblue(false);
    setDisplayorange(true);

    const imageElement = document.querySelector(
      `#card-${option.optionId} .company-img`
    );
    if (imageElement) {
      imageElement.classList.add(animationClass);

      imageElement.addEventListener(
        "animationend",
        () => {
          imageElement.classList.remove(animationClass);
        },
        { once: true }
      );
    }

    const buttonClass =
      buttonType === "thumbsdown"
        ? ".thumbsdown-comp-link"
        : ".thumbsup-comp-link";
    const buttonElement = document.querySelector(
      `#card-${option.optionId} ${buttonClass}`
    );

    if (buttonElement) {
      setTimeout(() => {
        buttonElement.classList.add(
          buttonType === "thumbsdown"
            ? "changewithDelaydown"
            : "changewithDelayup"
        );
      }, 0);

      buttonElement.addEventListener(
        "animationend",
        () => {
          buttonElement.classList.remove(
            buttonType === "thumbsdown"
              ? "changewithDelaydown"
              : "changewithDelayup"
          );
        },
        { once: true }
      );

      setAni(buttonType === "thumbsdown" ? true : !ani);
    }
  };

  const handleAnimationright = (option, animationClass, buttonType) => {
    setDisplayblue(true);
    setDisplayorange(false);
    const imageElement = document.querySelector(
      `#card-${option.optionId} .padding-small-screen`
    );
    if (imageElement) {
      imageElement.classList.add(animationClass);

      imageElement.addEventListener(
        "animationend",
        () => {
          imageElement.classList.remove(animationClass);
        },
        { once: true }
      );
    }

    // const buttonClass =
    //   buttonType === "thumbsup"
    //     ? ".thumbsup-comp-link"
    //     : ".thumbsdown-comp-link";
    // const buttonElement = document.querySelector(
    //   `#card-${option.optionId} ${buttonClass}`
    // );

    // if (buttonElement) {
    //   setTimeout(() => {
    //     buttonElement.classList.add(
    //       buttonType === "thumbsup"
    //         ? "changewithDelayup"
    //         : "changewithDelaydown"
    //     );
    //   }, 0);

    //   buttonElement.addEventListener(
    //     "animationend",
    //     () => {
    //       buttonElement.classList.remove(
    //         buttonType === "thumbsup"
    //           ? "changewithDelayup"
    //           : "changewithDelaydown"
    //       );
    //     },
    //     { once: true }
    //   );

    //   setAni(buttonType === "thumbsup" ? true : !ani);
    // }
  };

  // const handleAnimation = (option, animationClass, buttonType) => {
  //   const imageElement = document.querySelector(
  //     `#card-${option.optionId} .company-img`
  //   );
  //   if (imageElement) {
  //     imageElement.classList.add(animationClass);
  //     setTimeout(() => {
  //       imageElement.classList.remove(animationClass);
  //     }, 2500);
  //   }
  //   const buttonClass =
  //     buttonType === "thumbsdown"
  //       ? ".thumbsdown-comp-link"
  //       : ".thumbsup-comp-link";
  //   const buttonElement = document.querySelector(
  //     `#card-${option.optionId} ${buttonClass}`
  //   );

  //   if (buttonElement) {
  //     setTimeout(() => {
  //       buttonElement.classList.add(
  //         buttonType === "thumbsdown"
  //           ? "changewithDelaydown"
  //           : "changewithDelayup"
  //       );
  //     }, 0);
  //     setAni(buttonType === "thumbsdown" ? true : !ani);
  //   }

  // const thumbsdown = document.querySelector(
  //   `#card-${option.optionId} .thumbsdown-comp-link`
  // );
  // const thumbsup = document.querySelector(
  //   `#card-${option.optionId} .thumbsup-comp-link`
  // );
  // if (thumbsdown) {
  //   setTimeout(() => {
  //     thumbsdown.classList.add("changewithDelaydown");
  //   }) && setAni(true);
  // } else if (thumbsup) {
  //   setTimeout(() => {
  //     thumbsup.classList.add("changewithDelayup");
  //   }) && setAni(!ani);
  // }
  // };

  // const handleClickbtn = (option, direction) => {
  //   setdataAssessment((prevCompanies) => {
  //     return prevCompanies.map((company, i) => {
  //       if (i === option.optionId) {
  //         return {
  //           ...company,
  //           expanded: !company.expanded, // Toggle expanded state
  //           direction: company.expanded ? "" : direction, // Set direction only if expanding
  //         };
  //       } else {
  //         return { ...company, expanded: false };
  //       }
  //     });
  //   });

  //   setTimeout(() => {
  //     setdataAssessment((prevCompanies) => {
  //       return prevCompanies.map((company, i) => {
  //         if (i === option.optionId) {
  //           return { ...company, expanded: false };
  //         }
  //         return company;
  //       });
  //     });
  //   }, 3000); // Close after 3 seconds
  // };
  const [animate, setAnimate] = useState(false);
  const [showColumn, setShowColumn] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [QuestionData, setQuestionData] = useState({});
  // alert(ptionData);
  // alert(QuestionData);
  console.log(optionData);
  console.log(QuestionData);

  const handleAnimateclick = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 2000);
  };

  const handleStyle = (question, option) => {
    if (question?.selectedMostId === option.optionId && likeAmmt) {
      return {
        backgroundColor: "#444ce7",
        transition: "1s linear",
      };
    } else if (question?.selectedLeastId === option.optionId && !likeAmmt) {
      return {
        backgroundColor: "#f97066",
        transition: "1s linear",
      };
    } else {
      return { display: "none" };
    }
  };

  useEffect(() => {}, [QuestionData, optionData]);
  function handleSwipeStart() {
    // handleDislikeClick();
    // handleNext();
    if (QuestionData !== null || QuestionData !== "") {
      settoggle(true);
      handleAnimateclick();
      // handleAnimationright(optionData, "squish-blue", "thumbsdown");
      if (!toggle) {
        setSelectedQuestions((prevSelectedQuestions) => {
          const questionIndex = prevSelectedQuestions.findIndex(
            (q) => q.questionId === QuestionData.questionId
          );

          if (questionIndex > -1) {
            return prevSelectedQuestions.map((q, index) =>
              index === questionIndex
                ? {
                    ...q,
                    optionData: optionData.optionData,
                  }
                : q
            );
          }
          return [
            ...prevSelectedQuestions,
            {
              questionId: QuestionData.questionId,
              optionData: optionData.optionData,
            },
          ];
        });
      }

      handleDislikeClick(QuestionData.questionId, optionData);

      setAttemptedQuestions((prevState) => {
        // Add questionId only if it's not already included
        if (!prevState.includes(QuestionData.questionId) && showlike) {
          return [...prevState, QuestionData.questionId];
        }
        return prevState;
      });
    }
  }
  function handleSwipeEnd() {
    //  handleLikeClick();
    // handlePrevious();
    if (QuestionData !== null || QuestionData !== "") {
      handleAnimateclick();
      // handleAnimationleft(optionData, "squish-orange", "thumbsdown");
      // handleAnimationright(optionData, "squish-blue", "thumbsup");
      settoggle(true);
      if (!toggle) {
        setSelectedQuestions((prevSelectedQuestions) => {
          // Find if the question already exists in the array
          const questionIndex = prevSelectedQuestions.findIndex(
            (q) => q.questionId === QuestionData.questionId
          );

          // If the question is found, update the option
          if (questionIndex > -1) {
            return prevSelectedQuestions.map((q, index) =>
              index === questionIndex
                ? {
                    ...q,
                    optionData: optionData.optionData,
                  }
                : q
            );
          }

          // If the question is not found, push the new question
          return [
            ...prevSelectedQuestions,
            {
              questionId: QuestionData.questionId,
              optionData: optionData.optionData,
            },
          ];
        });
      }

      handleLikeClick(QuestionData.questionId, optionData);

      // setdata((prev) => ({
      //   ...prev,

      //   option1: option?.optionValue,
      // }));
      // setFlag(option?.optionId);

      // handleSelect(
      //   question.questionId,
      //   option.optionId,
      //   "most",
      //   option?.optionValue
      // );
      //  if(!showdislike){
      //  handleLinkDislike(question.questionId, {
      //    questionId: question?.questionId,
      //    data: {
      //      questionId: question?.questionId,
      //      type: "most",
      //      optionValue: option?.optionValue,
      //      optionId: option.optionId,

      //    },

      //  });
      //  handleLinkDislike(question.questionId, {
      //    questionId: question?.questionId,
      //    data: {
      //      questionId: question?.questionId,
      //      type: "most",
      //      optionValue: option?.optionValue,
      //      optionId: option.optionId,
      //    },
      //  });
      //  }
    }
  }
  // const handleButtonTouchStart = (event) => {
  //   event.stopPropagation();
  // };

  // const [swipeDirection, setSwipeDirection] = useState(null);
  // // alert("swipeDirection", swipeDirection);
  // console.log("swipeDirection", swipeDirection);

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => setSwipeDirection(false),
  //   onSwipedRight: () => setSwipeDirection(true),
  //   preventDefaultTouchmoveEvent: true,
  //   trackMouse: true,
  // });

  const dropdownRefweb = useRef(null);
  const handleClickOutsideWeb = (event) => {
    if (
      dropdownRefweb.current &&
      !dropdownRefweb.current.contains(event.target)
    ) {
      // setShowDropDown(false);
      setIsActive(false);
    }
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowDropDown(false);
  //   }
  // };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideWeb);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideWeb);
    };
  }, []);

  return (
    <>
      <Header globalpercentage={percentage} />
      {showBreakDone && (
        <BreakDone
          contnueBreakDone={() => {
            setShowBreakdone(false);
            start();
            console.log(minutes.toString(), "minutes");
            handleNext();
          }}
        />
      )}
      {showGreatGoing && (
        <BreakGoing
          continueTest={() => {
            setShowGreatGoing(false);
            start();
            handleNext();
          }}
        />
      )}
      {showGreatStart && (
        <GreatStart
          continueTest={() => {
            setShowGreatStart(false);
            start();
            handleNext();
          }}
        />
      )}
      {isLoading ? (
        <div
          className="d-flex justify-content-center "
          style={{ marginTop: "20%" }}
        ></div>
      ) : (
        !showGreatStart &&
        !showGreatGoing &&
        !showBreakDone && (
          <>
            {" "}
            <div
              className="container-fluid CompanyName-maindis"
              id="CompanyName-main"
            >
              <div className="row justify-content-between ">
                <div className="col-lg-1 col-md-2 sideNum-inweb">
                  <div className="col-12 text-center padding-in-web">
                    <p>Completed</p>
                    <h2>
                      {totalAttempted} / {dataAssessment.length}
                    </h2>
                    <div className="row sidnum-pad ms-0 me-0">
                      {dataAssessment?.map((item, index) => (
                        <div
                          key={index}
                          className="col-6 d-flex justify-content-center sidenum-top-padding"
                        >
                          <div
                            className={`d-flex justify-content-center nums  ${
                              selectedNumber === item.questionId &&
                              item?.selectedLeastId &&
                              item?.selectedMostId
                                ? "currentSelected selected"
                                : item?.selectedLeastId && item?.selectedMostId
                                ? "selected"
                                : selectedNumber === item.questionId
                                ? "selected-notanswered"
                                : ""
                            }`}
                            onClick={() => {
                              settoggle(false);

                              console.log(item, "insided divvvvvvvvvvvv");
                              handleNumberClick(item.questionId);
                            }}
                          >
                            <a
                              className={`nums-link text-center ${
                                selectedNumber === item.questionId ||
                                (item?.selectedLeastId && item?.selectedMostId)
                                  ? "nums-link-change"
                                  : ""
                              }`}
                            >
                              {item.questionId}
                            </a>
                          </div>
                        </div>
                      ))}

                      {selectedNumber !== null && (
                        <div className="question-data">
                          {
                            dataAssessment.find(
                              (item) => item.questionId === selectedNumber
                            )?.questionData
                          }
                        </div>
                      )}
                    </div>
                    <br />
                    <button
                      className="btnVldt mx-auto"
                      // style={{marginLeft:"20px"}}
                      hidden={hidebtn}
                      onClick={editAddInfo}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
                <div
                  className="col-lg-8 col-md-6 d-flex "
                  id="company-centerside"
                >
                  <div className="col-12 manage-height">
                    <div>
                      {dataAssessment.map((question) => (
                        <div
                          key={question.questionId}
                          className={`col-12 d-flex justify-content-center align-items-center ${
                            selectedNumber === question.questionId
                              ? ""
                              : "d-none"
                          }`}
                        >
                          <div
                            key={question.questionId}
                            className="col-12 "
                            ref={newquestionscreen}
                          >
                            <div className="comp-mainHead-txt">
                              <h2 className="text-center">
                                {question.question}
                              </h2>
                            </div>
                            <div className="row justify-content-end cards-container ">
                              {question.options.map((option) => (
                                <div
                                  key={option.optionId}
                                  className="col-xl-5 col-lg-5 col-md-12 d-flex justify-content-center align-items-center mx-md-3"
                                >
                                  <SwipeableOptionsCard
                                    question={question}
                                    option={option}
                                    handleLike={handleLikeClick}
                                    handleUnlike={handleDislikeClick}
                                  />
                                  {/* <div
                                    id={`card-${option.optionId}`}
                                    className="option-card row d-flex justify-content-around company-box my-2 position-relative "
                                    style={
                                      question?.selectedMostId ==
                                      option.optionId
                                        ? {
                                            border: "1px solid #444ce7",
                                            transition: "0.4s linear ",
                                          }
                                        : question?.selectedLeastId ==
                                          option?.optionId
                                        ? {
                                            border: "1px solid #f97066",
                                            transition: "0.4s linear ",
                                          }
                                        : null
                                    }
                                  >
                                    {displayorange && (
                                      <div
                                        className={`leftunlike hide col-2 position-absolute top-0 start-0 ${
                                          animate
                                            ? "expand-animation "
                                            : "d-none"
                                        }`}
                                        style={handleStyle(question, option)}
                                      >
                                        {question?.selectedMostId ==
                                        option.optionId ? null : (
                                          <img
                                            src={thumpsdownwhite}
                                            className="img-color"
                                          />
                                        )}
                                      </div>
                                    )}
                                    <div className="col-5">
                                      <img
                                        src={`https://api-beta.lywo.in/api/Assessment/FindSvgFile/find?fileName=${encodeURIComponent(
                                          option.optionValue.trim()
                                        )}.svg`}
                                        alt={option.optionValue}
                                        className="company-img"
                                      />
                                    </div>
                                    <div className="col-6 d-flex flex-column justify-content-between align-items-end padding-small-screen">
                                      <div></div>
                                      <h2 className="option-name text-nowrap">
                                        {option.optionValue}
                                      </h2>
                                      <div className="  pb-md-4 d-flex gapp">
                                        <div
                                          className={`thumbsdown-comp-link
                                             ${
                                               question.selectedLeastId ==
                                               option.optionId
                                                 ? "DislikeSelected"
                                                 : ""
                                             }`}
                                          onClick={() => {
                                            handleAnimateclick();
                                            handleAnimationleft(
                                              option,
                                              "squish-orange",
                                              "thumbsdown"
                                            );
                                            settoggle(true);
                                            if (!toggle) {
                                              setSelectedQuestions(
                                                (prevSelectedQuestions) => {
                                                  const questionIndex =
                                                    prevSelectedQuestions.findIndex(
                                                      (q) =>
                                                        q.questionId ===
                                                        question.questionId
                                                    );

                                                  if (questionIndex > -1) {
                                                    return prevSelectedQuestions.map(
                                                      (q, index) =>
                                                        index === questionIndex
                                                          ? {
                                                              ...q,
                                                              option:
                                                                option.option,
                                                            }
                                                          : q
                                                    );
                                                  }
                                                  return [
                                                    ...prevSelectedQuestions,
                                                    {
                                                      questionId:
                                                        question.questionId,
                                                      option: option.option,
                                                    },
                                                  ];
                                                }
                                              );
                                            }

                                            handleDislikeClick(
                                              question.questionId,
                                              option
                                            );

                                            setAttemptedQuestions(
                                              (prevState) => {
                                                if (
                                                  !prevState.includes(
                                                    question.questionId
                                                  ) &&
                                                  showlike
                                                ) {
                                                  return [
                                                    ...prevState,
                                                    question.questionId,
                                                  ];
                                                }
                                                return prevState;
                                              }
                                            );
                                          }}
                                        >
                                          <span
                                            className={`hover-text ${
                                              question.selectedLeastId ==
                                              option.optionId
                                                ? "selectedColor"
                                                : null
                                            }`}
                                          >
                                            Unlike Me
                                          </span>
                                          {question.selectedLeastId ==
                                          option.optionId ? (
                                            <img
                                              src={thumpsdownwhite}
                                              className="thumbsdown-comp"
                                              alt="Thumbs Down"
                                            />
                                          ) : (
                                            <img
                                              src={thumbsdown}
                                              className="thumbsdown-comp"
                                              alt="Thumbs Down"
                                            />
                                          )}
                                        </div>
                                        <div
                                          // className="thumbsup-comp-link"
                                          className={`thumbsup-comp-link ${
                                            question.selectedMostId ==
                                            option.optionId
                                              ? "likeSelected"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            handleAnimateclick();
                                            handleAnimationright(
                                              option,
                                              "squish-blue",
                                              "thumbsup"
                                            );
                                            settoggle(true);
                                            if (!toggle) {
                                              setSelectedQuestions(
                                                (prevSelectedQuestions) => {
                                                  const questionIndex =
                                                    prevSelectedQuestions.findIndex(
                                                      (q) =>
                                                        q.questionId ===
                                                        question.questionId
                                                    );
                                                  if (questionIndex > -1) {
                                                    return prevSelectedQuestions.map(
                                                      (q, index) =>
                                                        index === questionIndex
                                                          ? {
                                                              ...q,
                                                              option:
                                                                option.option,
                                                            }
                                                          : q
                                                    );
                                                  }
                                                  return [
                                                    ...prevSelectedQuestions,
                                                    {
                                                      questionId:
                                                        question.questionId,
                                                      option: option.option,
                                                    },
                                                  ];
                                                }
                                              );
                                            }

                                            handleLikeClick(
                                              question.questionId,
                                              option
                                            );
                                          }}
                                        >
                                          <span
                                            className={`hover-text ${
                                              question.selectedMostId ==
                                              option.optionId
                                                ? "selectedColor"
                                                : null
                                            }`}
                                          >
                                            Like Me
                                          </span>
                                          {question.selectedMostId ==
                                          option.optionId ? (
                                            <img
                                              src={activeThumsUp}
                                              className="thumbsup-comp"
                                              alt="Thumbs Up"
                                            />
                                          ) : (
                                            <img
                                              src={thumbsup}
                                              className="thumbsup-comp"
                                              alt="Thumbs Up"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {displayBlue && (
                                      <div
                                        className={`rightlike hide col-2 position-absolute top-0 end-0 ${
                                          animate
                                            ? "expand-animation "
                                            : "d-none"
                                        }`}
                                        style={handleStyle(question, option)}
                                      >
                                        {question?.selectedMostId ==
                                        option.optionId ? (
                                          <img
                                            src={activeThumsUp}
                                            className="img-color"
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div> */}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className={`bottomSheet ${isActive ? "active" : ""}`}
                    ref={dropdownRefweb}
                    // {...handlers}
                  >
                    <div className="drag-imgdiv">
                      {/* <img src={drag} className="drag-img" /> */}
                      {/* {isActive ? <img src={down} /> : <img src={up} />} */}
                    </div>
                    <div className="d-flex justify-content-evenly rowww ">
                      <div
                        className="row align-items-center justify-content-start hundredper"
                        id="mbl-att"
                      >
                        <div className="row align-items-center justify-content-evenly">
                          <div className="col-5">
                            <h2 className="ms-1 text-nowrap">
                              {totalAttempted}/{dataAssessment?.length}
                            </h2>
                          </div>
                          <div className="col-5">
                            <p className="mx-3">Completed</p>
                          </div>
                        </div>
                        {/* <p className="p-0">completed</p> */}
                      </div>
                      <div className="row align-items-center">
                        <div className="col-8 mx-4">
                          <div>
                            <div className="progress my-auto">
                              <div
                                id="progressBar"
                                className="progress-bar"
                                style={{
                                  width: percentage + "%",
                                  backgroundColor: "#4C60E5",
                                  borderRadius: "4px",
                                  height: "8px",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          {/* {isActive ? <img src={down} /> : <img src={up} />} */}
                          {/* <p className=" hundredper">
                            <b className="pertxt">{percentage}%</b>
                          </p> */}
                        </div>
                      </div>
                    </div>

                    <div
                      className="row bottomSheetscroll mx-auto "
                      ref={contentRef}
                    >
                      <div className="col-lg-1 col-md-2 d-flex mx-auto sideNum">
                        <div className="col-12 text-center">
                          {/* <p>Attempted</p>
              <h2>
                {attemptedQuestionss.length} / {dataAssessment.length}
              </h2> */}
                          <div className="row mx-auto manage-cols ">
                            {dataAssessment?.map((item, index) => (
                              <div key={index} className="col p-1">
                                <div
                                  className={`d-flex justify-content-center nums ${
                                    selectedNumber === item.questionId &&
                                    item?.selectedLeastId &&
                                    item?.selectedMostId
                                      ? "currentSelected selected"
                                      : item?.selectedLeastId &&
                                        item?.selectedMostId
                                      ? "selected"
                                      : selectedNumber === item.questionId
                                      ? "selected-notanswered"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    settoggle(false);

                                    console.log(item, "insided divvvvvvvvvvvv");
                                    handleNumberClick(item.questionId);
                                  }}
                                >
                                  <a
                                    className={`nums-link text-center ${
                                      selectedNumber === item.questionId ||
                                      (item?.selectedLeastId &&
                                        item?.selectedMostId)
                                        ? "nums-link-change"
                                        : ""
                                    }`}
                                  >
                                    {item.questionId}
                                  </a>
                                </div>
                              </div>
                            ))}

                            {selectedNumber !== null && (
                              <div className="question-data">
                                {
                                  dataAssessment.find(
                                    (item) => item.questionId === selectedNumber
                                  )?.questionData
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-2 col-md-3 d-flex company-rightside"
                  id="company-rightside"
                >
                  <div className="col-12 padding-in-web">
                    <div className="company-rightside-part1">
                      <div className="row align-items-center justify-content-center">
                        <div className="col d-flex">
                          {/* <h2>How to answer</h2> */}
                          <h2>How to answer</h2>
                          <div className="col-auto ms-3">
                            <img
                              src={vectorcircle}
                              className="vector-img"
                              alt="Vector"
                            />
                          </div>
                        </div>
                      </div>

                      <ul className="ps-3">
                        {/* <li>Each image has 2 options</li> */}
                        <li>Each image has 2 options</li>
                        <li>
                          Select the word which is most like you by clicking on
                          <span className="border p-1 rounded-2">
                            <img style={{ scale: "0.8" }} src={thumbsup} />
                          </span>{" "}
                          and the word which is least like you by clicking
                          <span className="border p-1 rounded-2">
                            <img style={{ scale: "0.8" }} src={thumbsdown} />
                          </span>
                          .
                        </li>
                        <li>
                          Once you have made 2 selections the other cards will
                          be disabled and can only be selected once you unmark
                          one of your choices
                        </li>
                      </ul>
                    </div>
                    <div className="company-rightside-part2">
                      <h2>Your Answer</h2>
                      <p>Most Like Me</p>
                      <h3 className="clr-text-Enthusiastic">
                        {seletcetedAnser?.mostLike}

                        {/* {mostLike?.data?.optionId == leastLike?.data?.optionId && mostLike?.data?.optionValue == leastLike?.data?.optionValue  && setMostLike("")  } */}
                      </h3>
                      <p>Least Like Me</p>
                      <h3 className="clr-text-Cautious">
                        {seletcetedAnser?.leastLike}
                        {/* {leastLike?.data?.optionId == mostLike?.data?.optionId && leastLike?.data?.optionValue == mostLike?.data?.optionValue && setLeastLike("")} */}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-buttons company-bottom ">
              <div className="display-none-div"></div>
              <div className="d-flex">
                <button
                  className="btnVldt d-md-none edit-btn"
                  hidden={hidebtn}
                  onClick={editAddInfo}
                >
                  Edit Profile
                </button>

                <button
                  className="comp-prebtn d-flex align-items-center"
                  onClick={handlePrevious}
                >
                  <img src={arrowleft} className="arrowleft" />
                  Previous
                </button>
                <button
                  className="comp-nxtbtn d-flex align-items-center"
                  onClick={percentage == "100" ? handleShowToast : handleNext}
                >
                  {percentage == "100" ? (
                    <>Submit</>
                  ) : (
                    <>
                      Next
                      <img src={arrowright} className="arrowright" />
                    </>
                  )}
                </button>
              </div>
              <div className="justify-content-end">
                <div className="comp-savebtn" onClick={handleShowToast}>
                  Save Progress
                </div>
              </div>
              {/* <div className="">
          <button className="comp-savebtn" onClick={handleNavigate}>
            End Task
          </button>
        </div> */}
              <div className={`p-2 modaltoast ${showToast ? "show" : ""}`}>
                <div className="row col-12 ">
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img src={Tasty_Cookies} className="Tasty_Cookies" />
                    </div>
                  </div>
                  <div className="col-9 d-flex">
                    <div>
                      <p>
                        "We use cookies to personalize your experience, ensuring
                        that saving your progress and resuming will be smooth
                        and uninterrupted."
                      </p>
                      <div className="d-flex">
                        <a className="comp-prebtn" onClick={handleShowToastf}>
                          Deny Cookies
                        </a>
                        <a className="comp-nxtbtn" href="/Thankyou">
                          Allow Cookies
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-1 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleShowToastf}
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-buttons-mobile company-bottom-mobile d-flex justify-content-between px-4">
              <div className="d-flex flex-column align-items-center">
                <img src={saveprog} className="" onClick={handleShowToast} />
                <p style={{ fontSize: "10px", marginBottom: "0px" }}>Save</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={prevbtn} className="" onClick={handlePrevious} />
                <p style={{ fontSize: "10px", marginBottom: "0px" }}>Prev</p>
              </div>

              <div
                className="d-flex flex-column align-items-center"
                onClick={toggleBottomSheet}
              >
                <img src={up} />
                {/* <img src={dragup} className="" /> */}
                <p style={{ fontSize: "10px", marginBottom: "0px" }}>Expand</p>
              </div>

              <div
                className="d-flex flex-column align-items-center"
                onClick={percentage == "100" ? handleShowToast : handleNext}
              >
                {percentage == "100" ? (
                  <>
                    <img src={submitbtn} />
                    <p
                      style={{
                        fontSize: "10px",
                        marginBottom: "0px",
                        color: "#47CD89",
                      }}
                    >
                      Submit
                    </p>
                  </>
                ) : (
                  <>
                    <img src={nextbtn} className="" />
                    <p style={{ fontSize: "10px", marginBottom: "0px" }}>
                      Next
                    </p>
                  </>
                )}
              </div>

              <div className="d-flex flex-column align-items-center">
                <img src={editprof} className="" onClick={editAddInfo} />
                <p style={{ fontSize: "10px", marginBottom: "0px" }}>Edit</p>
              </div>
            </div>
            {/* -----------mbl------------ */}
            {/* <div
            className="container-fluid CompanyName-mainmbl"
            id="CompanyName-main"
          >
            <div
              className="row  justify-content-between"
              onClick={toggleBottomoff}
            >
              <div className="col-lg-8 col-md-6 d-flex" id="company-centerside">
                <div className="col-12">
                  <div className="row pt-2 pb-2">
                    {companies.map((company, index) => (
                      <div
                        key={company.id}
                        className={`col-md-6 d-flex justify-content-center align-items-center  ${
                          company.expanded ? "expanded" : ""
                        }`}
                      >
                        <div
                          className={`col-12 company-box-default ${
                            company.expanded
                              ? company.direction === "unlike"
                                ? "company-box-unlike"
                                : "company-box-like"
                              : ""
                          }`}
                        >
                          <div
                            className={`row d-flex company-box  col-12 m-0 ${
                              company.direction === "like"
                                ? "like-border"
                                : company.direction === "unlike"
                                ? "unlike-border"
                                : ""
                            }`}
                          >
                            <div className="col-4 d-flex justify-content-center align-items-center">
                              <img
                                src={company.compImg}
                                className="company-img"
                                alt="Company"
                              />
                            </div>
                            <div className="col-8 d-flex flex-column align-items-end">
                              <h2>{company.name}</h2>
                              <div className="mt-auto d-flex gapp">
                                <div
                                  className="thumbsdown-comp-link"
                                  onClick={() => handleClick(index, "unlike")}
                                >
                                  <span className="hover-text">Unlike Me</span>
                                  <a>
                                    <img
                                      src={company.thumbsdown}
                                      className="thumbsdown-comp"
                                      alt="Thumbs Down"
                                    />
                                  </a>
                                </div>
                                <div
                                  className="thumbsup-comp-link"
                                  onClick={() => handleClick(index, "like")}
                                >
                                  <span className="hover-text">Like Me</span>
                                  <a>
                                    <img
                                      src={company.thumbsup}
                                      className="thumbsup-comp"
                                      alt="Thumbs Up"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`bottomSheet ${isActive ? "active" : ""}`}
              {...handlers}
            >
              <div onClick={toggleBottomSheet} className="drag-imgdiv">
                <img src={drag} className="drag-img" />
              </div>
              <div className="d-flex rowww justify-content-between">
                <div className=" d-flex align-items-center">
                  <p className="text-center p-0">
                    <b className="pertxt">{percentage}%</b>completed
                  </p>
                  <div className="pt-2">
                    <div className="progress ms-2">
                      <div
                        id="progressBar"
                        className="progress-bar"
                        style={{
                          width: percentage + "%",
                          backgroundColor: "#4C60E5",
                          borderRadius: "4px",
                          height: "8px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className=" d-flex align-items-center justify-content-end"
                  id="mbl-att"
                >
                  <p className="p-0">Attempted</p>
                  <h2 className="p-0 ms-1">00 / 28</h2>
                </div>
              </div>

              <div
                className="row bottomSheetscroll ms-0 me-0 d-flex justify-content-center"
                ref={contentRef}
              ></div>
            </div>
          </div>
          <div className="company-bottom-mbl align-items-center justify-content-between">
            <div className="justify-content-end">
              <div className="comp-savebtn" onClick={handleShowToast}>
                Save Progress
              </div>
            </div>
            <div className="d-flex">
              <a className="comp-prebtn" href="">
                <img src={arrowleft} className="arrowleft" />
                Previous
              </a>
              <a className="comp-nxtbtn" href="/Thankyou">
                Next
                <img src={arrowright} className="arrowright" />
              </a>
            </div>

            <div
              className={`p-2 modaltoast ${showToast ? "show" : ""}`}
              id="modaltoast"
            >
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div></div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleShowToastf}
                  ></button>
                </div>
                <div className="col-auto d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img src={Tasty_Cookies} className="Tasty_Cookies" />
                  </div>
                  <div className="ms-1">
                    <p>
                      We use cookies to personalize your experience, ensuring
                      that saving your progress and resuming will be smooth and
                      uninterrupted.
                    </p>
                  </div>
                </div>

                <div className="row col-12 mt-2">
                  <div className="d-flex justify-content-center">
                    <a className="comp-prebtnmbl" onClick={handleShowToastf}>
                      Deny Cookies
                    </a>
                    <a className="comp-nxtbtnmbl" href="/Thankyou">
                      Allow Cookies
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </>
        )
      )}
    </>
  );
};

export default CompanyName;
