import React from "react";
import Illustration2 from "../assets/images/Illustrationn2.svg";
import Header from "../components/Header";
import { t } from "i18next";

const BreakGoing = (props) => {
  const { continueTest } = props;

  return (
    <>
      {/* <Header /> */}
      <div className="container" id="breakmain">
        <div className="breakmain">
          <a className="text-center">
            <img src={Illustration2} className="Illustration1-slide" />
          </a>
          <div className="text-center p-4">
            <h3>Great Going.</h3>
            <p>
              Don't overthink your choices.<br />
              Your First impression is generally your best. Hope you're enjoying the images too.
            </p>
          </div>
          <div>
            <button className="btn btn-primary" onClick={() => continueTest()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakGoing;
