import React, { useEffect, useState } from "react";
import Group from "../assets/images/Group.svg";
import chevron from "../assets/images/chevron-down.svg";
import globe from "../assets/images/globe-01.svg";

const ThankHeader = () => {
  const [percentage, setPercentage] = useState(100);
  const [isOpen, setIsOpen] = useState(false);

  const [companyName, setCompanyName] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const languages = [
    { name: "English", icon: globe },
    { name: "Tamil", icon: globe },
    { name: "Malayalam", icon: globe },
    { name: "Hindi", icon: globe },
    { name: "French", icon: globe },
    { name: "Spanish", icon: globe },
    { name: "German", icon: globe },
    { name: "Italian", icon: globe },
    { name: "Chinese", icon: globe },
    { name: "Japanese", icon: globe },
    { name: "Korean", icon: globe },
    { name: "Arabic", icon: globe },
  ];

  useEffect(() => {
    setCompanyName(localStorage.getItem("company"));
    const progressBar = document.getElementById("progressBar");
    progressBar.style.width = percentage + "%";
  }, [percentage]);

  return (
    <>
      <div className="container-fluid sticky-top Header-main " id="Header-main">
        <div className="row justify-content-between">
          <div className="col-auto d-flex align-items-center">
            <div className="border-imgHead-r">
              <a href="/">
                <img src={Group} className="GroupImg" alt="Group" />
              </a>
            </div>
            <div className="ms-3">
                <p>Personality Evaluation</p>
              <h2>{companyName}</h2>
            </div>
          </div>
          <div className="col-auto d-flex align-items-center">
            <div className="row ">
              <div className="percentage-col col d-flex align-items-center justify-content-center">
                <h5 className="text-center pertxt ">{percentage}% </h5>
                <span className="completed-text pb-2">Completed</span>
              </div>
              <div className="col justify-content-center d-flex align-items-center pb-1 ps-0">
                <div className="progress">
                  <div
                    id="progressBar"
                    className="progress-bar"
                    style={{
                      width: percentage + "%",
                      backgroundColor: "#4C60E5",
                      borderRadius: "4px",
                      height: "8px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid sticky-top Header-mainmbl"
        id="Header-main"
      >
        <div className="col-12">
          <div className="row justify-content-between">
            <div className="col-6 d-flex align-items-center ">
              <div className="ms-3">
                  <p>Personality Evaluation</p>
                <h2>{companyName}</h2>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <div>
                <p className="text-center">Completed</p>
                <p className="text-center ">
                  <b className="pertxt">{percentage}%</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankHeader;
