import React, { useContext, useEffect, useRef, useState } from "react";
import LYWOLogoSmall from "../assets/images/LYWOLogoSmall.svg";
import Illustration from "../assets/images/Illustration.svg";
import helpIcon from "../assets/images/helpIcon.svg";
import key from "../assets/images/keyLog.svg";
import arrowRight from "../assets/images/arrow-right.svg";
import alertCircle from "../assets/images/alert-circle.svg";
import success from "../assets/images/success.svg";
import userIcon from "../assets/images/UserIcon.svg";
import emailIcon from "../assets/images/EmailIcon.svg";
import phoneIcon from "../assets/images/PhoneIcon.svg";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import { Await, useNavigate } from "react-router-dom";
import {
  getWithAuthCall,
  simpleGetCall,
  simplePostCall,
  simplePostTokenCall,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../contaxt/AppContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CodeLogin = () => {
  const scrollEleRef = useRef(null);
  console.log(
    "localStorage.rItem('questionsData');",
    localStorage.getItem("questionsData")
  );
  useEffect(() => {
    localStorage.removeItem("questionsData");
    setTimeout(() => {
      if (scrollEleRef.current) {
        scrollEleRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 2000);
  }, []);

  const navigate = useNavigate();

  const [code, setCode] = useState("");
  console.log("code", code);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);
  const [isCodeValidated, setIsCodeValidated] = useState(false);
  const [CodeValidated, setCodeValidated] = useState(false);
  const [dataUniqueCode, setdataUniqueCode] = useState([]);
  const [userData, setUserData] = useState("");
  const [Tokendata, setTokendata] = useState("");
  const [addtioninfoformpage, setAdditionInfoFormPage] = useState(false);
  const [checkcodevalid, setCheckCodeValid] = useState(false);
  const [iscodecheck, setiscodecheck] = useState(false);
  const [IsPhoneValid, setIsPhoneValid] = useState(true);

  const handleCodeChange = (e) => {
    let value = e.target.value.toUpperCase();
    console.log(value, ";;;;;;;;;;;;;;;;;");
    value = value.replace(/[^A-Z0-9]/g, "");
    setCode(value);
    setIsButtonDisabled(value === "");
    setIsValidCode(true);
    setIsCodeValidated(false);
  };
  const {
    settokendatacontaxt,
    setUserlgin,
    codequerylgin,
    SetPageMsg,
    SetChangeLang,
  } = useContext(AppContext);
  console.log("codequerylgin", codequerylgin);

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    updateButtonDisableState(value, email, contact);
  };
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.code) {
      setCode(location.state.code);
      getUniueCodeVerify();

      // setIsButtonDisabled(false)
    }
  }, []);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    updateButtonDisableState(fullName, value, contact);

    validateEmail(value);
  };
  const [isValid, setIsValid] = useState(false);
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(email));
  };

  const handleContactChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      setContact(value);
    }
    // setContact(value);
    updateButtonDisableState(fullName, email, value);
    // validatePhone(value)
  };
  // const validatePhone = (phone) => {
  //   const phoneRegex = /^[0-9]{10}$/; // Simple validation for a 10-digit phone number
  //   setIsPhoneValid(phoneRegex.test(phone));
  // };
  const updateButtonDisableState = (name, email, contact) => {
    let hasEmail = false;
    let hasContact = false;
    const hasName = name.trim() !== "";
    hasEmail = isValid == true ? (hasEmail = true) : (hasEmail = false);
    hasContact =
      IsPhoneValid == true ? (hasContact = true) : (hasContact = false);
    console.log(hasEmail, hasContact, hasName);
    if (
      hasName &&
      hasEmail &&
      hasContact &&
      isCodeValidated &&
      contact.length === 10 &&
      /^\d+$/.test(contact)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleSubmit = async () => {
    console.log(
      "valid xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
    );

    console.log(addtioninfoformpage);
    const containsCapitalLetter = /[A-Z]/.test(code);
    const containsNumber = /[0-9]/.test(code);

    if (!containsCapitalLetter) {
      setIsValidCode(false);
      setIsCodeValidated(false);
      return;
    }
    if (!isCodeValidated) {
      await getUniueCodeVerify();
    } else {
      // setIsCodeValidated(true);
      updateButtonDisableState(fullName, email, contact);
      userLogInWithEmail();
      console.log("Submitted code:", CodeValidated);
      // if (CodeValidated) {
      //   console.log("Submitted coded:", code);
      //   if (addtioninfoformpage) {
      //     console.log("Submitted code:", code);
      //    const userid=localStorage.getItem("customer_id");
      //    console.log(userid,"userid additionalinformation");
      //     navigate("/AdditionalInformation");
      //   }else{
      //     navigate("/CompanyName");
      //   }
      // }
    }
    setTimeout(() => {
      if (scrollEleRef.current) {
        scrollEleRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 2000);
  };
  const getUniueCodeVerify = () => {
    let requestData;
    requestData = {
      jobUniqueModel:
        location?.state?.code?.length > 0 ? location?.state?.code : code,
    };
    localStorage.setItem(
      "Uniquecode",
      location?.state?.code?.length > 0 ? location?.state?.code : code
    );
    if (requestData) {
      simplePostCall(
        ApiConfig.LOGIN_WITH_UNIQUE_CODE,
        JSON.stringify(requestData)
      )
        .then((res) => {
          setdataUniqueCode(res.response);
          console.log(res, "Test");
          localStorage.setItem("company", res.response[0].company);
          localStorage.setItem("addInfoOne", res.response[0].addInfoOne);
          localStorage.setItem("addInfoTwo", res.response[0].addInfoTwo);
          const token = res.token;
          localStorage.setItem("auth_token", token);
          if (res.succeded) {
            toast.success(res.response[0].message);
            console.log("iffff");
            setiscodecheck(true);
            setIsCodeValidated(true);
            setIsButtonDisabled(true);
          } else {
            console.log("elseeee");
            setiscodecheck(false);
            toast.error(res.response[0].message);
            setIsCodeValidated(false);
          }
          console.log(res, "rescheck");
          if (
            Array.isArray(res.response) &&
            res.response.length > 0 &&
            (res.response[0].addInfoTwo == "yes" ||
              res.response[0].addInfoOne == "yes")
          ) {
            console.log("check in condition");
            setAdditionInfoFormPage(true);
          } else {
            setAdditionInfoFormPage(false);
            console.log(
              "isAdditionalInformation is false or response format is not as expected."
            );
          }
        })
        .catch((err) => {
          console.log("catch:", err);
        })
        .finally(() => {});
    }
  };

  const getLangueg = (userId) => {
    simpleGetCall(ApiConfig?.GET_LANG + userId).then((res) => {
      if (res?.succeded) {
        SetChangeLang(res?.response);
        i18next.changeLanguage(res?.response);
      }
    });
  };

  const userLogInWithEmail = async () => {
    let requestData;
    let uniquecode = localStorage.getItem("Uniquecode");
    requestData = {
      email: email,
      name: fullName,
      mobileNumber: contact,
      UniqueCode: code,
      languagePrefrence: localStorage.getItem("language"),
    };

    if (requestData) {
      try {
        const res = await simplePostCall(
          ApiConfig.REGISTRATION_FORM,
          JSON.stringify(requestData)
        );
        console.log(res.response, "response ref");

        if (res.response[0].result) {
          console.log(res, "response refss");
          let userdata = res.response[0];

          getLangueg(userdata?.userId);

          console.log(res.response, "su ref");
          setCodeValidated(true);
          console.log(CodeValidated, "ddd");
          setUserData(userdata);
          console.log(userdata, "userdata");
          await userLogWithToken(userdata);
          const token = userdata?.token;
          console.log(token, "tokennnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
          // toast.success(res.response[0].message);
          let add = localStorage.getItem("addInfoOne");
          let two = localStorage.getItem("addInfoTwo");
          SetPageMsg(res.response[0].message);

          // Investor Code Added 22/10/2024)

          if (
            (add.toLocaleLowerCase() == "no" &&
              two.toLocaleLowerCase() == "no") ||
            res.response[0].message == "Login Successful"
          ) {
            if (res.response[0].message == "Login Successful") {
              if (res.response[0].role == "Investor") {
                navigate("/companyname");
              } else {
                navigate("/companyname");
              }
            } else {
              // navigate("/AdditionalInformation");
              if (res.response[0].role == "New Investor") {
                navigate("/");
              } else {
                navigate("/AdditionalInformation");
              }
            }
          } else if (codequerylgin) {
            // navigate("/");

            navigate("/AdditionalInformation");
            setUserlgin(true);
          } else {
            // navigate("/companyname");
            // navigate("/");
            if (res.response[0].message == "Login Successful") {
              navigate("/companyname");
            } else {
              navigate("/AdditionalInformation");
            }
          }
        } else {
          console.error("Error: No response or response is false");
          console.log(res.response, "response ref errolr");
          // navigate('/LoginWithCode')
          setCodeValidated(false);
          console.log(CodeValidated, "code validated");
          toast.error(res.response[0].message);
        }
      } catch (err) {
        console.log("Error:", err);
        console.log(err, "response refss");
      } finally {
        console.log("Error:finally");
      }
    }
  };
  const userLogWithToken = (userdata) => {
    let requestData;

    requestData = {
      userId: userdata?.userId,
      companyId: userdata?.companyId ? userdata?.companyId : 0,
      roleId: userdata?.roleId ? userdata?.roleId : 3,
      otp: userdata?.otp,
    };
    if (userdata?.userId) {
      localStorage.setItem("customer_id", userdata.userId);
      console.log(userdata.userId, "userdatatokenset");
    }

    const urlEncodedData = new URLSearchParams(requestData).toString();
    if (requestData) {
      simplePostTokenCall(
        ApiConfig.TOKEN_ACCESS,
        urlEncodedData,
        JSON.stringify(requestData)
      )
        .then((res) => {
          console.log(res, "resom token");

          if (res?.statusFlag) {
            setTokendata(res?.statusFlag);
            const token = res?.token;
            console.log(token, "tokentokentokentokentokentokentoken");
            localStorage.setItem("authToken", token);
            settokendatacontaxt(token);
          } else {
            console.error("Error: No response or response is false");
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        })
        .finally(() => {});
    }
  };

  return (
    <div className="clWrap">
      <div className="clLft-side">
        <div className="clLogoStyleMob">
          <a href="/">
            <img src={LYWOLogoSmall} className="clLogoStyle" alt="Logo" />
          </a>
        </div>
        <div className="illustrationContainer">
          <img src={Illustration} className="illustImg" alt="Logo" />
        </div>
      </div>
      <div className="formSpc">
        <div className="loginCode">
          <div className="text-center">
            <p>Login</p>
          </div>
          <div className="formArea">
            <label htmlFor="codeInput">Code</label>
            <div className="input-group">
              <img src={key} className="keyIconStyl" alt="Key Icon" />
              <input
                type="text"
                id="codeInput"
                name="codeInput"
                value={code}
                onChange={handleCodeChange}
                className={isValidCode ? "" : "error"}
                placeholder="Enter your unique access code"
              />
              {isCodeValidated ? (
                <img
                  src={success}
                  className="helpIconStyl"
                  alt="Success Icon"
                />
              ) : (
                <img
                  src={isValidCode ? helpIcon : alertCircle}
                  className="helpIconStyl"
                  alt={isValidCode ? "Help Icon" : "Alert Icon"}
                />
              )}
            </div>
            {!code && (
              <p className="instructionTxt">Please use access code provided</p>
            )}
            {isCodeValidated && !checkcodevalid && (
              <p className="instructionTxt"> {dataUniqueCode[0]?.message}</p>
            )}
            {isCodeValidated && checkcodevalid && (
              <p className="instructionTxt"> {dataUniqueCode[0]?.message}</p>
            )}
          </div>
          <div
            className="detailArea"
            style={{
              // display: isCodeValidated ? "block" : "none",
              maxHeight: isCodeValidated ? "1000px" : "0",
              transition: isCodeValidated
                ? "max-height 1s ease"
                : "max-height 0.3s ease",
              overflow: "hidden",
            }}
          >
            {dataUniqueCode?.map((item) => {
              console.log(item, "item");
              if (item.success == true) {
                return (
                  <div className="CPD-Area" key={item.id}>
                    {item?.company && (
                      <p className="instructionTxt">
                        <span>
                          Company Name <b className="ms-1">{item.company}</b>
                        </span>
                      </p>
                    )}
                    {item?.reason && (
                      <p className="instructionTxt">
                        <span>
                          {" "}
                          Purpose of Test <b className="ms-1"> {item.reason}</b>
                        </span>
                      </p>
                    )}
                    {item?.reason === "Internal Assessment" ? (
                      <></>
                    ) : (
                      item?.position && (
                        <p className="instructionTxt">
                          <span>
                            {" "}
                            Designation
                            <b className="ms-1">{item.position}</b>
                          </span>
                        </p>
                      )
                    )}
                  </div>
                );
              }
              <div></div>;
            })}
            {/* <div className="CPD-Area">
              <p className="instructionTxt">
                Company Name <b className="ms-1">LYWO</b>
              </p>
              <p className="instructionTxt">
                Purpose of Test <b className="ms-1">General Evaluation</b>
              </p>
              <p className="instructionTxt">
                Designation
                <b className="ms-1">Assistant Manager - Quality Control</b>
              </p>
            </div> */}
            {iscodecheck && (
              <div className="dataFormArea">
                <div className="mb-3">
                  <label htmlFor="nameInput">Full Name</label>
                  <div className="input-group">
                    <img
                      src={userIcon}
                      className="userIconStyl"
                      alt="User Icon"
                    />
                    <input
                      type="text"
                      id="nameInput"
                      c
                      name="nameInput"
                      value={fullName}
                      onChange={handleFullNameChange}
                      placeholder="Full Name"
                    />
                  </div>
                  <p
                    className={`${
                      fullName.length > 0 ? "d-none" : "instructionTxt"
                    }`}
                  >
                    Please enter your full name.
                  </p>
                </div>
                {/* <div className="mb-3">
                <label htmlFor="emailInput">Email Id</label>
                <div className="input-group">
                  <img
                    src={emailIcon}
                    className="emailIconStyl"
                    alt="Email Icon"
                  />
                  <input
                    type="text"
                    id="emailInput"
                    name="emailInput"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                  />
                </div>
              </div> */}
                <div className="mb-3" ref={scrollEleRef}>
                  <label htmlFor="emailInput">Email Id</label>
                  <div className="input-group">
                    <img
                      src={emailIcon}
                      className="emailIconStyl"
                      alt="Email Icon"
                    />
                    <input
                      type="text"
                      id="emailInput"
                      name="emailInput"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Email"
                      className={isValid ? "" : "invalid"}
                    />
                  </div>
                  {!isValid && (
                    <div className="instructionTxt">
                      Please enter a valid email address.
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="contactInput">Contact No.</label>
                  <div className="input-group">
                    <img
                      src={phoneIcon}
                      className="contactIconStyl"
                      alt="Phone Icon"
                    />
                    <input
                      type="tel"
                      maxLength={10}
                      id="contactInput"
                      name="contactInput"
                      value={contact}
                      onChange={handleContactChange}
                      placeholder="Contact No."
                      className={IsPhoneValid ? "" : "invalid"}
                      required
                    />
                  </div>
                  {!IsPhoneValid && (
                    <div className="error-message">
                      {" "}
                      Please Enter valid number
                    </div>
                  )}
                </div>
                {/* <div className="mb-3">
      <label htmlFor="phoneInput">Contact No.</label>
      <div className="input-group">
        <img
          src={phoneIcon}
          className="phoneIconStyl"
          alt="Phone Icon"
        />
        <input
          type="text"
          id="phoneInput"
          name="phoneInput"
          value={contact}
          onChange={handleContactChange}
          placeholder="Phone Number"
          className={IsPhoneValid ? '' : 'invalid'}
          maxLength={10}
        />
      </div>
      {!IsPhoneValid && <div className="error-message">Please enter a valid 10-digit phone number.</div>}
    </div> */}
              </div>
            )}
          </div>
          <div className="btnArea">
            <button
              className={`btnVldt ${isButtonDisabled ? "disabled" : ""}`}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              {isCodeValidated ? <p>Continue</p> : <p>Validate Code</p>}
              <img
                src={arrowRight}
                className="arrowIconStyl"
                alt="ArrowRight-Icon"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeLogin;
