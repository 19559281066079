import React, { useState, useEffect } from "react";
import ApiConfig from "../api/ApiConfig";

import { AppContext } from "../contaxt/AppContext";
const AppState = (props) => {
  const [percentage, setPercentage] = useState(0);
  console.log(percentage);
  //   const dispach = useDispatch();
  const [tokendatacontaxt, settokendatacontaxt] = useState("");
  const [hide, sethide] = useState(false);
  const [Pecentagedata, setPecentagedata] = useState("");
  const [customerData, setCustomerData] = useState({
    api_key: localStorage.getItem("api_key")
      ? localStorage.getItem("api_key")
      : null,

    User_id: localStorage.getItem("User_id")
      ? localStorage.getItem("User_id")
      : null,
  });

  const ENDPOINT = ApiConfig.BASE_URL;
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  useEffect(() => {
    if (customerData.customer_id) {
    }
  }, [customerData.customer_id]);

  console.log("customerData", customerData);
  const [ChangeLang, SetChangeLang] = useState("");
  const [PageMsg, SetPageMsg] = useState("");
  const [codequerylgin, setcodequerylgin] = useState(false);
  const [userlgin, setUserlgin] = useState(false);

  return (
    <div>
      <AppContext.Provider
        value={{
          userlgin,
          setUserlgin,
          PageMsg,
          SetPageMsg,
          codequerylgin,
          setcodequerylgin,
          customerData,
          setCustomerData,
          tokendatacontaxt,
          settokendatacontaxt,
          sethide,
          Pecentagedata,
          setPecentagedata,
          ChangeLang,
          SetChangeLang,
          hide,
          percentage,
          setPercentage,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
