import React, { useContext, useEffect, useRef, useState } from "react";
import Group from "../assets/images/Group.svg";
import chevron from "../assets/images/chevron-down.svg";
import globe from "../assets/images/globe-01.svg";
import { AppContext } from "../contaxt/AppContext";
import DropdownLanguage from "../pages/multiLang/DropDown";
import { useTranslation } from "react-i18next";
import { Accordion, Dropdown } from "react-bootstrap";
import { changeLanguage, t } from "i18next";
import dropdown from "../assets/images/arrow-drop-down-svgrepo-com.svg";
import dropup from "../assets/images/arrow-drop-up-svgrepo-com.svg";
import { simpleGetCall } from "../api/ApiServices";

const Header = ({ props }) => {
  const [show, setShow] = useState(true);
  // const handleShowShutter = () => {
  //   setShow(!show);
  // };
  const prevScrollY = useRef(0);
  // useEffect(() => {
  //   const currentScrollY = window.scrollY;
  //   const handleScroll = () => {
  //     if (currentScrollY > prevScrollY.current) {
  //       setShow(false);
  //     } else {
  //       setShow(true);
  //     }
  //     prevScrollY.current = currentScrollY;
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  console.log(props);
  const { percentage, setPercentage, ChangeLang } = useContext(AppContext);
  const [showDropDown, setShowDropDown] = useState(false);

  const [comapnyName, setCompanyName] = useState("");
  const [percentage1, setPercentage1] = useState();
  // let [company, setcompany] = useState();
  console.log(percentage1, "percentage1");
  const [isOpen, setIsOpen] = useState(false);
  // let [company,setcompany]=useState();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };



  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  
  const handleClickOutside = (event) => {
    if (
      dropdownRef1.current &&
      !dropdownRef1.current.contains(event.target) &&
      dropdownRef2.current &&
      !dropdownRef2.current.contains(event.target)
    ) {
      setShowDropDown(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const handleDropDown = () => {
    setShowDropDown(!showDropDown);
    setIsOpen(!isOpen);
  };
  const languages = [
    { name: "English" },
    { name: "Hindi" },
    { name: "Telugu" },
  ];

  const getLang = () => {
    simpleGetCall().then((res) => {});
  };

  // const savedPercentage = localStorage.getItem('percentage');
  // console.log(savedPercentage,"sssssssssssave%");
  // useEffect(() => {
  //   setCompanyName(localStorage.getItem("company"));

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    const progressBar = document.getElementById("progressBar");

    progressBar.style.width = percentage1 + "%";
  }, [percentage1]);

  useEffect(() => {
    const storedPercentage = localStorage.getItem("percentage");

    console.log(storedPercentage, "storeeeeeee");
    //setPercentage(storedPercentage);
    if (storedPercentage) {
    }
    console.log(percentage1);
  }, [percentage1]);

  const [curr, setCurr] = useState({
    en: "English",
    hi: "Hindi",
    te: "Telugu",
  });

  const handleLangChange = (lang) => {
    localStorage.setItem("language", lang);
    setShowDropDown(false);
  };
  const [currentLanguage, setCurrentLunguege] = useState(
    localStorage.getItem("language")
  );

  // useEffect(() => {
  //   const upbutton = document.querySelector(".place-below-header");
  //   upbutton.classList.add("getup");
  //   const mbl = document.querySelector(".Header-mainmbl");
  //   mbl.classList.add("getup");
  //   const timer = setTimeout(() => {
  //     setShow(false);
  //   }, 2000);
  //   return () => {
  //     if (upbutton) upbutton.classList.remove("getup");
  //     if (mbl) mbl.classList.remove("getup");
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <>
      <div className="container-fluid Header-main " id="Header-main">
        <div className="row justify-content-between height-adj">
          <div className="col-auto d-flex align-items-center">
            <div className="border-imgHead-r mt-1">
              <a href="/">
                <img src={Group} className="GroupImg" alt="Group" />
              </a>
            </div>
            <div className="d-flex flex-column ms-3 mt-2">
              {comapnyName ? (
                <div>
                  <p>Personality Evaluation</p>
                  <h2 className="manage-length">{comapnyName}</h2>
                </div>
              ) : (
                <div>
                  <h2>Personality Evaluation</h2>
                </div>
              )}
            </div>
          </div>
          <div className="col-auto d-flex align-items-center pe-4">
            <div className="row align-items-center">
              <div className="percentage-col col d-flex mt-2 align-items-center justify-content-center">
                <h5 className="text-center pertxt ">{percentage}% </h5>
                <span className="completed-text pb-2">Completed</span>
              </div>
              <div className="col mt-2 justify-content-center d-flex align-items-center pb-1">
                <div className="progress">
                  <div
                    id="progressBar"
                    className="progress-bar"
                    style={{
                      width: percentage + "%",
                      backgroundColor: "#4C60E5",
                      borderRadius: "4px",
                      height: "8px",
                    }}
                  ></div>
                </div>
              </div>
              <div className="col border lang-box border-0">
                <div
                  className="d-flex flex-column postion-relative"
                  ref={dropdownRef1}
                >
                  <div
                    className="d-flex mx-1 border p-1 rounded-3 align-items-center "
                    onClick={handleDropDown}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="me-1 mb-0">
                      {ChangeLang === "en"
                        ? "English"
                        : ChangeLang === "hi"
                        ? "Hindi"
                        : ChangeLang === "te"
                        ? "Telugu"
                        : "English"}
                    </h5>
                    <img src={globe} alt="Globe Icon" />
                  </div>
                  <div
                    className="border ms-1 mt-1 bg-white rounded-3"
                    style={{ position: "absolute", top: "75%", right: "10px" }}
                  >
                    {showDropDown && (
                      <DropdownLanguage
                        showDropDown={showDropDown}
                        setShowDropDown={setShowDropDown}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-center position-absolute ${
          show ? "place-below-header" : "show-above"
        }`}
      >
        {/* <button className="border-0 p-0" onClick={handleShowShutter}>
          {show ? (
            <img className="drop-img" src={dropup} />
          ) : (
            <img className="drop-img" src={dropdown} />
          )}
        </button> */}
      </div>
      {show && (
        <div
          className={`container-fluid Header-mainmbl ${
            show ? "showanimate" : "notshowanimate"
          } `}
          id="Header-main"
        >
          <div className="col-12">
            <div className="row justify-content-between">
              <div className="col-9 col-lg-6 d-flex align-items-center ">
                <div className="ms-3">
                  {comapnyName ? (
                    <div>
                      <p>Personality Evaluation</p>
                      <h2>{comapnyName}</h2>
                    </div>
                  ) : (
                    <div>
                      <h2>Personality Evaluation</h2>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-3 col-lg-6 d-flex align-items-center justify-content-end">
                <div className="row ">
                  <div className="col">
                    <div
                      className="d-flex flex-column postion-relative"
                      ref={dropdownRef2}
                    >
                      <div
                        className="d-flex border rounded-3 align-items-center mb-2"
                        onClick={handleDropDown}
                        style={{ cursor: "pointer" }}
                      >
                        <h5 className="me-1 small-screen-font">
                          {ChangeLang === "en"
                            ? "English"
                            : ChangeLang === "hi"
                            ? "Hindi"
                            : ChangeLang === "te"
                            ? "Telugu"
                            : "English"}
                        </h5>
                        <img
                          src={globe}
                          alt="Globe Icon"
                          className="py-1"
                          style={{ marginRight: "5px" }}
                        />
                      </div>
                      <div
                        className="border ms-1 mt-1 bg-white rounded-3"
                        style={{
                          position: "absolute",
                          top: "86%",
                          right: "1px",
                          zIndex: "10",
                        }}
                      >
                        {showDropDown && (
                          <DropdownLanguage
                            showDropDown={showDropDown}
                            setShowDropDown={setShowDropDown}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
